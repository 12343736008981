export type ProductDetailsType = {
    code: string;
    currency: string;
    deadlineByDay: number;
    deadlineByMonth: number;
    id: number;
    label: string;
    mainTransient: number;
    principalCompanyCode: string;
    productInfos: Array<ProductInfoDTO> | null;
    quoteValidityDuration: number;
    endorsementQuoteValidityDuration: number;
    range: {
        id: number;
        label: string;
    } | null;
    riskType: {
        id: number;
        label: string;
    };
    secondaryTransient: number;
    transientMode: string | null;
    transientDebit: number | null;
    openingDate: string | null;
    debitMode: string;
    delayedEffectLimit: number | null;
    mainDeadlineDerogation: boolean;
    annualPremiumAdjustment: boolean;
};

export type ProductInfoDTO = {
    id?: number;
    label: string;
    value: string;
    createdDate?: string;
    modifiedDate?: string;
    createdBy?: string;
    modifiedBy?: string;
};

export type EditedProductDetailsType = {
    code: string;
    label: string;
    deadlineByDay: number;
    deadlineByMonth: number;
    deadlineType: string;
    principalCompanyCode: string;
    currency: string;
    mainTransient: number;
    secondaryTransient: number;
    transientMode: string;
    riskTypeId: number;
    rangeId: number;
    productInfos: Array<ProductInfoDTO>;

    quoteValidityDuration: number | undefined;
    endorsementQuoteValidityDuration: number | undefined;
    debitMode: DebitModeType | undefined;
    openingDate: string | undefined;
    delayedEffectLimit: number | undefined;
    mainDeadlineDerogation: boolean | undefined;
};

export type EditProductParamsType = {
    productId: string;
    newProductDetails: EditedProductDetailsType;
};

export type ProductsByRiskType = {
    riskType: string;
    ranges: Array<ProductsByRange>;
};

export type ProductsByRange = {
    range: string;
    products: Array<ProductRecap>;
};

export type ProductRecap = {
    id: number;
    code: string;
    label: string;
    riskType: string;
    range: string;
};

export type NewProductDTO = {
    code: string;
    label: string;
    deadlineByDay: number;
    deadlineByMonth: number;
    principalCompanyCode: string;
    quoteValidityDuration: number;
    endorsementQuoteValidityDuration: number;
    riskTypeId: number;
    rangeId?: number;
    currency: string;
    openingDate: string;
    debitMode: string;
    delayedEffectLimit: number;
    transientMode: string;
};

export enum DebitModeType {
    A_ECHOIR = "A_ECHOIR",
    ECHU = "ECHU",
}

export type InsuranceCoverageDTO = {
    insurancePackageCode: string;
    insurancePackageLabel: string;
    coverages: Array<{code: string; label: string}>;
    options: Array<{code: string; label: string; registryCode: string; registryLabel: string}>;
    feeCharges: Array<{code: string; label: string}>;
};
