import {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Grid, Header} from "semantic-ui-react";
import {Breadcrumb} from "../Routes/BreadcrumbsInjectorWrapper";
import "./customBreadcrumb.css";
import Sections from "./Fragments/Sections";

const CustomBreadcrumb = ({
    sections,
    children,
    title,
}: {
    sections: Breadcrumb[];
    children?: JSX.Element;
    title: string;
}) => {
    const {t} = useTranslation();

    const getTitle = () => {
        if (title !== undefined) {
            return title;
        } else {
            return t(`sections.${sections[sections?.length - 1].name}`);
        }
    };

    return (
        <Fragment>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    {getTitle()}
                </Header>
                <div className="sub-header">
                    <Grid>
                        <Grid.Column floated={"left"}>
                            <Sections sections={sections} />
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
            {children}
        </Fragment>
    );
};

export default CustomBreadcrumb;
