import React, {useEffect, useState} from "react";
import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Input,
    Label,
    Table,
} from "semantic-ui-react";
import {
    PricingCriteriaConditionDTO,
    PricingCriteriaItemType,
    VariableCDTO,
} from "../../../Services/PricingCriterias/Types";
import {useGetPricingGridsQuery, useGetPricingGridDimensionsQuery} from "../../../Services/PricingGrid/Queries";
import {useGetQuestionsQuery} from "../../../Services/Question/Queries";
import {QuestionValueTypeEnum} from "../../../Services/Question/Types";
import {GenericReducerActionType} from "../../../Utils/ReducerUtils";
import {
    AssignmentType,
    ConditionActionType,
} from "../PricingCriterias/Fragments/PricingCriteriaConditions/PricingCriteriaConditionAccordion";

import {useGetPricingCriteriasList} from "../../../Services/PricingCriterias/Queries";
import {BasicGridType, DimensionGridDTO, DimensionValueType} from "../../../Services/PricingGrid/Types";

type GridSearchEditPropsType = {
    versionId: string;
    variableCDTO: VariableCDTO;
    dispatchCondition: (action: ConditionActionType) => void;
    originalCondition: PricingCriteriaConditionDTO;
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    setValue: (
        name: string,
        value: any,
        config?:
            | Partial<{
                  shouldValidate: boolean;
                  shouldDirty: boolean;
              }>
            | undefined
    ) => void;
    trigger: (name?: string | string[] | undefined) => Promise<boolean>;
};

export enum AssignmentDimensionType {
    NOTHING = "",
    VALEUR_FIXE = "VALEUR_FIXE",
    QUESTION = "QUESTION",
    PRICING_CRITERIA = "PRICING_CRITERIA",
}

const GridSearchEdit = ({
    variableCDTO,
    versionId,
    dispatchCondition,
    originalCondition,
    control,
    errors,
    setValue,
    trigger,
}: GridSearchEditPropsType) => {
    const {t} = useTranslation();

    const options = [
        {key: 1, text: "", value: AssignmentDimensionType.NOTHING},
        {key: 2, text: t("Fixed value"), value: AssignmentDimensionType.VALEUR_FIXE},
        {key: 3, text: t("Question"), value: AssignmentDimensionType.QUESTION},
        {key: 4, text: t("Pricing criteria"), value: AssignmentDimensionType.PRICING_CRITERIA},
    ];
    const gridsQueryResult = useGetPricingGridsQuery(versionId);
    const [selectedGrid, setSelectedGrid] = useState<BasicGridType | undefined>();
    const gridDimensionsQuery = useGetPricingGridDimensionsQuery(
        selectedGrid !== undefined ? String(selectedGrid.id) : "0",
        !!selectedGrid
    );

    const {data: pricingCriteriasList} = useGetPricingCriteriasList(versionId, true);
    const {data: questionsList} = useGetQuestionsQuery(versionId, true);

    useEffect(() => {
        const fetchDimension = async () => {
            if (selectedGrid !== undefined) {
                const newDimensions: DimensionGridDTO[] | undefined = (await gridDimensionsQuery.refetch()).data;

                if (newDimensions !== undefined) {
                    dispatchCondition({
                        type: GenericReducerActionType.EDIT,
                        assignmentType: AssignmentType.RECHERCHE_GRILLE,
                        payload: {
                            refGridCode: selectedGrid.code,
                            gridId: selectedGrid.id,
                            variableCriteriasCDTO: newDimensions.map((dimension) => {
                                let variable = variableCDTO.variableCriteriasCDTO.find(
                                    (variableCDTO) => variableCDTO.refDimensionCode === dimension.code
                                );
                                if (variable !== undefined) {
                                    return {
                                        refDimensionCode: dimension.code,
                                        value: variable.value !== undefined ? variable.value : null,
                                        pricingCriteriaName:
                                            variable.pricingCriteriaName !== undefined
                                                ? variable.pricingCriteriaName
                                                : null,
                                        questionName:
                                            variable.questionName !== undefined ? variable.questionName : null,
                                    };
                                } else {
                                    return {
                                        refDimensionCode: dimension.code,
                                        value: null,
                                        questionName: null,
                                        pricingCriteriaName: null,
                                    };
                                }
                            }),
                        },
                    });
                }
            }
        };

        fetchDimension();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGrid, dispatchCondition]);

    useEffect(() => {
        if (
            selectedGrid === undefined &&
            gridsQueryResult.isSuccess &&
            gridsQueryResult.data.length > 0 &&
            variableCDTO !== null &&
            variableCDTO.refGridCode.length > 0
        ) {
            const newGrid = gridsQueryResult.data.find((grid) => grid.code === variableCDTO.refGridCode);
            setSelectedGrid(newGrid);
            if (newGrid !== undefined) {
                setValue("gridCodeDropdown", newGrid.code);
            }
        }
    }, [gridsQueryResult.isSuccess, gridsQueryResult.data, selectedGrid, variableCDTO, setValue]);

    const checkDimensionHaveAnyValue = (dimensionCode: string) =>
        (variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)?.value ===
            null &&
            variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
                ?.pricingCriteriaName === null &&
            variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
                ?.questionName === null) ||
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode) ===
            undefined;
    const checkDimensionIsFixedValueType = (dimensionCode: string) =>
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)?.value !==
            null &&
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
            ?.pricingCriteriaName === null &&
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
            ?.questionName === null;
    const checkDimensionIsPricingCriteriaNameType = (dimensionCode: string) =>
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
            ?.pricingCriteriaName !== null &&
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)?.value ===
            null &&
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
            ?.questionName === null;
    const checkDimensionIsQuestionNameType = (dimensionCode: string) =>
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
            ?.questionName !== null &&
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)?.value ===
            null &&
        variableCDTO.variableCriteriasCDTO.find((variable) => variable.refDimensionCode === dimensionCode)
            ?.pricingCriteriaName === null;
    const getInputTypeBasedOnDimensionType = (dimensionValueType: DimensionValueType) => {
        switch (dimensionValueType) {
            case DimensionValueType.INT:
            case DimensionValueType.DOUBLE:
                return "number";
            case DimensionValueType.STRING:
                return "text";
            case DimensionValueType.DATE:
                return "date";
        }
    };

    return (
        <>
            {gridsQueryResult.isSuccess && gridsQueryResult.data !== undefined && (
                <Grid>
                    <GridRow>
                        <GridColumn width={10}>
                            <Controller
                                control={control}
                                defaultValue={selectedGrid !== undefined ? selectedGrid.code : ""}
                                rules={{required: true}}
                                name="gridCodeDropdown"
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <>
                                        <Container className="input">
                                            <Form.Select
                                                fluid
                                                name={name}
                                                placeholder={t("Select a grid")}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, {name, value}) => {
                                                    onChange(value);
                                                    const selectedGrid = gridsQueryResult.data?.find(
                                                        (grid) => grid.code === value
                                                    );
                                                    if (selectedGrid !== undefined) {
                                                        dispatchCondition({
                                                            type: GenericReducerActionType.EDIT,
                                                            assignmentType: AssignmentType.RECHERCHE_GRILLE,
                                                            payload: {
                                                                refGridCode: selectedGrid.code,
                                                                gridId: selectedGrid.id,
                                                                variableCriteriasCDTO: [],
                                                            },
                                                        });

                                                        setSelectedGrid(selectedGrid);
                                                        setValue(name, selectedGrid.code);
                                                    }
                                                }}
                                                options={gridsQueryResult.data.map((grid) => {
                                                    return {key: grid.code, text: grid.code, value: grid.code};
                                                })}
                                            />
                                            <Button
                                                attached="right"
                                                name="delete"
                                                icon="delete"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    dispatchCondition({
                                                        type: GenericReducerActionType.REMOVE,
                                                        assignmentType: AssignmentType.VALEUR_FIXE,
                                                        payload: {},
                                                    });
                                                }}
                                            />
                                        </Container>
                                        {errors.gridCodeDropdown && (
                                            <Label basic color="red" pointing="above">
                                                {t("this field is required")}
                                            </Label>
                                        )}
                                    </>
                                )}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>
            )}

            <Grid divided>
                <Grid.Row>
                    <GridColumn width={8}>
                        <Table fixed celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {gridDimensionsQuery.data !== undefined &&
                                    selectedGrid &&
                                    variableCDTO !== null &&
                                    variableCDTO.variableCriteriasCDTO !== null &&
                                    variableCDTO.variableCriteriasCDTO.length > 0 &&
                                    gridDimensionsQuery.data.map((dimension) => (
                                        <Table.Row key={dimension.code}>
                                            <Table.Cell>{dimension.code}</Table.Cell>
                                            <Table.Cell style={{overflow: "visible"}}>
                                                {/* Sélectionner le type de valeur associé sur une dimension dans la recherche de grille : valeur fixe ou critère tarifaire */}
                                                {checkDimensionHaveAnyValue(dimension.code) && (
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                            validate: (value) =>
                                                                value !== AssignmentDimensionType.NOTHING,
                                                        }}
                                                        defaultValue={AssignmentDimensionType.NOTHING}
                                                        name={dimension.code + "_assignmentDimensionType"}
                                                        render={({field: {name, value, onBlur}}) => (
                                                            <Dropdown
                                                                fluid
                                                                name={name}
                                                                value={value}
                                                                floating={true}
                                                                placeholder={t("Select a type of associated value")}
                                                                onBlur={onBlur}
                                                                onChange={(e, {value}) => {
                                                                    if (value === AssignmentDimensionType.VALEUR_FIXE) {
                                                                        dispatchCondition({
                                                                            type: GenericReducerActionType.EDIT,
                                                                            assignmentType:
                                                                                AssignmentType.RECHERCHE_GRILLE_DIMENSION_VALUE,
                                                                            payload: {
                                                                                dimensionCode: dimension.code,
                                                                                value: "",
                                                                            },
                                                                        });
                                                                    } else {
                                                                        if (
                                                                            value === AssignmentDimensionType.QUESTION
                                                                        ) {
                                                                            dispatchCondition({
                                                                                type: GenericReducerActionType.EDIT,
                                                                                assignmentType:
                                                                                    AssignmentType.RECHERCHE_GRILLE_DIMENSION_QUESTION,
                                                                                payload: {
                                                                                    dimensionCode: dimension.code,
                                                                                    value: "",
                                                                                },
                                                                            });
                                                                        } else {
                                                                            dispatchCondition({
                                                                                type: GenericReducerActionType.EDIT,
                                                                                assignmentType:
                                                                                    AssignmentType.RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA,
                                                                                payload: {
                                                                                    dimensionCode: dimension.code,
                                                                                    value: "",
                                                                                },
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                                error={
                                                                    errors[dimension.code + "_assignmentDimensionType"]
                                                                        ? true
                                                                        : false
                                                                }
                                                                options={options}
                                                                selection
                                                            />
                                                        )}
                                                    />
                                                )}

                                                {/* Input en mode valeur fixe */}
                                                {checkDimensionIsFixedValueType(dimension.code) && (
                                                    <Controller
                                                        control={control}
                                                        rules={{required: true}}
                                                        defaultValue={
                                                            variableCDTO.variableCriteriasCDTO.find(
                                                                (variable) =>
                                                                    variable.refDimensionCode === dimension.code
                                                            )?.value || ""
                                                        }
                                                        name={dimension.code + "_fixedValue"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Input
                                                                fluid
                                                                required
                                                                value={value}
                                                                name={name}
                                                                maxLength="100"
                                                                placeholder={t("Fixed value")}
                                                                type={getInputTypeBasedOnDimensionType(
                                                                    dimension.dimensionValueType
                                                                )}
                                                                step={
                                                                    dimension.dimensionValueType ===
                                                                    DimensionValueType.DOUBLE
                                                                        ? "0.01"
                                                                        : "1"
                                                                }
                                                                onBlur={onBlur}
                                                                onChange={(e, {value}) => {
                                                                    onChange(e);
                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType:
                                                                            AssignmentType.RECHERCHE_GRILLE_DIMENSION_VALUE,
                                                                        payload: {
                                                                            dimensionCode: dimension.code,
                                                                            value: value,
                                                                        },
                                                                    });
                                                                }}
                                                                error={
                                                                    errors[dimension.code + "_fixedValue"]
                                                                        ? true
                                                                        : false
                                                                }
                                                                action
                                                            >
                                                                <input />
                                                                <Button
                                                                    attached="right"
                                                                    name="delete"
                                                                    icon="delete"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        dispatchCondition({
                                                                            type: GenericReducerActionType.REMOVE,
                                                                            assignmentType:
                                                                                AssignmentType.RECHERCHE_GRILLE_DIMENSION_VALUE,
                                                                            payload: {
                                                                                dimensionCode: dimension.code,
                                                                                value: "",
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </Input>
                                                        )}
                                                    />
                                                )}

                                                {/* Dropdown sélection d'un critère tarifaire à affecter */}
                                                {checkDimensionIsPricingCriteriaNameType(dimension.code) &&
                                                    pricingCriteriasList && (
                                                        <Controller
                                                            control={control}
                                                            rules={{required: true}}
                                                            defaultValue={
                                                                variableCDTO.variableCriteriasCDTO.find(
                                                                    (variable) =>
                                                                        variable.refDimensionCode === dimension.code
                                                                )?.pricingCriteriaName || ""
                                                            }
                                                            name={dimension.code + "_pricingCriteriaName"}
                                                            render={({field: {name, value, onChange, onBlur}}) => (
                                                                <>
                                                                    <Container className="input">
                                                                        <Dropdown
                                                                            fluid
                                                                            required
                                                                            name={name}
                                                                            placeholder={t("Select a pricing criteria")}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            onChange={(e, {value}) => {
                                                                                onChange(e);
                                                                                dispatchCondition({
                                                                                    type: GenericReducerActionType.EDIT,
                                                                                    assignmentType:
                                                                                        AssignmentType.RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA,
                                                                                    payload: {
                                                                                        dimensionCode: dimension.code,
                                                                                        value: value as string,
                                                                                    },
                                                                                });
                                                                                setValue(
                                                                                    dimension.code +
                                                                                        "_pricingCriteriaName",
                                                                                    value
                                                                                );
                                                                                trigger(
                                                                                    dimension.code +
                                                                                        "_pricingCriteriaName"
                                                                                );
                                                                            }}
                                                                            options={pricingCriteriasList
                                                                                .filter((criteria) => {
                                                                                    return (
                                                                                        String(criteria.type) ===
                                                                                            PricingCriteriaItemType.STRING ||
                                                                                        String(criteria.type) ===
                                                                                            String(
                                                                                                dimension.dimensionValueType
                                                                                            ) ||
                                                                                        (criteria.type ===
                                                                                            PricingCriteriaItemType.NUMERIC &&
                                                                                            (dimension.dimensionValueType ===
                                                                                                DimensionValueType.INT ||
                                                                                                dimension.dimensionValueType ===
                                                                                                    DimensionValueType.DOUBLE))
                                                                                    );
                                                                                })
                                                                                .map((criteria) => {
                                                                                    return {
                                                                                        key: criteria.name,
                                                                                        text: criteria.name,
                                                                                        value: criteria.name,
                                                                                    };
                                                                                })}
                                                                            error={
                                                                                errors[
                                                                                    dimension.code +
                                                                                        "_pricingCriteriaName"
                                                                                ]
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            selection
                                                                        />
                                                                        <Button
                                                                            attached="right"
                                                                            name="delete"
                                                                            icon="delete"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                dispatchCondition({
                                                                                    type: GenericReducerActionType.REMOVE,
                                                                                    assignmentType:
                                                                                        AssignmentType.RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA,
                                                                                    payload: {
                                                                                        dimensionCode: dimension.code,
                                                                                        value: "",
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Container>
                                                                </>
                                                            )}
                                                        />
                                                    )}

                                                {/* Dropdown sélection d'une question à affecter */}
                                                {checkDimensionIsQuestionNameType(dimension.code) && questionsList && (
                                                    <Controller
                                                        control={control}
                                                        rules={{required: true}}
                                                        defaultValue={
                                                            variableCDTO.variableCriteriasCDTO.find(
                                                                (variable) =>
                                                                    variable.refDimensionCode === dimension.code
                                                            )?.questionName || ""
                                                        }
                                                        name={dimension.code + "_questionName"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <>
                                                                <Container className="input">
                                                                    <Dropdown
                                                                        fluid
                                                                        required
                                                                        name={name}
                                                                        placeholder={t("Select a question")}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        onChange={(e, {value}) => {
                                                                            onChange(e);
                                                                            dispatchCondition({
                                                                                type: GenericReducerActionType.EDIT,
                                                                                assignmentType:
                                                                                    AssignmentType.RECHERCHE_GRILLE_DIMENSION_QUESTION,
                                                                                payload: {
                                                                                    dimensionCode: dimension.code,
                                                                                    value: value as string,
                                                                                },
                                                                            });
                                                                            setValue(
                                                                                dimension.code + "_questionName",
                                                                                value
                                                                            );
                                                                            trigger(dimension.code + "_questionName");
                                                                        }}
                                                                        options={questionsList
                                                                            .filter((question) => {
                                                                                return (
                                                                                    question.valueType ===
                                                                                        QuestionValueTypeEnum.STRING ||
                                                                                    question.valueType ===
                                                                                        QuestionValueTypeEnum.LISTE ||
                                                                                    (question.valueType ===
                                                                                        QuestionValueTypeEnum.NOMBRE &&
                                                                                        (dimension.dimensionValueType ===
                                                                                            DimensionValueType.INT ||
                                                                                            dimension.dimensionValueType ===
                                                                                                DimensionValueType.DOUBLE)) ||
                                                                                    (question.valueType ===
                                                                                        QuestionValueTypeEnum.DATE &&
                                                                                        dimension.dimensionValueType ===
                                                                                            DimensionValueType.DATE)
                                                                                );
                                                                            })
                                                                            .map((question) => {
                                                                                return {
                                                                                    key: question.name,
                                                                                    text: question.name,
                                                                                    value: question.name,
                                                                                };
                                                                            })}
                                                                        error={
                                                                            errors[dimension.code + "_questionName"]
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        selection
                                                                    />
                                                                    <Button
                                                                        attached="right"
                                                                        name="delete"
                                                                        icon="delete"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            dispatchCondition({
                                                                                type: GenericReducerActionType.REMOVE,
                                                                                assignmentType:
                                                                                    AssignmentType.RECHERCHE_GRILLE_DIMENSION_QUESTION,
                                                                                payload: {
                                                                                    dimensionCode: dimension.code,
                                                                                    value: "",
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </Container>
                                                            </>
                                                        )}
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </GridColumn>
                </Grid.Row>
            </Grid>
            <GridSearchOriginalView
                originalCondition={originalCondition}
                selectedGrid={selectedGrid}
                gridsQueryResult={gridsQueryResult.data}
            />
        </>
    );
};

type GridSearchOriginalViewPropsType = {
    originalCondition: PricingCriteriaConditionDTO;
    selectedGrid: BasicGridType | undefined;
    gridsQueryResult: BasicGridType[] | undefined;
};

const GridSearchOriginalView = React.memo<GridSearchOriginalViewPropsType>(
    ({originalCondition, selectedGrid, gridsQueryResult}) => {
        const {t} = useTranslation();
        const originalGridDimensionsQuery = useGetPricingGridDimensionsQuery(
            String(
                gridsQueryResult?.find((grid) => grid.code === originalCondition?.variableCDTO?.refGridCode)?.id ?? 0
            ),
            gridsQueryResult !== undefined
        );

        const renderBodyContent = () => {
            if (
                originalGridDimensionsQuery !== undefined &&
                originalGridDimensionsQuery.data !== undefined &&
                originalGridDimensionsQuery.data.length >= originalCondition?.variableCDTO?.variableCriteriasCDTO.length
            ) {
                // Invalidation suite à l'ajout d'une ou plusieurs dimension(s)
                return originalGridDimensionsQuery.data.map((dimension) => {
                    const variableCriteria = originalCondition?.variableCDTO?.variableCriteriasCDTO?.find(
                        (variableCriteriaCDTO) => variableCriteriaCDTO.refDimensionCode === dimension.code
                    );
                    return (
                        <Table.Row key={dimension.code} error={variableCriteria === undefined}>
                            <Table.Cell>
                                {variableCriteria === undefined && <Icon name="attention" />}
                                {dimension.code}
                            </Table.Cell>
                            <Table.Cell>
                                {variableCriteria?.value
                                    ? t("Fixed value")
                                    : variableCriteria?.pricingCriteriaName
                                    ? t("Pricing criteria")
                                    : t("Question")}
                            </Table.Cell>
                            <Table.Cell>
                                {variableCriteria?.value
                                    ? variableCriteria.value
                                    : variableCriteria?.pricingCriteriaName
                                    ? variableCriteria.pricingCriteriaName
                                    : variableCriteria?.questionName}
                            </Table.Cell>
                        </Table.Row>
                    );
                });
            } else if (
                originalGridDimensionsQuery.data !== undefined &&
                originalGridDimensionsQuery.data.length < originalCondition?.variableCDTO?.variableCriteriasCDTO.length
            ) {
                // Invalidation suite à la suppression d'une ou plusieurs dimension(s)
                return originalCondition?.variableCDTO?.variableCriteriasCDTO.map((variableCriteria) => {
                    const dimension = originalGridDimensionsQuery.data.find(
                        (dimension) => dimension.code === variableCriteria.refDimensionCode
                    );
                    return (
                        <Table.Row key={variableCriteria.refDimensionCode} error={dimension === undefined}>
                            <Table.Cell>
                                {dimension === undefined && <Icon name="attention" />}
                                {variableCriteria.refDimensionCode}
                            </Table.Cell>
                            <Table.Cell>
                                {variableCriteria?.value ? t("Fixed value") : t("Pricing criteria")}
                            </Table.Cell>
                            <Table.Cell>
                                {variableCriteria?.value
                                    ? variableCriteria.value
                                    : variableCriteria?.pricingCriteriaName
                                    ? variableCriteria.pricingCriteriaName
                                    : variableCriteria?.questionName}
                            </Table.Cell>
                        </Table.Row>
                    );
                });
            }
        };

        return (
            <>
                {((!originalCondition.valid && originalCondition?.variableCDTO?.refGridCode === selectedGrid?.code) ||
                    originalCondition?.variableCDTO?.refGridCode !== selectedGrid?.code) &&
                    originalGridDimensionsQuery.data !== undefined && (
                        <>
                            <Divider />
                            <p>
                                {t("Actual grid assignement") + " : "}
                                <strong>{originalCondition?.variableCDTO?.refGridCode}</strong>
                            </p>

                            <Table fixed name="old_assignement">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Associated_value_type")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{renderBodyContent()}</Table.Body>
                            </Table>
                        </>
                    )}
            </>
        );
    }
);

export default GridSearchEdit;
