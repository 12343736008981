import {useTranslation} from "react-i18next";
import {Label, LabelGroup} from "semantic-ui-react";
import PricingGridSearchView from "../../../../../Components/PricingGridSearch/PricingGridSearchView";
import {convertVariableConditionToGridSearch} from "../../../../../Services/PricingGrid/Types";
import {Variable, VariableCondition, VariableValueType} from "../../../../../Services/Variables/Types";
import ComputingRuleAssignmentView from "../../../ComputingRule/ComputingRuleAssignment/ComputingRuleAssignmentView";
import {AssignmentType} from "./VariableConditionAssignementReducer";

type VariableConditionAssignementViewProps = {
    versionId: string;
    variable: Variable;
    variableCondition: VariableCondition;
    assignmentType: AssignmentType;
};

const VariableConditionAssignementView = ({
    versionId,
    assignmentType,
    variable,
    variableCondition,
}: VariableConditionAssignementViewProps) => {
    const {t} = useTranslation();

    if (assignmentType === AssignmentType.FIXED_VALUE) {
        return (
            <LabelGroup>
                <Label size="large" basic style={{border: "none"}} horizontal>
                    {t("enums.AssignmentType.FIXED_VALUE") + " : "}
                </Label>
                <Label size="large" className="fixed_value_assignment" horizontal>
                    {variable.valueType === VariableValueType.DATE
                        ? t("format_date", {value: variableCondition.variableCriterias[0].value})
                        : variableCondition.variableCriterias[0].value}
                </Label>
            </LabelGroup>
        );
    } else if (assignmentType === AssignmentType.SYSTEM_VARIABLE) {
        return (
            <LabelGroup>
                <Label size="large" basic style={{border: "none"}} horizontal>
                    {t("enums.AssignmentType.SYSTEM_VARIABLE") + " : "}
                </Label>
                <Label size="large" className="fixed_value_assignment" horizontal>
                    {t("enums.SystemVariable." + variableCondition.variableCriterias[0].systemVariable).includes(
                        "enums"
                    )
                        ? variableCondition.variableCriterias[0].systemVariable
                        : t("enums.SystemVariable." + variableCondition.variableCriterias[0].systemVariable)}
                </Label>
            </LabelGroup>
        );
    } else if (assignmentType === AssignmentType.PRICING_CRITERIA) {
        return (
            <LabelGroup>
                <Label size="large" basic style={{border: "none"}} horizontal>
                    {t("enums.AssignmentType.PRICING_CRITERIA") + " : "}
                </Label>
                <Label size="large" className="fixed_value_assignment" horizontal>
                    {variableCondition.variableCriterias[0].pricingCriteriaName}
                </Label>
            </LabelGroup>
        );
    } else if (assignmentType === AssignmentType.VARIABLE) {
        return (
            <LabelGroup>
                <Label size="large" basic style={{border: "none"}} horizontal>
                    {t("enums.AssignmentType.VARIABLE") + " : "}
                </Label>
                <Label size="large" className="fixed_value_assignment" horizontal>
                    {variableCondition.variableCriterias[0].variableName}
                </Label>
            </LabelGroup>
        );
    } else if (
        assignmentType === AssignmentType.VARIABLE_COMPUTING_RULE &&
        variableCondition.variableCriterias[0].computingRule
    ) {
        return (
            <ComputingRuleAssignmentView
                computingRuleCondition={variableCondition.variableCriterias[0].computingRule.computingRuleConditions[0]}
            />
        );
    } else if (assignmentType === AssignmentType.GRID_SEARCH) {
        return (
            <PricingGridSearchView
                versionId={versionId}
                pricingGridSearch={convertVariableConditionToGridSearch(variableCondition, t)}
            />
        );
    } else {
        return null;
    }
};

export default VariableConditionAssignementView;
