import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionPanelProps,
    AccordionTitleProps,
    SemanticShorthandCollection,
    TabPane,
} from "semantic-ui-react";
import {CoverageResult} from "../../../../Services/Pricing/Types";
import {formatBigDecimalAsCurrency, formatBigDecimalAsPercent} from "../../../../Utils/NumberUtils";
import {handleNullableProperty} from "../../../../Utils/TextUtils";
import UnitTestResultExpandShrink from "./UnitTestResultExpandShrink";
import UnitTestResultNextPeriodsPane from "./UnitTestResultNextPeriodsPane";

type UnitTestResultCoveragesPaneProps = {
    coverages: CoverageResult[];
};

const UnitTestResultCoveragesPane = ({coverages}: UnitTestResultCoveragesPaneProps) => {
    const {t} = useTranslation();
    const [activesIndexs, setActivesIndexs] = useState<number[] | undefined>();

    const handleAccordionTitleClick = (_: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const {index, active} = data;
        const newState = activesIndexs?.filter((i) => i !== index) ?? [];
        if (!active) newState.push(index as number);
        setActivesIndexs(newState);
    };

    const panels: SemanticShorthandCollection<AccordionPanelProps> = coverages.map((coverage, i) => {
        return {
            key: coverage.code,
            title: (
                <Accordion.Title>
                    <i aria-hidden="true" className="dropdown icon" />
                    {coverage.label} - {coverage.code}
                </Accordion.Title>
            ),
            content: (
                <Accordion.Content key={coverage.code} active={activesIndexs?.includes(i)}>
                    <div className="amounts-pane">
                        <div data-cy="coverage-informations">
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Company code")}
                                </div>
                                <div className="col bold">{handleNullableProperty(coverage.companyCode, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Tax code")}
                                </div>
                                <div className="col bold">{handleNullableProperty(coverage.taxCode, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Tax rate")}
                                </div>
                                <div className="col bold">{formatBigDecimalAsPercent(coverage.taxRate / 100, t)}</div>
                            </div>
                        </div>

                        <div data-cy="amounts">
                            <b>{t("Amounts")}</b>
                            <div className="row gap-1">
                                <div className="col">{t("Annual")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.annualPremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.netAnnualPremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">{formatBigDecimalAsCurrency(coverage.taxAnnual, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("Splitted")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.splittingPremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.netSplittingPremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.taxSplitting, t)}
                                </div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("Prorate")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.proratePremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.netProratePremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(coverage.taxProrate, t)}
                                </div>
                            </div>
                        </div>

                        <div data-cy="commission-amounts">
                            <b>{t("Commission amounts")}</b>
                            <div className="row gap-1">
                                <div className="col">{t("Contribution")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.contributionCommissionAmount, t)}
                                </div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("Management")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.managementCommissionAmount, t)}
                                </div>
                            </div>
                        </div>

                        <div data-cy="retrocessions-amounts">
                            <b>{t("Retrocessions amounts")}</b>
                            <div className="row gap-1">
                                <div className="col">{t("business_provider_x", {number: 1})}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.retrocessionAmount1, t)}
                                </div>
                                <div className="col">{t("Calculation basis")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.baseRetrocessionAmount1, t)}
                                </div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("business_provider_x", {number: 2})}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.retrocessionAmount2, t)}
                                </div>
                                <div className="col">{t("Calculation basis")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.baseRetrocessionAmount2, t)}
                                </div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("business_provider_x", {number: 3})}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.retrocessionAmount3, t)}
                                </div>
                                <div className="col">{t("Calculation basis")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.baseRetrocessionAmount3, t)}
                                </div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("business_provider_x", {number: 4})}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.retrocessionAmount4, t)}
                                </div>
                                <div className="col">{t("Calculation basis")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.baseRetrocessionAmount4, t)}
                                </div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("business_provider_x", {number: 5})}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.retrocessionAmount5, t)}
                                </div>
                                <div className="col">{t("Calculation basis")}</div>
                                <div className="col right bold">
                                    {formatBigDecimalAsCurrency(coverage.baseRetrocessionAmount5, t)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <UnitTestResultNextPeriodsPane nextPeriods={coverage.nextPeriods} isNested={true} />
                </Accordion.Content>
            ),
        };
    });

    return (
        <TabPane data-cy="coverages-pane">
            <UnitTestResultExpandShrink maxIndex={panels.length} setActivesIndexes={setActivesIndexs} />

            <Accordion
                styled
                exclusive={false}
                fluid
                panels={panels}
                activeIndex={activesIndexs}
                onTitleClick={handleAccordionTitleClick}
            />
        </TabPane>
    );
};

export default UnitTestResultCoveragesPane;
