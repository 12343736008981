import {UseQueryResult} from "@tanstack/react-query";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Input, Menu, Message, Modal} from "semantic-ui-react";
import useContainerDimensions from "../../Hooks/useContainerDimensions";
import {SortOption} from "../../Utils/SortUtils";
import Sort from "../Sort/Sort";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EntityPickerPropsType<T, Q extends any[]> = {
    object: string;
    objectContext: "male" | "female";
    entityListGetMethod: (...args: Q) => UseQueryResult<T[], Error>;
    entityListGetParameters: Q;
    renderCardContent: (entity: T) => JSX.Element;
    filterEntity: (entity: T) => boolean;
    filterBySearch: (entity: T, search: string) => boolean;
    onCancel: () => void;
    sortOptions?: SortOption[];
    defaultSortMethod?: (a: T, b: T) => number;
    placeholder?: JSX.Element;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EntityPicker = <T, Q extends any[]>({
    object,
    objectContext,
    entityListGetMethod,
    entityListGetParameters,
    renderCardContent,
    filterEntity,
    filterBySearch,
    onCancel,
    sortOptions,
    defaultSortMethod,
    placeholder,
}: EntityPickerPropsType<T, Q>) => {
    const {t} = useTranslation();

    const {data: entityList, status} = entityListGetMethod(...entityListGetParameters);
    const [sortMethod, setSortMethod] = useState<(a: T, b: T) => number>(
        defaultSortMethod !== undefined ? () => defaultSortMethod : () => () => 1
    );
    const [search, setSearch] = useState("");
    const {columnNumberAsText, ref} = useContainerDimensions();

    const filteredEntityList: T[] = useMemo(() => {
        if (status === "success" && entityList !== undefined) {
            return entityList
                .filter((entity) => filterBySearch(entity, search) && filterEntity(entity))
                .sort(sortMethod);
        }

        return [];
    }, [search, status, entityList, filterBySearch, sortMethod, filterEntity]);

    const renderEmptyListMessageContent = () => {
        if (entityList === undefined) return null;

        if (entityList.length === 0) {
            return t("enums.WarningEntityPickerEmptyTypeMessage." + object.toLowerCase());
        }

        if (search === "") {
            return t("enums.WarningEntityPickerEmptyFilterFunctionTypeMessage." + object.toLowerCase());
        }

        return t("enums.WarningEntityPickerEmptyFilterTypeMessage." + object.toLowerCase());
    };

    return (
        <Modal open size="large" dimmer="blurring" data-cy="entityPicker">
            <Modal.Header>
                {t("Choose", {
                    name: t(object, {count: 1}).toLowerCase(),
                    context: objectContext,
                })}
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                {sortOptions !== undefined && sortOptions.length !== 0 && (
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                <Input
                                    icon="search"
                                    placeholder={t("Find entity", {
                                        context: objectContext,
                                        entity: t("enums.ConditionKindEnum." + object.toUpperCase()).toLowerCase(),
                                    })}
                                    defaultValue={search}
                                    onChange={(_, data) => setSearch(data.value)}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                    <div
                        className={"ui cards " + columnNumberAsText}
                        ref={ref}
                        style={{overflow: "auto", maxHeight: "calc(100vh - 270px)"}}>
                        {filteredEntityList.map((entity) => renderCardContent(entity))}
                    </div>

                    {status === "pending" && placeholder}

                    {status === "success" && filteredEntityList.length === 0 && (
                        <Message warning>
                            <Message.Content>{renderEmptyListMessageContent()}</Message.Content>
                        </Message>
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCancel}>
                    {t("Cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EntityPicker;
