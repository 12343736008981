import {QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import React from "react";
import {CookiesProvider} from "react-cookie";
import {createRoot} from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import {AuthProvider} from "./Components/AuthProvider/AuthProvider";
import queryClient from "./Config/queryClient";
import "./index.css";
import "./Assets/custom-icons.css";

const domNode = document.getElementById("root") as HTMLElement;
const root = createRoot(domNode);

root.render(
    <React.StrictMode>
        <CookiesProvider>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <App />
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </CookiesProvider>
    </React.StrictMode>
);
