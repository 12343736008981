type UnitTestResultHeaderInformationProps = {
    label: string;
    renderSubContent: () => JSX.Element;
    ["data-cy"]: string;
};

const UnitTestResultHeaderInformation = ({
    label,
    renderSubContent,
    "data-cy": dataCy,
}: UnitTestResultHeaderInformationProps) => {
    const mainContainerStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.75rem",
    };

    const subElementStyle: React.CSSProperties = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    };

    const subElementContent = renderSubContent();

    return (
        <div style={mainContainerStyle}>
            <span data-cy={`${dataCy}-label`}>{label}</span>
            <div data-cy={`${dataCy}-content`} style={subElementStyle}>
                {subElementContent}
            </div>
        </div>
    );
};

export default UnitTestResultHeaderInformation;
