import {VariableCondition, VariableCriteria} from "../../../../../Services/Variables/Types";
import {GenericReducerActionType} from "../../../../../Utils/ReducerUtils";

export type VariableConditionActionType = {
    type: GenericReducerActionType;
    assignmentType: AssignmentType;
    assignmentDimensionType?: AssignmentDimensionType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
};

export enum AssignmentType {
    NOTHING = "",
    FIXED_VALUE = "FIXED_VALUE",
    SYSTEM_VARIABLE = "SYSTEM_VARIABLE",
    PRICING_CRITERIA = "PRICING_CRITERIA",
    VARIABLE = "VARIABLE",
    VARIABLE_R = "VARIABLE_R",
    VARIABLE_COMPUTING_RULE = "VARIABLE_COMPUTING_RULE",
    GRID_SEARCH = "GRID_SEARCH",
}

export enum AssignmentDimensionType {
    NOTHING = "NOTHING",
    FIXED_VALUE = "FIXED_VALUE",
    SYSTEM_VARIABLE = "SYSTEM_VARIABLE",
    PRICING_CRITERIA = "PRICING_CRITERIA",
    VARIABLE = "VARIABLE",
    VARIABLE_R = "VARIABLE_R",
}

const variableConditionReducer = (
    conditionState: VariableCondition,
    action: VariableConditionActionType
): VariableCondition => {
    switch (action.type) {
        case GenericReducerActionType.EDIT: {
            switch (action.assignmentType) {
                case AssignmentType.FIXED_VALUE:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: action.payload.fixedValue ?? "",
                                refDimensionCode: null,
                                variableName: null,
                                pricingCriteriaName: null,
                                computingRule: null,
                                systemVariable: null,
                            },
                        ],
                    };

                case AssignmentType.SYSTEM_VARIABLE:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: null,
                                refDimensionCode: null,
                                variableName: null,
                                pricingCriteriaName: null,
                                computingRule: null,
                                systemVariable: action.payload.systemVariable ?? "",
                            },
                        ],
                    };

                case AssignmentType.PRICING_CRITERIA:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: null,
                                refDimensionCode: null,
                                variableName: null,
                                pricingCriteriaName: action.payload.pricingCriteria ?? "",
                                computingRule: null,
                                systemVariable: null,
                            },
                        ],
                    };

                case AssignmentType.VARIABLE:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: null,
                                refDimensionCode: null,
                                variableName: action.payload.variableName ?? "",
                                pricingCriteriaName: null,
                                computingRule: null,
                                systemVariable: null,
                                isVariableV: true,
                            },
                        ],
                    };

                case AssignmentType.VARIABLE_R:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: null,
                                refDimensionCode: null,
                                variableName: action.payload.variableName ?? "",
                                pricingCriteriaName: null,
                                computingRule: null,
                                systemVariable: null,
                                isVariableV: false,
                            },
                        ],
                    };

                case AssignmentType.VARIABLE_COMPUTING_RULE:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: null,
                                refDimensionCode: null,
                                variableName: null,
                                pricingCriteriaName: null,
                                computingRule: {
                                    id: null,
                                    computingRuleConditions: [
                                        {
                                            id: null,
                                            ifCondition: null,
                                            conditionPartList: [],
                                            orderCondition: 1,
                                            calculFormule: null,
                                            rulePartList: action.payload.rulePartList,
                                            roundingMode: action.payload.roundingMode,
                                            decimalCount: action.payload.decimalCount,
                                        },
                                    ],
                                },
                                systemVariable: null,
                            },
                        ],
                    };

                case AssignmentType.GRID_SEARCH:
                    switch (action.assignmentDimensionType) {
                        case AssignmentDimensionType.FIXED_VALUE:
                            return {
                                ...conditionState,
                                variableCriterias: action.payload.conditionState.variableCriterias.map(
                                    (variableCriteria: VariableCriteria) => {
                                        if (variableCriteria.refDimensionCode === action.payload.dimensionCode) {
                                            variableCriteria.value = action.payload.value;
                                            variableCriteria.systemVariable = null;
                                            variableCriteria.pricingCriteriaName = null;
                                            variableCriteria.variableName = null;
                                            variableCriteria.computingRule = null;
                                        }
                                        return variableCriteria;
                                    }
                                ),
                            };
                        case AssignmentDimensionType.SYSTEM_VARIABLE:
                            return {
                                ...conditionState,
                                variableCriterias: action.payload.conditionState.variableCriterias.map(
                                    (variableCriteria: VariableCriteria) => {
                                        if (variableCriteria.refDimensionCode === action.payload.dimensionCode) {
                                            variableCriteria.value = null;
                                            variableCriteria.systemVariable = action.payload.systemVariable;
                                            variableCriteria.pricingCriteriaName = null;
                                            variableCriteria.variableName = null;
                                            variableCriteria.computingRule = null;
                                        }
                                        return variableCriteria;
                                    }
                                ),
                            };
                        case AssignmentDimensionType.PRICING_CRITERIA:
                            return {
                                ...conditionState,
                                variableCriterias: action.payload.conditionState.variableCriterias.map(
                                    (variableCriteria: VariableCriteria) => {
                                        if (variableCriteria.refDimensionCode === action.payload.dimensionCode) {
                                            variableCriteria.value = null;
                                            variableCriteria.systemVariable = null;
                                            variableCriteria.pricingCriteriaName = action.payload.pricingCriteria;
                                            variableCriteria.variableName = null;
                                            variableCriteria.computingRule = null;
                                        }
                                        return variableCriteria;
                                    }
                                ),
                            };
                        case AssignmentDimensionType.VARIABLE:
                            return {
                                ...conditionState,
                                variableCriterias: action.payload.conditionState.variableCriterias.map(
                                    (variableCriteria: VariableCriteria) => {
                                        if (variableCriteria.refDimensionCode === action.payload.dimensionCode) {
                                            variableCriteria.value = null;
                                            variableCriteria.systemVariable = null;
                                            variableCriteria.pricingCriteriaName = null;
                                            variableCriteria.variableName = action.payload.variable;
                                            variableCriteria.computingRule = null;
                                            variableCriteria.isVariableV = true;
                                        }
                                        return variableCriteria;
                                    }
                                ),
                            };
                        case AssignmentDimensionType.VARIABLE_R:
                            return {
                                ...conditionState,
                                variableCriterias: action.payload.conditionState.variableCriterias.map(
                                    (variableCriteria: VariableCriteria) => {
                                        if (variableCriteria.refDimensionCode === action.payload.dimensionCode) {
                                            variableCriteria.value = null;
                                            variableCriteria.systemVariable = null;
                                            variableCriteria.pricingCriteriaName = null;
                                            variableCriteria.variableName = action.payload.variable;
                                            variableCriteria.computingRule = null;
                                            variableCriteria.isVariableV = false;
                                        }
                                        return variableCriteria;
                                    }
                                ),
                            };
                        default:
                            return {
                                ...conditionState,
                                refGridCode: action.payload.refGridCode ?? "",
                                variableCriterias: action.payload.variableCriterias,
                                defaultValue:
                                    action.payload.defaultValue !== undefined ? action.payload.defaultValue : null,
                            };
                    }
            }

            break;
        }
        case GenericReducerActionType.REMOVE: {
            switch (action.assignmentType) {
                case AssignmentType.FIXED_VALUE:
                case AssignmentType.SYSTEM_VARIABLE:
                case AssignmentType.PRICING_CRITERIA:
                case AssignmentType.VARIABLE:
                case AssignmentType.VARIABLE_COMPUTING_RULE:
                    return {
                        ...conditionState,
                        refGridCode: null,
                        defaultValue: null,
                        variableCriterias: [
                            {
                                id: null,
                                value: null,
                                refDimensionCode: null,
                                variableName: null,
                                pricingCriteriaName: null,
                                computingRule: null,
                                systemVariable: null,
                                isVariableV: undefined
                            },
                        ],
                    };

                case AssignmentType.GRID_SEARCH:
                    if (action.assignmentDimensionType) {
                        switch (action.assignmentDimensionType) {
                            case AssignmentDimensionType.FIXED_VALUE:
                            case AssignmentDimensionType.SYSTEM_VARIABLE:
                            case AssignmentDimensionType.PRICING_CRITERIA:
                            case AssignmentDimensionType.VARIABLE:
                            case AssignmentDimensionType.VARIABLE_R:
                                return {
                                    ...conditionState,
                                    variableCriterias: action.payload.conditionState.variableCriterias.map(
                                        (variableCriteria: VariableCriteria) => {
                                            if (variableCriteria.refDimensionCode === action.payload.dimensionCode) {
                                                variableCriteria.value = null;
                                                variableCriteria.systemVariable = null;
                                                variableCriteria.pricingCriteriaName = null;
                                                variableCriteria.variableName = null;
                                                variableCriteria.isVariableV = undefined;
                                            }
                                            return variableCriteria;
                                        }
                                    ),
                                };

                            default:
                                return {
                                    ...conditionState,
                                    refGridCode: action.payload.refGridCode ?? "",
                                    defaultValue: null,
                                    variableCriterias: action.payload.variableCriterias,
                                };
                        }
                    } else {
                        return {
                            ...conditionState,
                            refGridCode: null,
                            defaultValue: null,
                            variableCriterias: [
                                {
                                    id: null,
                                    value: null,
                                    refDimensionCode: null,
                                    variableName: null,
                                    pricingCriteriaName: null,
                                    computingRule: null,
                                    systemVariable: null,
                                    isVariableV: undefined
                                },
                            ],
                        };
                    }
            }

            break;
        }

        case GenericReducerActionType.UNDO:
            return action.payload;
        default:
            return conditionState;
    }

    return conditionState;
};

export default variableConditionReducer;
