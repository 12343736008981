import {Control, Controller} from "react-hook-form";
import {Checkbox} from "semantic-ui-react";
import {PricingInflowForm} from "../../../../Services/Pricing/Types";

type UnitTestInsurancePackageOptionProps = {
    control: Control<PricingInflowForm>;
    insurancePackageCode: string;
    option: {code: string; label: string; registryCode: string; registryLabel: string};
};

const UnitTestInsurancePackageOption = ({
    control,
    insurancePackageCode,
    option,
}: UnitTestInsurancePackageOptionProps) => {
    return (
        <Controller
            control={control}
            name={`includeOptions.${insurancePackageCode}.${option.code}`}
            defaultValue={false}
            shouldUnregister
            render={({field}) => (
                <Checkbox
                    {...field}
                    className="option-toggle violet"
                    toggle
                    label={`${option.label} (${option.code})`}
                    value={undefined}
                    onChange={(_, data) => field.onChange(data.checked)}
                    checked={field.value}
                />
            )}
        />
    );
};

export default UnitTestInsurancePackageOption;
