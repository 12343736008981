import {Dispatch, Fragment, SetStateAction, useState} from "react";
import {Dimmer, Header, Input, Segment, Sidebar, Transition} from "semantic-ui-react";
import {RiskInformation} from "../../../../Services/Pricing/Types";

const flattenRiskInformation = (riskInfos: RiskInformation[]): RiskInformation[] => {
    const result: RiskInformation[] = [];

    const flatten = (infos: RiskInformation[], parentCode: string = ""): void => {
        infos.forEach((info) => {
            const newCode = parentCode ? `${parentCode}.${info.code}` : info.code;
            if (info.values && info.values.length > 0) {
                flatten(info.values, newCode);
            } else {
                result.push({code: newCode, value: info.value, values: null});
            }
        });
    };

    flatten(riskInfos);
    return result;
};

type UnitTestResultRiskInformationSidebarProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    riskInformations: RiskInformation[];
};

const UnitTestResultRiskInformationSidebar = ({
    isOpen,
    setIsOpen,
    riskInformations,
}: UnitTestResultRiskInformationSidebarProps) => {
    const [search, setSearch] = useState<string>("");

    const filter = (riskInformation: RiskInformation): boolean => {
        if (!search) return true;
        return riskInformation.code.toLowerCase().includes(search.toLowerCase());
    };

    const elementsFlattens = flattenRiskInformation(riskInformations);

    return (
        <Fragment>
            <Transition visible={isOpen} duration={500}>
                <Dimmer active={isOpen} style={{backgroundColor: "rgba(0, 0, 0, .6)"}} page />
            </Transition>

            <Sidebar
                as={Segment}
                visible={isOpen}
                onHide={() => setIsOpen(false)}
                direction="right"
                animation="overlay"
                style={{zIndex: 1001, padding: "2rem"}}
                width="very wide"
                data-cy="sidebar-risk-informations"
            >
                <Header as="h2" className="left">
                    Éléments complémentaires
                </Header>
                <Input
                    fluid
                    icon="search"
                    placeholder="Rechercher un élément..."
                    data-cy="search-risk-informations"
                    onChange={(e) => setSearch(e.target.value)}
                />

                <div className="risk-informations-list">
                    {elementsFlattens.filter(filter).map((riskInformation) => (
                        <div key={riskInformation.code} className="risk-informations-element">
                            <span>{riskInformation.code}</span>
                            <span style={{fontWeight: "bold"}}>{riskInformation.value}</span>
                        </div>
                    ))}
                </div>
            </Sidebar>
        </Fragment>
    );
};

export default UnitTestResultRiskInformationSidebar;
