import {useTranslation} from "react-i18next";
import {Menu, Tab, TabPane} from "semantic-ui-react";
import {InsurancePackageResult} from "../../../../Services/Pricing/Types";
import UnitTestResultAmountsPane from "./UnitTestResultAmountsPane";
import UnitTestResultCoveragesPane from "./UnitTestResultCoveragesPane";
import UnitTestResultDeductibleCapitalsPane from "./UnitTestResultDeductibleCapitalsPane";
import UnitTestResultFeesPane from "./UnitTestResultFeesPane";
import UnitTestResultNextPeriodsPane from "./UnitTestResultNextPeriodsPane";
import UnitTestResultOptionsPane from "./UnitTestResultOptionsPane";
import UnitTestResultPaymentSchedulePane from "./UnitTestResultPaymentSchedulePane";

const titleWithCount = (
    collection: unknown[] | null | undefined,
    label: string
): {disabled: boolean; content: string} => {
    if (!collection || collection.length === 0) {
        return {
            disabled: true,
            content: label,
        };
    } else {
        return {
            disabled: false,
            content: `${label} (${collection.length})`,
        };
    }
};

type UnitTestResultInsurancePackagePaneProps = {
    insurancePackage: InsurancePackageResult;
};

const UnitTestResultInsurancePackagePane = ({insurancePackage}: UnitTestResultInsurancePackagePaneProps) => {
    const {t} = useTranslation();

    const panes = [
        {
            menuItem: (
                <Menu.Item data-cy="amounts-pane-menu" key={"amounts-pane"}>
                    {t("Amounts")}
                </Menu.Item>
            ),
            render: () => <UnitTestResultAmountsPane insurancePackage={insurancePackage} />,
        },
        {
            menuItem: (
                <Menu.Item
                    data-cy="payment-schedule-pane-menu"
                    key={"payment-schedule-pane"}
                    {...titleWithCount(insurancePackage.paymentSchedule, t("deadlines"))}
                />
            ),
            render: () => <UnitTestResultPaymentSchedulePane paymentSchedule={insurancePackage.paymentSchedule} />,
        },
        {
            menuItem: (
                <Menu.Item
                    data-cy="next-periods-pane-menu"
                    key={"next-periods-pane"}
                    {...titleWithCount(insurancePackage.nextPeriods, t("secondaryDeadlines"))}
                />
            ),
            render: () => <UnitTestResultNextPeriodsPane nextPeriods={insurancePackage.nextPeriods} />,
        },
        {
            menuItem: (
                <Menu.Item
                    data-cy="coverages-pane-menu"
                    key={"coverages-pane"}
                    {...titleWithCount(insurancePackage.coverages, t("Coverages"))}
                />
            ),
            render: () => <UnitTestResultCoveragesPane coverages={insurancePackage.coverages} />,
        },
        {
            menuItem: (
                <Menu.Item
                    data-cy="fees-pane-menu"
                    key={"fees-pane"}
                    {...titleWithCount(insurancePackage.feesCharges, t("fees"))}
                />
            ),
            render: () => <UnitTestResultFeesPane fees={insurancePackage.feesCharges} />,
        },
        {
            menuItem: (
                <Menu.Item
                    data-cy="options-pane-menu"
                    key={"options-pane"}
                    {...titleWithCount(insurancePackage.options, t("Options"))}
                />
            ),
            render: () => <UnitTestResultOptionsPane options={insurancePackage.options} />,
        },
        {
            menuItem: (
                <Menu.Item
                    data-cy="deductible-capitals-pane-menu"
                    key={"deductible-capitals-pane"}
                    {...titleWithCount(insurancePackage.deductiblesCapitals, t("Deductible capitals"))}
                />
            ),
            render: () => (
                <UnitTestResultDeductibleCapitalsPane deductibleCapitals={insurancePackage.deductiblesCapitals} />
            ),
        },
    ];

    return (
        <TabPane>
            <Tab className="remove-tab-border" panes={panes} />
        </TabPane>
    );
};

export default UnitTestResultInsurancePackagePane;
