import {CSSProperties, Fragment, HTMLInputTypeAttribute, useState} from "react";
import {Controller, ControllerFieldState, ControllerRenderProps, UseFormReturn} from "react-hook-form";
import {Dropdown, DropdownItemProps, Grid, Input, Label} from "semantic-ui-react";
import {PricingInflowForm} from "../../../../Services/Pricing/Types";
import {useGetQuestionsQuery} from "../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../Services/Question/Types";
import {VersionDTO} from "../../../../Services/Version/Types";
import {useTranslation} from "react-i18next";

type UnitTestQuestionsProps = {
    version: VersionDTO;
    form: UseFormReturn<PricingInflowForm>;
};

const UnitTestQuestions = ({version, form}: UnitTestQuestionsProps) => {
    const {t} = useTranslation();
    const [search, setSearch] = useState<string | undefined>();

    const getQuestionsQuery = useGetQuestionsQuery(String(version.id));

    const renderField = (
        field: ControllerRenderProps<PricingInflowForm, `riskInformation.${string}`>,
        fieldState: ControllerFieldState,
        question: QuestionDTO
    ) => {
        if (question.valueType === QuestionValueTypeEnum.LISTE) {
            const options: Array<DropdownItemProps> = question.questionValues.map((value) => ({
                key: value.id,
                text: value.value,
                value: value.value,
            }));

            const style: CSSProperties = {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            };

            return (
                <div className="ui fluid icon left labeled input">
                    <Label>{question.name}</Label>
                    <Dropdown
                        {...field}
                        fluid
                        style={style}
                        clearable
                        selection
                        onChange={(_, {value}) => field.onChange(value)}
                        options={options}
                        error={!!fieldState.error}
                    />
                    {question.mandatory && <i className="icon asterisk" />}
                </div>
            );
        }

        return (
            <Input
                {...field}
                fluid
                type={getInputTypeByQuestionType(question.valueType)}
                label={question.name}
                labelPosition="left"
                icon={question.mandatory && "asterisk"}
                onChange={(_, {value}) => field.onChange(value)}
                error={!!fieldState.error}
            />
        );
    };

    const getInputTypeByQuestionType = (questionType: QuestionValueTypeEnum): HTMLInputTypeAttribute => {
        switch (questionType) {
            case QuestionValueTypeEnum.STRING:
                return "text";
            case QuestionValueTypeEnum.NOMBRE:
                return "number";
            case QuestionValueTypeEnum.DATE:
                return "date";
            default:
                return "text";
        }
    };

    return (
        <Fragment>
            <Grid.Row>
                <Grid.Column floated="right">
                    <Input
                        fluid
                        icon="search"
                        data-cy="question-search"
                        placeholder={t("Find entity_female", {entity: t("Question").toLowerCase()})}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row data-cy="questions-list">
                {getQuestionsQuery.isSuccess &&
                    getQuestionsQuery.data.map((question) => {
                        const isVisible = !search || question.name.toLowerCase().includes(search.toLowerCase());
                        const styleDisplay = {display: isVisible ? "" : "none"};

                        return (
                            <Grid.Column key={question.id} style={styleDisplay}>
                                <Controller
                                    control={form.control}
                                    name={`riskInformation.${question.name}`}
                                    defaultValue=""
                                    rules={{required: question.mandatory}}
                                    render={({field, fieldState}) => renderField(field, fieldState, question)}
                                />
                            </Grid.Column>
                        );
                    })}
            </Grid.Row>
        </Fragment>
    );
};

export default UnitTestQuestions;
