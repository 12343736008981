import {Placeholder, Table} from "semantic-ui-react";

type TableBodyPlaceholderProps = {
    numbersOfColumns: number;
};

const TableBodyPlaceholder = ({numbersOfColumns}: TableBodyPlaceholderProps) => {
    return Array.from(Array(3).keys()).map((value) => (
        <Table.Row key={`tableBodyPlaceholder-${value}`}>
            <Table.Cell colSpan={numbersOfColumns}>
                <Placeholder fluid>
                    <Placeholder.Header>
                        <Placeholder.Line length="full" />
                    </Placeholder.Header>
                </Placeholder>
            </Table.Cell>
        </Table.Row>
    ));
};

export default TableBodyPlaceholder;
