import {Fragment, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Divider, DropdownItemProps, Form, Grid, Header, Segment, TextArea} from "semantic-ui-react";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import {useGetCompanyProductInfos, useGetRangesQuery, useRiskTypesQuery} from "../../../../../Services/Company/Queries";
import {CompanyProductInfosType, RangeType, RiskTypeItemType} from "../../../../../Services/Company/Types";
import {useUpdateProductMutation} from "../../../../../Services/Product/Queries";
import {
    DebitModeType,
    EditedProductDetailsType,
    ProductDetailsType,
    ProductInfoDTO,
} from "../../../../../Services/Product/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import {DeadlineType, getDefaultValueDeadlineType, TransientModeType} from "../../NewProductPage";

type PropsType = {
    openEditProductDetails: () => void;
    productDetails: ProductDetailsType;
};

const getMaxDayByMonth = (month: number) => {
    if (month === 2) {
        return 28;
    } else if ([4, 6, 9, 11].includes(month)) {
        return 30;
    } else {
        return 31;
    }
};

const ProductDetailsEdit = ({openEditProductDetails, productDetails}: PropsType) => {
    const {t} = useTranslation();

    const debitModeOptions = Object.values(DebitModeType).map((debitModeType: DebitModeType, index: number) => {
        return {
            key: "debitMode" + index,
            text: t("enums.DebitModeType." + debitModeType),
            value: debitModeType,
        };
    });

    const deadlineOptions = Object.values(DeadlineType).map(
        (deadlineType: DeadlineType, index: number): DropdownItemProps => {
            return {
                key: "deadline" + index,
                text: t("enums.DeadlineType." + deadlineType),
                value: deadlineType,
            };
        }
    );

    const transientModeOptions = Object.values(TransientModeType).map(
        (transientModeType: TransientModeType, index: number) => {
            return {
                key: "transientMode" + index,
                text: t("enums.TransientModeType." + transientModeType),
                value: transientModeType,
            };
        }
    );

    const productDetailsForm = useForm<EditedProductDetailsType>({
        mode: "onBlur",
        defaultValues: {
            code: productDetails.code,
            currency: productDetails.currency,
            deadlineByDay: productDetails.deadlineByDay,
            deadlineByMonth: productDetails.deadlineByMonth,
            deadlineType: getDefaultValueDeadlineType(productDetails.deadlineByDay, productDetails.deadlineByMonth),
            debitMode: DebitModeType[productDetails.debitMode as keyof typeof DebitModeType],
            delayedEffectLimit: productDetails.delayedEffectLimit,
            endorsementQuoteValidityDuration: productDetails.endorsementQuoteValidityDuration,
            label: productDetails.label,
            mainTransient: productDetails.mainTransient,
            openingDate: productDetails.openingDate,
            principalCompanyCode: productDetails.principalCompanyCode,
            quoteValidityDuration: productDetails.quoteValidityDuration,
            secondaryTransient: productDetails.secondaryTransient,
            transientMode: productDetails.transientMode,
            riskTypeId: productDetails.riskType.id,
            rangeId: productDetails.range === null ? undefined : productDetails.range.id,
        },
    });

    const [productInfosEdit, setProductInfosEdit] = useState<Array<ProductInfoDTO> | undefined>(
        productDetails.productInfos ? [...productDetails.productInfos] : productDetails.productInfos
    );
    const [riskTypesOptions, setRiskTypesOptions] = useState<DropdownItemProps[]>([]);
    const [rangesOptions, setRangesOptions] = useState<DropdownItemProps[]>([]);

    const riskTypesQuery = useRiskTypesQuery();
    const rangesQuery = useGetRangesQuery(String(productDetailsForm.watch("riskTypeId")));
    const getCompanyProductInfos = useGetCompanyProductInfos();

    const updateProductMutation = useUpdateProductMutation(String(productDetails.id));

    const onProductUpdateSuccess = () => {
        openEditProductDetails();
    };

    useEffect(() => {
        if (riskTypesQuery.isSuccess && riskTypesQuery.data) {
            setRiskTypesOptions(
                riskTypesQuery.data.map((item: RiskTypeItemType): DropdownItemProps => {
                    return {key: item.id, text: item.label, value: item.id};
                })
            );
        }
    }, [riskTypesQuery.isSuccess, riskTypesQuery.data]);

    useEffect(() => {
        if (rangesQuery.isSuccess && rangesQuery.data) {
            setRangesOptions([
                {key: 0, text: t("No range"), value: ""},
                ...rangesQuery.data.map((item: RangeType): DropdownItemProps => {
                    return {key: item.id, text: item.label, value: item.id};
                }),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangesQuery.isSuccess, rangesQuery.data]);

    const submitForm = async (formData: EditedProductDetailsType) => {
        const productInfoTmp = productInfosEdit?.filter((value: ProductInfoDTO) => value.value !== "");

        const newProductDetails = {
            ...formData,
            currency: productDetails.currency,
            mainTransient: productDetails.mainTransient,
            secondaryTransient: productDetails.secondaryTransient,
            deadlineByDay: formData.deadlineByDay ?? 0,
            deadlineByMonth: formData.deadlineByMonth ?? 0,
            productInfos: productInfoTmp
                ? productInfoTmp?.map((item: ProductInfoDTO) => ({
                      value: item.value,
                      label: item.label,
                  }))
                : [],
        };

        updateProductMutation.mutate(newProductDetails);
    };

    const handleProductInfosChange = (label: string, value: string) => {
        if (productInfosEdit?.find((productInfo) => productInfo.label === label)) {
            const index = productInfosEdit?.findIndex(
                (productInfo) => productInfo.label.toLowerCase() === label.toLowerCase()
            );
            const productInfosEditTemp = [...productInfosEdit];
            const info = {...productInfosEditTemp[index]};
            info.value = value;
            productInfosEditTemp[index] = info;
            setProductInfosEdit(productInfosEditTemp);
        } else if (productInfosEdit) {
            const productInfo = {
                label: label,
                value: value,
            };
            setProductInfosEdit([...productInfosEdit, productInfo]);
        } else {
            const productInfosEditTemp = [];
            const productInfo = {
                label: label,
                value: value,
            };
            productInfosEditTemp?.push(productInfo);
            setProductInfosEdit([...productInfosEditTemp]);
        }
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("Product")}
            </Header>
            <Form>
                <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <strong>{t("Code")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                rules={{required: true}}
                                defaultValue={productDetails.code}
                                name={"code"}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        id="form-product-details-input-code"
                                        placeholder={t("code")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="10"
                                        errors={t("this field is required")}
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, "code")}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("Label")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                defaultValue={productDetails.label}
                                name={"label"}
                                rules={{required: true}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        id="form-product-details-input-libelle"
                                        placeholder={t("Label")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        errors={t("this field is required")}
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, "label")}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("Company code")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                rules={{required: true}}
                                defaultValue={productDetails.principalCompanyCode}
                                name={"principalCompanyCode"}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        name={name}
                                        fluid
                                        id="form-product-details-input-code-compagnie"
                                        placeholder={t("Company code")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength={"5"}
                                        error={getErrorMessage(
                                            t,
                                            productDetailsForm.formState.errors,
                                            "principalCompanyCode"
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("quotation_validity_duration_modification")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"quoteValidityDuration"}
                                defaultValue={productDetails.quoteValidityDuration}
                                rules={{required: true, min: 0}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        id={"form-product-details-input-days-valide"}
                                        placeholder={t("quotation_validity_duration_modification")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="number"
                                        min="0"
                                        label={{content: "jour(s)"}}
                                        error={getErrorMessage(
                                            t,
                                            productDetailsForm.formState.errors,
                                            "quoteValidityDuration",
                                            undefined,
                                            undefined,
                                            undefined,
                                            0
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("endorsement_quotation_validity_duration_modification")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"endorsementQuoteValidityDuration"}
                                defaultValue={productDetails.endorsementQuoteValidityDuration}
                                rules={{required: true, min: 0}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        placeholder={t("endorsement_quotation_validity_duration")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="number"
                                        min="0"
                                        error={getErrorMessage(
                                            t,
                                            productDetailsForm.formState.errors,
                                            name,
                                            undefined,
                                            undefined,
                                            undefined,
                                            0
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("Opening_date")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"openingDate"}
                                defaultValue={productDetails.openingDate}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        id={"form-product-details-openingDate"}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="datetime-local"
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, "openingDate")}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <strong>{t("Transient Mode")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"transientMode"}
                                defaultValue={productDetails.transientMode}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        required
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={(_, {value}) => {
                                            onChange(value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, "transientMode")}
                                        options={transientModeOptions}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("deadlinePrincipal")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                rules={{required: true}}
                                name={"deadlineType"}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        required
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={(_, {value}) => {
                                            onChange(value);
                                            if (value === "deadlineFixe") {
                                                productDetailsForm.setValue("deadlineByDay", 1);
                                                productDetailsForm.setValue("deadlineByMonth", 1);
                                            } else if (value === "deadlineBirthday") {
                                                productDetailsForm.setValue("deadlineByDay", 0);
                                                productDetailsForm.setValue("deadlineByMonth", 0);
                                            } else {
                                                productDetailsForm.setValue("deadlineByDay", 1);
                                                productDetailsForm.setValue("deadlineByMonth", 0);
                                            }
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, "deadlineType")}
                                        options={deadlineOptions}
                                    />
                                )}
                            />
                        </Grid.Column>

                        {(productDetailsForm.watch("deadlineType") === "deadlineFixe" ||
                            productDetailsForm.watch("deadlineType") === "deadlineDayFixMonthBirthday") && (
                            <>
                                <Grid.Column width={6}>
                                    <strong>{t("deadlineByDay")} * : </strong>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Controller
                                        control={productDetailsForm.control}
                                        name={"deadlineByDay"}
                                        rules={{
                                            required: true,
                                            min: 1,
                                            max: getMaxDayByMonth(productDetailsForm.watch("deadlineByMonth")),
                                        }}
                                        render={({field: {value, name, onChange, onBlur}}) => (
                                            <Form.Input
                                                fluid
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                type="number"
                                                min={1}
                                                max={getMaxDayByMonth(productDetailsForm.watch("deadlineByMonth"))}
                                                error={getErrorMessage(
                                                    t,
                                                    productDetailsForm.formState.errors,
                                                    "deadlineByDay",
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    1,
                                                    getMaxDayByMonth(productDetailsForm.watch("deadlineByMonth"))
                                                )}
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </>
                        )}

                        {productDetailsForm.watch("deadlineType") === "deadlineFixe" && (
                            <>
                                <Grid.Column width={6}>
                                    <strong>{t("deadlineByMonth")} * : </strong>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Controller
                                        control={productDetailsForm.control}
                                        name={"deadlineByMonth"}
                                        rules={{required: true, min: 1, max: 12}}
                                        render={({field: {value, name, onChange, onBlur}}) => (
                                            <Form.Input
                                                fluid
                                                name={name}
                                                onChange={(_, {value}) => {
                                                    onChange(value);
                                                    if (
                                                        [4, 6, 9, 11].includes(Number(value)) &&
                                                        productDetailsForm.watch("deadlineByDay") > 30
                                                    ) {
                                                        productDetailsForm.setValue("deadlineByDay", 30);
                                                    } else if (
                                                        value === "2" &&
                                                        productDetailsForm.watch("deadlineByDay") > 28
                                                    ) {
                                                        productDetailsForm.setValue("deadlineByDay", 28);
                                                    }
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                type="number"
                                                min={1}
                                                max={12}
                                                error={getErrorMessage(
                                                    t,
                                                    productDetailsForm.formState.errors,
                                                    "deadlineByMonth",
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    1,
                                                    12
                                                )}
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </>
                        )}

                        <Grid.Column width={6}>
                            <strong>{t("Debit mode")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                rules={{required: true}}
                                control={productDetailsForm.control}
                                name={"debitMode"}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        name={name}
                                        required
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={(_, {value}) => {
                                            onChange(value);
                                        }}
                                        options={debitModeOptions}
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, name)}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("Delayed effect limit")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"delayedEffectLimit"}
                                rules={{min: 0}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        id={"form-product-details-delayed-effect-limit"}
                                        onChange={(_, {value}) => {
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        type="number"
                                        min="0"
                                        error={getErrorMessage(
                                            t,
                                            productDetailsForm.formState.errors,
                                            name,
                                            undefined,
                                            undefined,
                                            undefined,
                                            0
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Header as="h2" dividing>
                    {t("classification")}
                </Header>

                <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <strong>{t("risk_type")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"riskTypeId"}
                                rules={{required: true}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        name={name}
                                        maxLength="50"
                                        placeholder={t("risk_type")}
                                        onChange={(_, {value}) => {
                                            onChange(value ?? null);
                                            productDetailsForm.setValue("rangeId", NaN);
                                        }}
                                        value={value}
                                        onBlur={onBlur}
                                        options={riskTypesOptions}
                                        error={getErrorMessage(t, productDetailsForm.formState.errors, "riskTypeId")}
                                    />
                                )}
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <strong>{t("Range")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={productDetailsForm.control}
                                name={"rangeId"}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        name={name}
                                        onBlur={onBlur}
                                        disabled={!(rangesQuery.isSuccess && rangesQuery.data !== undefined)}
                                        maxLength="50"
                                        placeholder={t("Range")}
                                        onChange={(_, {value}) => onChange(value ?? null)}
                                        value={value}
                                        options={rangesOptions}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                {getCompanyProductInfos.isSuccess &&
                    getCompanyProductInfos.data !== undefined &&
                    getCompanyProductInfos.data.length !== 0 && (
                        <>
                            <Header as="h2" dividing>
                                {t("Additional_information", {count: getCompanyProductInfos.data.length})}
                            </Header>

                            <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                <Grid.Row>
                                    {getCompanyProductInfos.data.map((info: CompanyProductInfosType) => (
                                        <Fragment key={info.label}>
                                            <Grid.Column width={6} verticalAlign="middle">
                                                <strong>{info.label} : </strong>
                                            </Grid.Column>
                                            <Grid.Column width={10}>
                                                <Form.Field
                                                    style={{height: "42px"}}
                                                    id={`form-textarea-control-info-compl${info.label}`}
                                                    name={info.label}
                                                    control={TextArea}
                                                    onChange={(_: Event, {value}: {value: string}) =>
                                                        handleProductInfosChange(info.label, value)
                                                    }
                                                    value={
                                                        productInfosEdit?.find(
                                                            (productinfo) =>
                                                                productinfo.label.toLowerCase() ===
                                                                info.label.toLowerCase()
                                                        )?.value ?? ""
                                                    }
                                                />
                                            </Grid.Column>
                                        </Fragment>
                                    ))}
                                </Grid.Row>
                            </Grid>
                        </>
                    )}

                <div className="bottom-button-card">
                    <Divider />
                    <Button name="cancel" secondary onClick={openEditProductDetails}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        isModalDisabled={Object.keys(productDetailsForm.formState.errors).length !== 0}
                        customContent={t("You are about to change product informations")}
                        onValidate={productDetailsForm.handleSubmit(submitForm)}
                        onSuccess={onProductUpdateSuccess}
                        isPending={updateProductMutation.isPending}
                        isError={updateProductMutation.isError}
                        isSuccess={updateProductMutation.isSuccess}
                        error={updateProductMutation.error}
                        resetMutation={updateProductMutation.reset}
                    />
                </div>
            </Form>
        </Segment>
    );
};

export default ProductDetailsEdit;
