import {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Grid, Header} from "semantic-ui-react";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import {ProductDetailsType} from "../../../../Services/Product/Types";

type ProductBreadcrumbProps = {
    product: ProductDetailsType;
    sections: Array<{name: string; path: string}>;
};

const ProductBreadcrumb = ({product, sections}: ProductBreadcrumbProps) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    {product.label}
                </Header>
                <div className="sub-header">
                    <Grid columns="equal">
                        <Grid.Column>
                            <Sections sections={sections} />
                        </Grid.Column>
                        <Grid.Column>
                            <Button floated="right" color={"teal"} as={Link} to={`/products/${product.id}/unit_test`}>
                                {t("Test the product")}
                            </Button>
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductBreadcrumb;
