import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Container, Grid, Input, Menu, Segment, Select} from "semantic-ui-react";
import {Breadcrumb} from "../../../Components/Routes/BreadcrumbsInjectorWrapper";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SimpleList from "../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../Components/SimpleList/SimpleListTypes";
import {useProductsSidebarMenu} from "../../../Hooks/useProductsSidebarMenu";
import {useGetProducts} from "../../../Services/Product/Queries";
import {useGetCommissionPackages} from "../../Services/CommissionPackages/Queries";
import BreadcrumbCommissionPackage from "./Fragment/BreadcrumbCommissionPackage";
import {ProductRecap} from "../../../Services/Product/Types";

const commissionPackagesColumns = [
    {text: "Product", dataField: "productCode", type: DataTypeColumnEnum.STRING},
    {text: "Schema", dataField: "schema", type: DataTypeColumnEnum.STRING},
    {text: "Business_Provider_Code", dataField: "businessProviderCode", type: DataTypeColumnEnum.STRING},
    {text: "Start_Date", dataField: "startDate", type: DataTypeColumnEnum.DATE},
    {text: "End_Date", dataField: "endDate", type: DataTypeColumnEnum.DATE},
    {text: "Commission_Coverages", dataField: "commissionCoverages", type: DataTypeColumnEnum.LIST},
    {text: "Retrocession_Type", dataField: "retrocessionType", type: DataTypeColumnEnum.ENUM},
    {text: "First_Year_Rate", dataField: "firstYearRate", type: DataTypeColumnEnum.STRING},
    {text: "Second_Year_Rate", dataField: "secondYearRate", type: DataTypeColumnEnum.STRING},
    {text: "Next_Years_Rate", dataField: "nextYearsRate", type: DataTypeColumnEnum.STRING},
    {text: "Commitment_Duration", dataField: "commitmentDuration", type: DataTypeColumnEnum.STRING},
    {text: "Recovery_Type", dataField: "recoveryType", type: DataTypeColumnEnum.ENUM},
    {text: "First_Year_Recovery_Rate", dataField: "firstYearRecoveryRate", type: DataTypeColumnEnum.STRING},
    {text: "Second_Year_Recovery_Rate", dataField: "secondYearRecoveryRate", type: DataTypeColumnEnum.STRING},
    {text: "Total_Recovery_Month_Number", dataField: "totalRecoveryMonthNumber", type: DataTypeColumnEnum.STRING},
];

const CommissionPackagesPage = (props: {sections: Array<Breadcrumb>}) => {
    const {t} = useTranslation();
    const menus = useProductsSidebarMenu();

    const [search, setSearch] = useState<string>("");
    const [productCodeFilter, setProductCodeFilter] = useState<string>("");
    const [commissionPackagesQueryEnabled, setCommissionPackagesQueryEnabled] = useState<boolean>(false);

    const getProductsQuery = useGetProducts();
    const [productList, setProductList] = useState<{key: string; value: string; text: string}[]>([]);
    const getCommissionPackagesQuery = useGetCommissionPackages(productCodeFilter, commissionPackagesQueryEnabled);

    const commissionPackagesFilter = (searchValue: string) => {
        if (getCommissionPackagesQuery.isSuccess) {
            return getCommissionPackagesQuery.data.filter(
                (commissionPackage) =>
                    commissionPackage.schema.toLowerCase().includes(searchValue.toLowerCase()) ||
                    commissionPackage.businessProviderCode?.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
    };

    useEffect(() => {
        if (getProductsQuery.isSuccess && getProductsQuery.data) {
            setProductList(
                getProductsQuery.data.sort((p1: ProductRecap, p2: ProductRecap) => (p1.label.toUpperCase() < p2.label.toUpperCase() ? -1 : 1)).map((product) => {
                    return {key: product.code, value: product.code, text: product.label};
                })
            );
        }
    }, [getProductsQuery.isSuccess, getProductsQuery.data]);

    useEffect(() => {
        if (productList.length > 0) {
            setCommissionPackagesQueryEnabled(true);
            setProductCodeFilter(productList[0].value);
        }
    }, [productList]);

    const handleFilterChange = (value: string) => {
        setProductCodeFilter(value);
    };

    return (
        <Fragment>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            <BreadcrumbCommissionPackage productCode={productCodeFilter} sections={props.sections} />
            <Container fluid>
                <div className="main-container">
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                                        <Menu.Item style={{padding: 0}}>
                                            {getProductsQuery.isSuccess && getProductsQuery.data !== undefined && (
                                                <Select
                                                    name="productFilter"
                                                    value={productCodeFilter}
                                                    onChange={(_, product) =>
                                                        handleFilterChange(product.value as string)
                                                    }
                                                    options={productList}
                                                />
                                            )}
                                        </Menu.Item>
                                        <Menu.Menu position="right">
                                            <Menu.Item style={{padding: 0}}>
                                                <Input
                                                    icon="search"
                                                    placeholder={t("Schema, Business Provider Code")}
                                                    defaultValue={search}
                                                    onChange={(_, data) => setSearch(data.value)}
                                                />
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu>

                                    <Segment
                                        id="commission-package-table"
                                        style={{overflow: "auto", border: "none", boxShadow: "none", padding: 0}}
                                    >
                                        {getCommissionPackagesQuery.isSuccess &&
                                            getCommissionPackagesQuery.data !== undefined && (
                                                <SimpleList
                                                    columns={commissionPackagesColumns}
                                                    rows={commissionPackagesFilter(search) || []}
                                                    globalData={[]}
                                                    translationNameSpace="generation"
                                                />
                                            )}
                                    </Segment>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>
        </Fragment>
    );
};

export default CommissionPackagesPage;
