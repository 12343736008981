import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../Components/Sidebar/Sidebar";
import { useGetIndexValueListQuery } from "../../../Services/Company/Queries";
import { IndexValue } from "../../../Services/Company/Types";
import IndexValueDetails from "./Fragments/IndexValueDetails";

export interface IParams extends Params {
    indexId: string;
    valueId: string;
}

const IndexValuePage = () => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [sidebarMenus, setSidebarMenus] = useState<Array<SidebarMenu>>([]);

    const getIndexValueList = useGetIndexValueListQuery(Number(params.indexId));
    
    const [indexValue, setIndexValue] = useState<IndexValue>();

    useEffect(() => {
        if (getIndexValueList.isSuccess) {
            const match = getIndexValueList.data.filter(value => value.id === Number(params.valueId))[0];

            const menus = new SidebarMenu(
                "",
                getIndexValueList.data
                    .sort((a: IndexValue, b: IndexValue) => {
                        return new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime();
                    })
                    .map(
                        (value) =>
                            new SidebarItem(
                                value.effectiveDate,
                                new Date(value.effectiveDate).toLocaleDateString("fr"),
                                `/transversal_settings/indexes/${params.indexId}/values/${value.id}`,
                            )
                )
            );
            setSidebarMenus([menus]);

            setIndexValue(match);
        }
    }, [getIndexValueList.isSuccess, getIndexValueList.data, params.valueId, params.indexId]);

    return (
        <Container fluid>
            <Sidebar title={t("Index values")} menus={sidebarMenus} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            {indexValue !== undefined && (
                                <IndexValueDetails
                                    indexId={Number(params.indexId)}
                                    indexValue={indexValue}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
}

export default IndexValuePage;