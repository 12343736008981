import {useTranslation} from "react-i18next";
import {TabPane} from "semantic-ui-react";
import {DeductibleCapitalResult} from "../../../../Services/Pricing/Types";
import {groupBy} from "../../../../Utils/ObjectUtils";

type UnitTestResultDeductibleCapitalsPaneProps = {
    deductibleCapitals: DeductibleCapitalResult[];
};

const UnitTestResultDeductibleCapitalsPane = ({deductibleCapitals}: UnitTestResultDeductibleCapitalsPaneProps) => {
    const {t} = useTranslation();
    const grouped = groupBy(deductibleCapitals, ({deductibleCapitalType}) => deductibleCapitalType);

    const renderDeductibleCapital = (deductibleCapital: DeductibleCapitalResult): JSX.Element => {
        return (
            <div key={deductibleCapital.deductibleCapitalLabel} className="row">
                <div className="col bold">{deductibleCapital.deductibleCapitalLabel}&nbsp;:&nbsp;</div>
                <div className="col">{deductibleCapital.deductibleCapitalContent}</div>
            </div>
        );
    };

    return (
        <TabPane>
            <div className="amounts-pane">
                <div data-cy="deductibles">
                    <b>{t("Deductibles")}</b>
                    {(!grouped.F || grouped.F.length === 0) && <p className="italic">{t("No matching items")}</p>}
                    {grouped.F?.map((d) => renderDeductibleCapital(d))}
                </div>
                <div data-cy="capitals">
                    <b>{t("Capitals")}</b>
                    {(!grouped.C || grouped.C.length === 0) && <p className="italic">{t("No matching items")}</p>}
                    {grouped.C?.map((d) => renderDeductibleCapital(d))}
                </div>
            </div>
        </TabPane>
    );
};

export default UnitTestResultDeductibleCapitalsPane;
