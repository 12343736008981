import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationsCoverlifeFr from "../Config/translations-coverlife.fr.json";
import translationsGenerationFr from "../Config/translations-generation.fr.json";
import translationsFr from "../Config/translations.fr.json";

const resources = {
    en: {
        common: {},
    },
    fr: {
        common: translationsFr,
        coverlife: translationsCoverlifeFr,
        generation: translationsGenerationFr,
    },
};

i18n.use(initReactI18next).init({
    resources,
    ns: ["common", "coverlife", "generation"],
    defaultNS: "common",
    fallbackNS: ["coverlife", "generation"],
    lng: "fr",

    interpolation: {
        escapeValue: false, // react already safes from xss

        // Renseigner cette function fait que i18 nous considère en mode "legacy" : https://www.i18next.com/translation-function/formatting#legacy-format-function-i18next-less-than-21.3.0
        // Cela nous empêche d'utiliser les formatters par défauts, non fonctionnels du coups.
        format: (value, format, lng) => {
            if (format === "date") {
                return new Date(value).toLocaleDateString(lng);
            } else if (format === "datetime") {
                return new Date(value).toLocaleDateString(lng) + " " + new Date(value).toLocaleTimeString(lng);
            }

            return value;
        },
        alwaysFormat: true,
    },
});

export default i18n;
