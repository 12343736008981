import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionPanelProps,
    AccordionTitleProps,
    SemanticShorthandCollection,
    TabPane,
} from "semantic-ui-react";
import {NextPeriod} from "../../../../Services/Pricing/Types";
import {formatBigDecimalAsCurrency} from "../../../../Utils/NumberUtils";
import UnitTestResultExpandShrink from "./UnitTestResultExpandShrink";

type UnitTestResultNextPeriodsPaneProps = {
    nextPeriods: NextPeriod[];
    isFee?: boolean; // Pour les frais, il ne faut pas afficher les encarts "Commissions" et "Rétrocessions"
    isNested?: boolean; // Le même composant pour les échéances de la formule et celles des garanties / options. Si "isNested" est à true -> garanties / options
};

const UnitTestResultNextPeriodsPane = ({
    nextPeriods,
    isFee = false,
    isNested = false,
}: UnitTestResultNextPeriodsPaneProps) => {
    const {t} = useTranslation();
    const [activesIndexs, setActivesIndexs] = useState<number[] | undefined>();

    const handleAccordionTitleClick = (_: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const {index, active} = data;
        const newState = activesIndexs?.filter((i) => i !== index) ?? [];
        if (!active) newState.push(index as number);
        setActivesIndexs(newState);
    };

    const numberOfPeriods: number = nextPeriods.length ?? 0;
    const panels: SemanticShorthandCollection<AccordionPanelProps> = nextPeriods.map((period, i) => {
        const active = activesIndexs?.includes(i);
        const titleClasses = isNested ? "nested" : undefined;

        return {
            title: (
                <Accordion.Title className={titleClasses}>
                    <i aria-hidden="true" className="dropdown icon" />
                    {t("Deadline x over x - Amount withdrawn x", {
                        index: i + 1,
                        over: numberOfPeriods,
                        amount: formatBigDecimalAsCurrency(period.proratePremiumInclVat, t),
                    })}
                </Accordion.Title>
            ),
            content: (
                <Accordion.Content active={active}>
                    <div className="amounts-pane">
                        {!isNested && (
                            <div data-cy="period-dates">
                                <div className="row gap-1">
                                    <div className="col">
                                        <span>{t("Effective date")}</span>
                                        <span>{t("Period end date")}</span>
                                    </div>
                                    <div className="col bold">
                                        <span>{t("format_date", {value: period.effectiveDate})}</span>
                                        <span>{t("format_date", {value: period.periodEndDate})}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div data-cy="amounts">
                            <b>{t("Amounts")}</b>
                            <div className="row gap-1">
                                <div className="col">{t("Prorate")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(period.proratePremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(period.netProratePremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">{formatBigDecimalAsCurrency(period.taxProrate, t)}</div>
                            </div>
                        </div>

                        {!isFee && (
                            <Fragment>
                                <div data-cy="commission-amounts">
                                    <b>{t("Commission amounts")}</b>
                                    <div className="row gap-1">
                                        <div className="col">{t("Contribution")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.contributionCommissionAmount, t)}
                                        </div>
                                    </div>
                                    <div className="row gap-1">
                                        <div className="col">{t("Management")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.managementCommissionAmount, t)}
                                        </div>
                                    </div>
                                </div>

                                <div data-cy="retrocessions-amounts">
                                    <b>{t("Retrocessions amounts")}</b>
                                    <div className="row gap-1">
                                        <div className="col">{t("business_provider_x", {number: 1})}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.retrocessionAmount1, t)}
                                        </div>
                                        <div className="col">{t("Calculation basis")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.baseRetrocessionAmount1, t)}
                                        </div>
                                    </div>
                                    <div className="row gap-1">
                                        <div className="col">{t("business_provider_x", {number: 2})}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.retrocessionAmount2, t)}
                                        </div>
                                        <div className="col">{t("Calculation basis")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.baseRetrocessionAmount2, t)}
                                        </div>
                                    </div>
                                    <div className="row gap-1">
                                        <div className="col">{t("business_provider_x", {number: 3})}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.retrocessionAmount3, t)}
                                        </div>
                                        <div className="col">{t("Calculation basis")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.baseRetrocessionAmount3, t)}
                                        </div>
                                    </div>
                                    <div className="row gap-1">
                                        <div className="col">{t("business_provider_x", {number: 4})}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.retrocessionAmount4, t)}
                                        </div>
                                        <div className="col">{t("Calculation basis")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.baseRetrocessionAmount4, t)}
                                        </div>
                                    </div>
                                    <div className="row gap-1">
                                        <div className="col">{t("business_provider_x", {number: 5})}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.retrocessionAmount5, t)}
                                        </div>
                                        <div className="col">{t("Calculation basis")}</div>
                                        <div className="col right bold">
                                            {formatBigDecimalAsCurrency(period.baseRetrocessionAmount5, t)}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Accordion.Content>
            ),
        };
    });

    const content = (
        <Fragment>
            {!isNested && <UnitTestResultExpandShrink maxIndex={panels.length} setActivesIndexes={setActivesIndexs} />}

            <Accordion
                styled
                exclusive={false}
                fluid
                panels={panels}
                activeIndex={activesIndexs}
                onTitleClick={handleAccordionTitleClick}
            />
        </Fragment>
    );

    if (isNested) {
        return content;
    } else {
        return <TabPane data-cy="next-periods-pane">{content}</TabPane>;
    }
};

export default UnitTestResultNextPeriodsPane;
