import {TFunction} from "i18next";

export const formatBigDecimalAsCurrency = (number: number, t: TFunction) => {
    if (number !== undefined && number !== null) {
        const formatter = new Intl.NumberFormat("fr", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatter.format(number);
    } else {
        return <i>{t("Not specified")}</i>;
    }
};

export const formatBigDecimalAsPercent = (number: number, t: TFunction) => {
    if (number !== undefined && number !== null) {
        const formatter = new Intl.NumberFormat("fr", {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
        return formatter.format(number);
    } else {
        return <i>{t("Not specified")}</i>;
    }
};
