import { Dispatch, SetStateAction } from "react";
import { Controller } from "react-hook-form";
import { Control, FieldErrors } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { Form, Grid } from "semantic-ui-react";
import { IndexValueDTO } from "../../../../Services/Company/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";

type FormIndexValueType = {
    control: Control<IndexValueDTO>;
    errors: FieldErrors<IndexValueDTO>;
    defaultValueValue: number | undefined;
    setDefaultValueValue: Dispatch<SetStateAction<number | undefined>>;
    defaultValueEffectiveDate: string;
    setDefaultValueEffectiveDate: Dispatch<SetStateAction<string>>
}

const FormIndexValue = ({
    control,
    errors,
    defaultValueValue,
    setDefaultValueValue,
    defaultValueEffectiveDate,
    setDefaultValueEffectiveDate,
}: FormIndexValueType) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form" noValidate={true}>
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueEffectiveDate}
                            rules={{required: true}}
                            name={"effectiveDate"}
                            render={({field: {value, name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type="date"
                                    name={name}
                                    label={t("Effective date")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueEffectiveDate(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, "effectiveDate")}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueValue}
                            rules={{required: true, min: 0}}
                            name={"value"}
                            render={({field: {value, name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type="number"
                                    min="0"
                                    name={name}
                                    label={t("Value")}
                                    step={".01"}
                                    placeholder={t("Value")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueValue(Number(value));
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, "value", 0)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
}

export default FormIndexValue;