import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Container, Dimmer, Loader, Message, Segment} from "semantic-ui-react";
import {useGetProductDetailsQuery} from "../../../../Services/Product/Queries";
import {useGetProductRetrocessionsQuery} from "../../../../Services/ProductRetrocession/Queries";
import {useGetSplittings} from "../../../../Services/Splitting/Queries";
import {useGetVersionsQuery} from "../../../../Services/Version/Queries";
import UnitTestForm from "./UnitTestForm";
import "./unit-test-form.css";

const UnitTestPage = () => {
    const {t} = useTranslation();

    const {id: productId} = useParams() as {id: string};

    const getProduct = useGetProductDetailsQuery(productId);
    const getSplittings = useGetSplittings(productId);
    const getVersions = useGetVersionsQuery(productId);
    const getProductRetrocessions = useGetProductRetrocessionsQuery(productId);

    const allQueries = [getProduct, getSplittings, getVersions, getProductRetrocessions];

    const isLoading: boolean = allQueries.some((q) => q.isLoading);
    const isError: boolean = allQueries.some((q) => q.isError);
    const isOk: boolean = allQueries.every((q) => q.isSuccess);

    return (
        <Container fluid className="unit-test-form">
            <Segment className="page-container">
                {isError && (
                    <Message negative>
                        <Message.Content>Une erreur est survenue au chargement initial du formulaire</Message.Content>
                    </Message>
                )}

                {isOk && (
                    <UnitTestForm
                        product={getProduct.data!}
                        splittings={getSplittings.data!}
                        retrocessions={getProductRetrocessions.data!}
                        versions={getVersions.data!}
                    />
                )}

                <Dimmer active={isLoading} page>
                    <Loader content={t("Loading") + "..."} />
                </Dimmer>
            </Segment>
        </Container>
    );
};

export default UnitTestPage;
