import {TFunction} from "i18next";
import {VariableCDTO, VariableCriteriaCDTO} from "../PricingCriterias/Types";
import {VariableCondition, VariableCriteria} from "../Variables/Types";

export type BasicGridType = {
    id: number;
    label: string;
    code: string;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export enum CellTypeEnum {
    DIM = "DIM",
    VAL = "VAL",
    DVAL = "DVAL",
    VIDE = "VIDE",
}

export type PricingGridCellType = {
    type: CellTypeEnum;
    colspan: number;
    id: number | null;
    value: string | number;
};

export type PricingGridLineType = {
    cells: Array<PricingGridCellType>;
};

export type PricingGridViewType = {
    code: string;
    label: string | null;
    lines: Array<PricingGridLineType>;
};

export type DimensionGridDTO = {
    id?: number;
    code: string;
    label: string;
    dimensionType: DimensionType;
    dimensionValueType: DimensionValueType;
    dimensionOrderType: DimensionOrderType;
    visualizationGridDTO: VisualizationGridDTO;
    dimensionGridValueDTOs: DimensionGridValueDTO[];
};

export type DimensionGridDTOWithId = {
    id: number;
    code: string;
    label: string;
    dimensionType: DimensionType;
    dimensionValueType: DimensionValueType;
    dimensionOrderType: DimensionOrderType;
    visualizationGridDTO: VisualizationGridDTO;
    dimensionGridValueDTOs: DimensionGridValueDTOWithId[];
};

export enum DimensionType {
    F = "F",
    T = "T",
}

export enum DimensionOrderType {
    VALEUR = "VALEUR",
    ORDRE = "ORDRE",
}

export enum DimensionValueType {
    INT = "INT",
    STRING = "STRING",
    DOUBLE = "DOUBLE",
    DATE = "DATE",
}

export type VisualizationGridDTO = {
    axe: Axe;
    rank: number;
};

export enum Axe {
    L = "L",
    C = "C",
}

export type DimensionGridValueDTO = {
    id?: number;
    value: string;
    min: number;
    max: number;
    minDate: string;
    maxDate: string;
    label: string;
    order: number;
};

export type DimensionGridValueDTOWithId = {
    id: number;
    value: string;
    min: number;
    max: number;
    minDate: string;
    maxDate: string;
    label: string;
    order: number;
};

export type PricingGridAddType = {
    code: string;
    label: string;
    dimensionGridDTOs: [];
    gridValueDTOs: [];
};

export type PricingGridFormType = {
    code: string | undefined;
    label: string | undefined;
    dimensionType: DimensionType | undefined;
    dimensionValueType: DimensionValueType | undefined;
    dimensionOrderType: DimensionOrderType | undefined;
    dimensionAxe: Axe | undefined;
};

// ----- Recherche de grille -----
export enum PricingGridSearchValueType {
    FIXED_VALUE = "FIXED_VALUE",
    VARIABLE = "VARIABLE",
    QUESTION = "QUESTION",
    PRICING_CRITERIA = "PRICING_CRITERIA",
    SOFTWARE_CRITERIA = "SOFTWARE_CRITERIA",
}

export type PricingGridSearchType = {
    code: string;
    dimensions: Array<{
        code: string;
        valueType: PricingGridSearchValueType;
        value: string;
    }>;
    defaultValue: string | null;
};

export const convertVariableCToPricingGridSearch = (variableCDTO: VariableCDTO): PricingGridSearchType => {
    const pricingGridSearch: PricingGridSearchType = {
        code: variableCDTO.refGridCode ?? "",
        dimensions: variableCDTO.variableCriteriasCDTO.map((dimension) => ({
            code: dimension.refDimensionCode,
            valueType: determinePricingGridSearchValueTypeFromVariableC(dimension),
            value: dimension.value ?? dimension.questionName ?? dimension.pricingCriteriaName ?? "",
        })),
        defaultValue: null,
    };

    return pricingGridSearch;
};

export const convertVariableConditionToGridSearch = (
    variableCondition: VariableCondition,
    t: TFunction<"translation", undefined>
): PricingGridSearchType => {
    const pricingGridSearch: PricingGridSearchType = {
        code: variableCondition?.refGridCode ?? "",
        dimensions: variableCondition.variableCriterias.map((dimension) => ({
            code: dimension.refDimensionCode ?? "",
            valueType: determinePricingGridSearchValueTypeFromVariableCriteria(dimension),
            value: determinePricingGridSearchDisplayValueTypeFromVariableCriteria(dimension, t),
        })),
        defaultValue: variableCondition.defaultValue ?? null,
    };

    return pricingGridSearch;
};

export const determinePricingGridSearchValueTypeFromVariableC = (
    variableCriteria: VariableCriteriaCDTO
): PricingGridSearchValueType => {
    if (variableCriteria.questionName) return PricingGridSearchValueType.QUESTION;
    else if (variableCriteria.pricingCriteriaName !== null) return PricingGridSearchValueType.PRICING_CRITERIA;
    else return PricingGridSearchValueType.FIXED_VALUE;
};

export const determinePricingGridSearchValueTypeFromVariableCriteria = (
    variableCriteria: VariableCriteria
): PricingGridSearchValueType => {
    if (variableCriteria.value) return PricingGridSearchValueType.FIXED_VALUE;
    else if (variableCriteria.systemVariable) return PricingGridSearchValueType.SOFTWARE_CRITERIA;
    else if (variableCriteria.pricingCriteriaName) return PricingGridSearchValueType.PRICING_CRITERIA;
    else if (variableCriteria.variableName) return PricingGridSearchValueType.VARIABLE;
    else return PricingGridSearchValueType.FIXED_VALUE;
};

export const determinePricingGridSearchDisplayValueTypeFromVariableCriteria = (
    variableCriteria: VariableCriteria,
    t: TFunction<"translation", undefined>
): string => {
    if (variableCriteria.value) {
        if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(variableCriteria.value)) {
            return t("format_date", {value: variableCriteria.value})
        }
        return variableCriteria.value;
    } else if (variableCriteria.systemVariable)
        return t("enums.SystemVariable." + variableCriteria.systemVariable).includes("enums")
            ? variableCriteria.systemVariable
            : t("enums.SystemVariable." + variableCriteria.systemVariable);
    else if (variableCriteria.pricingCriteriaName) return variableCriteria.pricingCriteriaName;
    else if (variableCriteria.variableName) return variableCriteria.variableName;
    else return "";
};
