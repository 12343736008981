import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {CustomError} from "../RequestUtils";
import {postPricing} from "./AxiosRequests";
import {PricingInflow, PricingOutflow} from "./Types";

export type PremiumsMutationType = {
    pricingInflow: PricingInflow;
    policyEffectiveDate: string | null;
    policyInsurancePackageCode: string | null;
};

export const usePremiumsMutation = (
    productId: number,
    productCode: string
): UseMutationResult<PricingOutflow, CustomError, PremiumsMutationType, Error> => {
    const navigate = useNavigate();

    return useMutation<PricingOutflow, CustomError, PremiumsMutationType, Error>({
        mutationFn: (data: PremiumsMutationType) => postPricing(productCode, data),
        onSuccess: (data) => {
            navigate(`/products/${productId}/unit_test/result`, {state: data});
        },
    });
};
