export type PricingInflowForm = {
    effectiveDate: string;
    insurancePackageCode: string | null;
    splittingCode: string;
    movementType: string;
    movementCode: string;
    discountPercentage?: number;

    mainDeadlineDerogation?: boolean;
    deadLineDay?: number | null;
    deadLineMonth?: number | null;

    debitDay?: number | null;
    businessProviders: Record<string, number | null>;

    deactivateSchedule?: boolean;
    lightPricing?: boolean;
    contributionExemption?: number;
    promotionalCampaignCode?: string;

    riskInformation: Record<string, string>;
    includeOptions: Record<string, Record<string, boolean>>;

    policyEffectiveDate?: string | null;
    policyInsurancePackageCode?: string | null;
};

export type PricingInflow = {
    effectiveDate: string;
    insurancePackageCode: string | null;
    splittingCode: string;
    movementType: string;
    movementCode: string;
    discountPercentage?: number;
    deadLineDay?: number | null;
    deadLineMonth?: number | null;
    debitDay?: number | null;
    businessProvider1: string | null;
    businessProvider2: string | null;
    businessProvider3: string | null;
    businessProvider4: string | null;
    businessProvider5: string | null;
    deactivateSchedule?: boolean;
    lightPricing?: boolean;
    contributionExemption?: number;
    promotionalCampaignCode?: string;

    riskInformation: Array<RiskInformation>;
    includeOptions: Array<IncludeOption>;
};

export type RiskInformation = {
    code: string;
    value: string | null;
    values?: Array<RiskInformation> | null;
};

export type IncludeOption = {
    insurancePackageCode: string;
    optionsCode: Array<string>;
};

export type PricingOutflow = {
    productCode: string;
    versionCode: string;
    splittingCode: string;
    effectiveDate: string;
    insurancePackages: Array<InsurancePackageResult>;
    deadLine: string;
    periodEndDate: string;
    movementId: number | null;
    tradeDiscount: number | null;
    statusCode: string | null;
    productInformation: Array<RiskInformation>;
    riskInformation: Array<RiskInformation>;
    expiryDate: string;
    calculationSheet: string;
    companyCode: string;
    productRiskType: string;
    productRange: string | null;
    promotionalCampaignCode: string | null;
    indexes: Array<RiskInformation>;
};

export type InsurancePackageResult = {
    id: number;
    code: string;
    label: string;
    coverages: Array<CoverageResult>;
    options: Array<OptionResult>;
    feesCharges: Array<FeeChargeResult>;
    deductiblesCapitals: Array<DeductibleCapitalResult>;
    annualPremiumInclVat: number;
    netAnnualPremium: number;
    splittingPremiumInclVat: number;
    netSplittingPremium: number;
    proratePremiumInclVat: number;
    netProratePremium: number;
    taxAnnual: number;
    taxProrate: number;
    taxSplitting: number;
    selected: boolean | null;
    terrorismTax: number;
    contributionCommissionAmount: number;
    managementCommissionAmount: number;
    retrocessionAmount1: number;
    baseRetrocessionAmount1: number;
    retrocessionAmount2: number;
    baseRetrocessionAmount2: number;
    retrocessionAmount3: number;
    baseRetrocessionAmount3: number;
    retrocessionAmount4: number;
    baseRetrocessionAmount4: number;
    retrocessionAmount5: number;
    baseRetrocessionAmount5: number;
    nextPeriods: Array<NextPeriod>;
    paymentSchedule: Array<PaymentSchedule>;
};

type PricedResult = {
    code: string;
    label: string;
    companyCode: string | null;
    taxRate: number;
    taxCode: string;
    annualPremiumInclVat: number;
    netAnnualPremium: number;
    splittingPremiumInclVat: number;
    netSplittingPremium: number;
    proratePremiumInclVat: number;
    netProratePremium: number;
    taxAnnual: number;
    taxProrate: number;
    taxSplitting: number;
    nextPeriods: Array<NextPeriod>;
    emitOnDeadline: boolean;
    refundMode: RefundMode;
    active: boolean;
    periodStartDate: string;
    periodEndDate: string;
    rate: number;
};

export type CoverageResult = PricedResult & {
    coverageGroupCode: string;
    contributionCommissionRate: number;
    contributionCommissionAmount: number;
    managementCommissionRate: number;
    managementCommissionAmount: number;
    retrocessionRate1: number;
    retrocessionAmount1: number;
    baseRetrocessionAmount1: number;
    retrocessionRate2: number;
    retrocessionAmount2: number;
    baseRetrocessionAmount2: number;
    retrocessionRate3: number;
    retrocessionAmount3: number;
    baseRetrocessionAmount3: number;
    retrocessionRate4: number;
    retrocessionAmount4: number;
    baseRetrocessionAmount4: number;
    retrocessionRate5: number;
    retrocessionAmount5: number;
    baseRetrocessionAmount5: number;
    contributionExemptionRate: number;
    false: boolean;
    optionCode: string;
    optionLabel: string;
};

export type FeeChargeResult = PricedResult & {
    feeChargeType: FeeChargeType;
    feeType: FeeType;
};

export type OptionResult = {
    code: string;
    label: string;
    companyCode: string;
    taxRate: number;
    annualPremiumInclVat: number;
    netAnnualPremium: number;
    splittingPremiumInclVat: number;
    netSplittingPremium: number;
    taxAnnual: number;
    taxSplitting: number;
    emitOnDeadline: boolean;
};

export type DeductibleCapitalResult = {
    deductibleCapitalLabel: string;
    deductibleCapitalType: DeductibleCapitalType;
    deductibleCapitalContent: string;
    coverageGroupLabel: string;
    coverageCode: string;
};

export type NextPeriod = {
    effectiveDate: string;
    periodEndDate: string;
    proratePremiumInclVat: number;
    netProratePremium: number;
    taxProrate: number;
    contributionCommissionAmount: number;
    managementCommissionAmount: number;
    retrocessionAmount1: number;
    baseRetrocessionAmount1: number;
    retrocessionAmount2: number;
    baseRetrocessionAmount2: number;
    retrocessionAmount3: number;
    baseRetrocessionAmount3: number;
    retrocessionAmount4: number;
    baseRetrocessionAmount4: number;
    retrocessionAmount5: number;
    baseRetrocessionAmount5: number;
};

export type PaymentSchedule = {
    debitDay: string;
    amountInclVat: number;
    effectiveDate: string;
    periodEndDate: string;
};

export enum MovementType {
    AN = "AN",
    TE = "TE",
    AVCT = "AVCT",
    AVAG = "AVAG",
    AVCE = "AVCE",
    ANEF = "ANEF",
    RVCT = "RVCT",
    AVRG = "AVRG",
    AVPO = "AVPO",
    AVRN = "AVRN",
    AVEXO = "AVEXO",
    REP = "REP",
}

enum RefundMode {
    O = "O",
    N = "N",
    J = "J",
}

enum FeeType {
    FC = "FC",
    FR = "FR",
    RC = "RC",
    HO = "HO",
}

enum FeeChargeType {
    FEE = "FEE",
    CHARGE = "CHARGE",
}

enum DeductibleCapitalType {
    F = "F",
    C = "C",
}
