import { useTranslation } from "react-i18next";
import { Grid, Header, Menu, Segment } from "semantic-ui-react";
import SimpleList from "../../../Components/SimpleList/SimpleList";
import { DataTypeColumnEnum } from "../../../Components/SimpleList/SimpleListTypes";
import { useAddIndexValueMutation } from "../../../Services/Company/Queries";
import { Index, IndexValueDTO } from "../../../Services/Company/Types";
import {useForm} from "react-hook-form";
import { useState } from "react";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import FormIndexValue from "./Fragments/FormIndexValue";

type IndexValueListType = {
    activeItem: Index;
}

const indexTableColumns = [
    {
        text: "Effective date",
        dataField: "effectiveDate",
        type: DataTypeColumnEnum.DATE,
    },
    {
        text: "Value",
        dataField: "value",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "",
        dataField: "/transversal_settings/indexes/:indexId/values/:id",
        type: DataTypeColumnEnum.LINK,
    },
]

const IndexValueList = ({
    activeItem,
}: IndexValueListType) => {
    const {t} = useTranslation();

    const addIndexValueMutation = useAddIndexValueMutation(activeItem.id);
    const addIndexValueFrom = useForm<IndexValueDTO>();
    const submitAddIndexValueForm = (form: IndexValueDTO) => {
        addIndexValueMutation.mutate({
            ...form,
        });
    }
    const [defaultValueEffectiveDate, setDefaultValueEffectiveDate] = useState<string>("");
    const [defaultValueValue, setDefaultValueValue] = useState<number>();

    return (
        <Grid.Column width={12}>
            <Segment data-cy="index-values-segment">
                <Menu borderless style={{border: "0", boxShadow: "none"}}>
                    <Menu.Item>
                        <Header size="large">
                            {t("Index values")}
                        </Header>
                    </Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item name="addIndexValue">
                            <ModalAdd
                                isValidationDisabled={Object.keys(addIndexValueFrom.formState.errors).length !== 0}
                                isPending={addIndexValueMutation.isPending}
                                isSuccess={addIndexValueMutation.isSuccess}
                                isError={addIndexValueMutation.isError}
                                error={addIndexValueMutation.error}
                                resetMutation={addIndexValueMutation.reset}
                                objectToAdd="Index value"
                                objectToAddContext="female"
                                onValidate={addIndexValueFrom.handleSubmit(submitAddIndexValueForm)}
                                onClose={() => addIndexValueFrom.reset()}
                                onCancel={() => addIndexValueFrom.reset()}
                                onSuccess={() => addIndexValueFrom.reset()}
                                iconOnOpenButton
                                openButtonIcon="plus"
                                renderModalContent={() => (
                                    <FormIndexValue
                                        control={addIndexValueFrom.control}
                                        errors={addIndexValueFrom.formState.errors}
                                        defaultValueValue={defaultValueValue}
                                        setDefaultValueValue={setDefaultValueValue}
                                        defaultValueEffectiveDate={defaultValueEffectiveDate}
                                        setDefaultValueEffectiveDate={setDefaultValueEffectiveDate}
                                    />
                                )}
                            />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

                <SimpleList
                    columns={indexTableColumns}
                    rows={activeItem.indexValues}
                    globalData={[{key: "indexId", value: activeItem.id}]}
                />
            </Segment>
        </Grid.Column>
    );
}

export default IndexValueList;