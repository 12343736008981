import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionPanelProps,
    AccordionTitleProps,
    SemanticShorthandCollection,
    TabPane,
} from "semantic-ui-react";
import {OptionResult} from "../../../../Services/Pricing/Types";
import {formatBigDecimalAsCurrency, formatBigDecimalAsPercent} from "../../../../Utils/NumberUtils";
import {handleNullableProperty} from "../../../../Utils/TextUtils";
import UnitTestResultExpandShrink from "./UnitTestResultExpandShrink";

type UnitTestResultOptionsPaneProps = {
    options: OptionResult[];
};

const UnitTestResultOptionsPane = ({options}: UnitTestResultOptionsPaneProps) => {
    const {t} = useTranslation();
    const [activesIndexs, setActivesIndexs] = useState<number[] | undefined>();

    const handleAccordionTitleClick = (_: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const {index, active} = data;
        const newState = activesIndexs?.filter((i) => i !== index) ?? [];
        if (!active) newState.push(index as number);
        setActivesIndexs(newState);
    };

    const panels: SemanticShorthandCollection<AccordionPanelProps> = options.map((option, i) => {
        return {
            key: option.code,
            title: `${option.label} - ${option.code}`,
            content: (
                <Accordion.Content active={activesIndexs?.includes(i)}>
                    <div className="amounts-pane">
                        <div data-cy="options-informations">
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Company code")}
                                </div>
                                <div className="col bold">{handleNullableProperty(option.companyCode, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Tax rate")}
                                </div>
                                <div className="col bold">{formatBigDecimalAsPercent(option.taxRate / 100, t)}</div>
                            </div>
                        </div>

                        <div data-cy="amounts">
                            <b>{t("Amounts")}</b>
                            <div className="row gap-1">
                                <div className="col">{t("Annual")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(option.annualPremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(option.netAnnualPremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">{formatBigDecimalAsCurrency(option.taxAnnual, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col">{t("Splitted")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(option.splittingPremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(option.netSplittingPremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(option.taxSplitting, t)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Accordion.Content>
            ),
        };
    });

    return (
        <TabPane data-cy="options-pane">
            <UnitTestResultExpandShrink maxIndex={panels.length} setActivesIndexes={setActivesIndexs} />

            <Accordion
                styled
                exclusive={false}
                fluid
                panels={panels}
                activeIndex={activesIndexs}
                onTitleClick={handleAccordionTitleClick}
            />
        </TabPane>
    );
};

export default UnitTestResultOptionsPane;
