import { ConditionPart } from "../Condition/Types";
import { QuestionValueTypeEnum } from "../Question/Types";

export type PricingCriterias = {
    id: number;
    name: string;
    type: PricingCriteriaItemType;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
    description: string;
    valid: boolean;
    saveQuote: boolean;
};

export type PricingCriteriaItem = {
    id: number;
    name: string;
    type: PricingCriteriaItemType;
    saveQuote: boolean;
    versionId: number;
    pricingCriteriaConditions: PricingCriteriaConditionDTO[];
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
    description: string;
    valid: boolean;
};

export type PricingCriteriaConditionDTO = {
    id: number;
    ifCondition: string | null;
    conditionPartList: ConditionPart[];
    conditionOrder: number;
    fixedValue: string;
    variableCDTO: VariableCDTO;
    questionName: string;
    questionType: QuestionValueTypeEnum;
    pricingCriteriaId: number;
    processType: ProcessType;
    processParams: string[];
    valid: boolean;
};

export type UpdateAssignmentPricingCriteriaConditionDTO = {
    id: number;
    ifCondition: string | null;
    conditionPartList: ConditionPart[];
    conditionOrder: number;
    fixedValue: string | null;
    variableCDTO: VariableCDTO | null;
    questionName: string | null;
    pricingCriteriaId: number;
    processType: ProcessType | null;
    processParams: string[] | null;
};

export type VariableCDTO = {
    id: number;
    name: string;
    valueType: VariableCritereType;
    refGridCode: string;
    variableCriteriasCDTO: VariableCriteriaCDTO[];
};

export enum VariableCritereType {
    STRING = "STRING",
    NUMERIC = "NUMERIC",
    DATE = "DATE",
}

export type VariableCriteriaCDTO = {
    refDimensionCode: string;
    value: string | null;
    pricingCriteriaName: string | null;
    questionName: string | null;
};

export type NewPricingCriteriaDTO = {
    name: string;
    type: PricingCriteriaItemType;
    saveQuote: boolean;
    pricingCriteriaConditions: NewPricingCriteriaConditionDTO[];
};

export type NewPricingCriteriaConditionDTO = {
    ifCondition: string | null;
    conditionPartList?: ConditionPart[];
    conditionOrder?: number;
    fixedValue: string;
    variableCDTO?: VariableCDTO | undefined;
    questionName?: string;
    processType?: ProcessType;
    processParams?: string[];
};

export enum PricingCriteriaItemType {
    STRING = "STRING",
    DATE = "DATE",
    NUMERIC = "NUMERIC",
}

export enum ProcessType {
    NONE = "NONE",
    SLICE = "SLICE",
    CALCUL_AGE = "CALCUL_AGE",
}
