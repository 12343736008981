import {UseMutationResult} from "@tanstack/react-query";
import {useEffect, useReducer, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Grid, GridColumn, GridRow, Message} from "semantic-ui-react";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import {useCheckSyntax} from "../../../../../Services/ComputingRule/Queries";
import {CustomError} from "../../../../../Services/RequestUtils";
import {Variable, VariableCondition, VariableCriteria} from "../../../../../Services/Variables/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {GenericReducerActionType} from "../../../../../Utils/ReducerUtils";
import variableConditionReducer, {AssignmentType} from "./VariableConditionAssignementReducer";
import VariableConditionAssignementUpdate from "./VariableConditionAssignementUpdate";
import VariableConditionAssignementView from "./VariableConditionAssignementView";

type VariableConditionAssignementPropsType = {
    version: VersionDTO;
    variable: Variable;
    variableCondition: VariableCondition;
    updateVariableConditionMutation: UseMutationResult<VariableCondition, CustomError, VariableCondition, Error>;
    activeIndex: number;
    index: number;
};

const VariableConditionAssignement = ({
    variable,
    variableCondition,
    version,
    updateVariableConditionMutation,
    activeIndex,
    index,
}: VariableConditionAssignementPropsType) => {
    const {t} = useTranslation();

    const [editAssignment, setEditAssignment] = useState(false);
    const [assignmentType, setAssignmentType] = useState<AssignmentType>(AssignmentType.FIXED_VALUE);
    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
    const [isComputingRuleChange, setIsComputingRuleChange] = useState<boolean>();

    const [conditionState, dispatchCondition] = useReducer(variableConditionReducer, variableCondition);
    const {
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        trigger,
    } = useForm({mode: "onBlur"});

    const checkSyntax = useCheckSyntax();

    const updateVariableCondition = () => {
        conditionState.variableCriterias.map((variableCriteria: VariableCriteria) => {
            variableCriteria.id = null;
            if (variableCriteria.computingRule !== null && variableCriteria.computingRule !== undefined) {
                variableCriteria.computingRule.id = null;
                variableCriteria.computingRule.computingRuleConditions[0].id = null;
            }
            if(variableCriteria.value !== null && variableCriteria.value !== undefined){
                variableCriteria.value = variableCriteria.value.trim();
            }
            return variableCriteria;
        });

        updateVariableConditionMutation.mutate(conditionState);
    };

    const determineAssignmentType = () => {
        if (conditionState.refGridCode !== null) {
            return AssignmentType.GRID_SEARCH;
        } else if (conditionState.variableCriterias.length === 1) {
            if (conditionState.variableCriterias[0].value !== null) {
                return AssignmentType.FIXED_VALUE;
            } else if (conditionState.variableCriterias[0].systemVariable !== null) {
                return AssignmentType.SYSTEM_VARIABLE;
            } else if (conditionState.variableCriterias[0].pricingCriteriaName !== null) {
                return AssignmentType.PRICING_CRITERIA;
            } else if (conditionState.variableCriterias[0].variableName !== null) {
                return AssignmentType.VARIABLE;
            } else if (conditionState.variableCriterias[0].computingRule !== null) {
                return AssignmentType.VARIABLE_COMPUTING_RULE;
            }
        }
        return AssignmentType.NOTHING;
    };

    const closeAssignmentEdition = () => {
        setAssignmentType(determineAssignmentType());
        dispatchCondition({
            type: GenericReducerActionType.UNDO,
            assignmentType: AssignmentType.NOTHING,
            payload: variableCondition,
        });
        setEditAssignment(false);
    };

    const verify = () => {
        setIsComputingRuleChange(false);
        checkSyntax.mutate(conditionState.variableCriterias[0].computingRule.computingRuleConditions[0].rulePartList);
    };

    useEffect(() => {
        if (checkSyntax.isError && !isComputingRuleChange) setIsErrorVisible(true);
    }, [checkSyntax, isComputingRuleChange]);

    useEffect(() => {
        setAssignmentType(determineAssignmentType());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variableCondition?.variableCriterias, conditionState]);

    useEffect(() => {
        if (activeIndex !== index) {
            setEditAssignment(false);
        }
    }, [activeIndex, index]);

    useEffect(() => {
        setEditAssignment(false);
        dispatchCondition({
            type: GenericReducerActionType.UNDO,
            assignmentType: AssignmentType.NOTHING,
            payload: variableCondition,
        });
    }, [variableCondition, variable.id]);

    return editAssignment ? (
        <Grid>
            <GridRow>
                <GridColumn width={1} textAlign="center" verticalAlign="middle" style={{whiteSpace: "nowrap"}}>
                    {t("Then")} :
                </GridColumn>
                <GridColumn width={15} textAlign="left">
                    <VariableConditionAssignementUpdate
                        variable={variable}
                        assignmentType={assignmentType}
                        conditionState={conditionState}
                        originalCondition={variableCondition}
                        dispatchCondition={dispatchCondition}
                        checkSyntax={checkSyntax}
                        setIsErrorVisible={setIsErrorVisible}
                        setIsComputingRuleChange={setIsComputingRuleChange}
                        isComputingRuleChange={isComputingRuleChange}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        trigger={trigger}
                    />
                </GridColumn>
            </GridRow>

            <GridRow>
                {assignmentType === AssignmentType.VARIABLE_COMPUTING_RULE && (
                    <>
                        <GridColumn width={12} textAlign={"left"}>
                            <Button name="verify" primary onClick={() => verify()}>
                                {t("Verify")}
                            </Button>
                            {checkSyntax.error && isErrorVisible && (
                                <Message compact negative>
                                    <p>{t("request_errors." + checkSyntax.error.message)}</p>
                                </Message>
                            )}
                        </GridColumn>
                    </>
                )}
                <GridColumn
                    width={assignmentType === AssignmentType.VARIABLE_COMPUTING_RULE ? 4 : 16}
                    textAlign={"right"}>
                    <Button name="cancel" secondary onClick={() => closeAssignmentEdition()}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        isPending={updateVariableConditionMutation.isPending}
                        isSuccess={updateVariableConditionMutation.isSuccess}
                        isError={updateVariableConditionMutation.isError}
                        resetMutation={updateVariableConditionMutation.reset}
                        error={updateVariableConditionMutation.error}
                        onSuccess={() => setEditAssignment(false)}
                        onValidate={handleSubmit(updateVariableCondition)}
                        objectToUpdate={t("Assignment")}
                        objectType="nonbinary"
                    />
                </GridColumn>
            </GridRow>
        </Grid>
    ) : (
        <Grid>
            <GridRow>
                <GridColumn width={1} textAlign="center" verticalAlign="middle" style={{whiteSpace: "nowrap"}}>
                    {t("Then")} :
                </GridColumn>

                <GridColumn width={14} textAlign="left" verticalAlign="middle">
                    <VariableConditionAssignementView
                        versionId={String(version?.id) ?? ""}
                        assignmentType={assignmentType}
                        variable={variable}
                        variableCondition={variableCondition}
                    />
                </GridColumn>

                {version !== undefined && version.pipDate === null && (
                    <GridColumn floated="right" textAlign="center" verticalAlign="middle">
                        <Button
                            className="editAssignement"
                            color="grey"
                            icon="edit"
                            compact
                            basic
                            onClick={async () => {
                                setEditAssignment(true);
                            }}
                        />
                    </GridColumn>
                )}
            </GridRow>
        </Grid>
    );
};

export default VariableConditionAssignement;
