import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionPanelProps,
    AccordionTitleProps,
    SemanticShorthandCollection,
    TabPane,
} from "semantic-ui-react";
import {PaymentSchedule} from "../../../../Services/Pricing/Types";
import {formatBigDecimalAsCurrency} from "../../../../Utils/NumberUtils";
import UnitTestResultExpandShrink from "./UnitTestResultExpandShrink";

type UnitTestResultPaymentSchedulePaneProps = {
    paymentSchedule: PaymentSchedule[];
};

const UnitTestResultPaymentSchedulePane = ({paymentSchedule}: UnitTestResultPaymentSchedulePaneProps) => {
    const {t} = useTranslation();
    const [activesIndexs, setActivesIndexs] = useState<number[] | undefined>();

    const handleAccordionTitleClick = (_: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const {index, active} = data;
        const newState = activesIndexs?.filter((i) => i !== index) ?? [];
        if (!active) newState.push(index as number);
        setActivesIndexs(newState);
    };

    const numberOfPayments: number = paymentSchedule.length ?? 0;
    const panels: SemanticShorthandCollection<AccordionPanelProps> = paymentSchedule.map((payment, i) => {
        return {
            key: payment.effectiveDate,
            title: t("Deadline x over x - Amount withdrawn x", {
                index: i + 1,
                over: numberOfPayments,
                amount: formatBigDecimalAsCurrency(payment.amountInclVat, t),
            }),
            content: (
                <Accordion.Content active={activesIndexs?.includes(i)}>
                    <b>Échéancier de paiement</b>
                    <div className="row gap-1">
                        <div className="col">
                            <span>Date de prélévement</span>
                            <span>Période couverte</span>
                            <span>Montant à prélever</span>
                        </div>
                        <div className="col bold">
                            <span>{t("format_date", {value: payment.debitDay})}</span>
                            <span>
                                {t("format_date", {value: payment.effectiveDate})} au{" "}
                                {t("format_date", {value: payment.periodEndDate})}
                            </span>
                            <span>{formatBigDecimalAsCurrency(payment.amountInclVat, t)}</span>
                        </div>
                    </div>
                </Accordion.Content>
            ),
        };
    });

    return (
        <TabPane data-cy="payment-schedule-pane">
            <UnitTestResultExpandShrink maxIndex={panels.length} setActivesIndexes={setActivesIndexs} />

            <Accordion
                styled
                exclusive={false}
                fluid
                panels={panels}
                activeIndex={activesIndexs}
                onTitleClick={handleAccordionTitleClick}
            />
        </TabPane>
    );
};

export default UnitTestResultPaymentSchedulePane;
