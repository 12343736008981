import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Container, Grid, Header, Form, Icon, Menu, Segment } from "semantic-ui-react";
import { AuthContext } from "../../../Components/AuthProvider/AuthProvider";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import useTransverseFeatureSidebarMenus from "../../../Hooks/useTransverseFeatureSidebarMenus";
import { useAddIndexMutation, useDeleteIndexMutation, useGetIndexesQuery, useUpdateIndexMutation } from "../../../Services/Company/Queries";
import { Index } from "../../../Services/Company/Types";
import getErrorMessage from "../../Global/Form/ErrorMessage";
import FormIndex from "./Fragments/FormIndex";
import IndexValueList from "./IndexValueList";

const IndexPage = () => {
    const {t} = useTranslation();

    const indexes = useGetIndexesQuery();

    //add
    const addIndexMutaion = useAddIndexMutation();
    const addIndexForm = useForm<{code: string}>();
    const submitAddIndexForm = (form: {code: string}) => {
        addIndexMutaion.mutate({
            code: form.code,
        });
    }

    //update
    const updateIndexMutation = useUpdateIndexMutation();
    const updateIndexForm = useForm<{code: string}>({mode: "onChange"});
    const submitUpdateIndexForm = (form: {code: string}) => {
        if (activeItem) {
            updateIndexMutation.mutate({
                id: activeItem.id,
                indexNewCode: form.code,
            });
        }
    }

    //delete
    const deleteIndexMutation = useDeleteIndexMutation();

    const [defaultValueCode, setDefaultValueCode] = useState<string>("");

    const [activeItem, setActiveItem] = useState<Index | undefined>();
    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        setEditMode(false);
        if (indexes.isSuccess && indexes.data) {
            if (activeItem != null) {
                setActiveItem(indexes.data.find((index) => index.id === activeItem.id));
            } else {
                setActiveItem(indexes.data[0]);
            }
        }
        updateIndexForm.reset();
    }, [indexes.isSuccess, indexes.data, activeItem]);

    const {user: loggedUser} = React.useContext(AuthContext);

    const menus = useTransverseFeatureSidebarMenus(
        loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN" || authority === "ADMINISTRATEUR") ||
            false
    );

    return (
        <Container fluid>
            <Sidebar title={t("transversal_settings")} menus={menus} />
            <div className="main-container">
                <Segment>
                    <Grid style={{margin: "1px"}}>
                        <Grid.Column width={4}>
                            <Menu fluid vertical pointing name="indexMenu">
                                <Menu.Item>
                                    <Header size="medium">{t("Indexes")}</Header>
                                </Menu.Item>
                                {indexes.isSuccess && indexes.data.map((index) => {
                                    if (editMode && activeItem?.id === index.id) {
                                        return (
                                            <Menu.Item
                                                name={`index-${index.id}`}
                                                key={`index-${index.id}`}
                                                active={activeItem?.code === index.code}
                                                onClick={() => setActiveItem(index)}>
                                                <Grid>
                                                    <Grid.Column width={10} verticalAlign="middle">
                                                        <Form>
                                                            <Controller
                                                                control={updateIndexForm.control}
                                                                defaultValue={activeItem.code}
                                                                rules={{required: true, maxLength: 10}}
                                                                name={"code"}
                                                                render={({field: {name, value, onChange, onBlur}}) => (
                                                                    <Form.Input
                                                                        fluid
                                                                        required
                                                                        type={"text"}
                                                                        name={name}
                                                                        maxLength="10"
                                                                        placeholder={t("code")}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        error={getErrorMessage(
                                                                            t,
                                                                            updateIndexForm.formState.errors,
                                                                            name,
                                                                            undefined,
                                                                            10
                                                                        )}
                                                                    />
                                                                )}
                                                            />
                                                        </Form>
                                                    </Grid.Column>
                                                    <Grid.Column width={6} verticalAlign="middle">
                                                        <Button.Group floated="right">
                                                            <Button
                                                                icon="cancel"
                                                                color="black"
                                                                name="cancel"
                                                                onClick={() => {
                                                                    setEditMode(false);
                                                                    updateIndexForm.reset();
                                                                }}>
                                                            </Button>
                                                            <ModalUpdate
                                                                isPending={updateIndexMutation.isPending}
                                                                isSuccess={updateIndexMutation.isSuccess}
                                                                isError={updateIndexMutation.isError}
                                                                error={updateIndexMutation.error}
                                                                resetMutation={updateIndexMutation.reset}
                                                                onValidate={updateIndexForm.handleSubmit(
                                                                    submitUpdateIndexForm
                                                                )}
                                                                objectToUpdate="index"
                                                                objectType="nonbinary"
                                                                iconOnOpenButton={true}
                                                                openButtonIcon="check"
                                                                isModalDisabled={!updateIndexForm.formState.isValid}
                                                            />
                                                        </Button.Group>
                                                    </Grid.Column>
                                                </Grid>
                                            </Menu.Item>
                                        );
                                    } else {
                                        return (
                                            <Menu.Item
                                                name={`index-${index.id}`}
                                                key={`index-${index.id}`}
                                                active={activeItem?.code === index.code}
                                                onClick={() => setActiveItem(index)}>
                                                <Grid>
                                                    <Grid.Column width={10} verticalAlign="middle">
                                                        <Header as="h5" style={{wordBreak: "break-word"}}>
                                                            {index.code}
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={6} verticalAlign="middle">
                                                        <Button.Group basic floated="right">
                                                            <Button
                                                                name="edit"
                                                                basic
                                                                icon
                                                                onClick={() => setEditMode(true)}>
                                                                <Icon name={"edit"} color="black" />
                                                            </Button>
                                                            <ModalDelete
                                                                isPending={deleteIndexMutation.isPending}
                                                                isSuccess={deleteIndexMutation.isSuccess}
                                                                isError={deleteIndexMutation.isError}
                                                                error={deleteIndexMutation.error}
                                                                resetMutation={deleteIndexMutation.reset}
                                                                onValidate={() =>
                                                                    deleteIndexMutation.mutate(String(index.id))
                                                                }
                                                                objectToDelete="index"
                                                                objectType="nonbinary"
                                                                renderOpenButton={() => {
                                                                    return (
                                                                        <Button basic icon name="delete">
                                                                            <Icon
                                                                                name={"trash alternate"}
                                                                                color="red"
                                                                            />
                                                                        </Button>
                                                                    );
                                                                }}
                                                            />
                                                        </Button.Group>
                                                    </Grid.Column>
                                                </Grid>
                                            </Menu.Item>
                                        );
                                    }
                                })}
                                <ModalAdd
                                    isValidationDisabled={Object.keys(addIndexForm.formState.errors).length !== 0}
                                    isPending={addIndexMutaion.isPending}
                                    isSuccess={addIndexMutaion.isSuccess}
                                    isError={addIndexMutaion.isError}
                                    error={addIndexMutaion.error}
                                    resetMutation={addIndexMutaion.reset}
                                    objectToAdd="index"
                                    objectToAddContext="male"
                                    onValidate={addIndexForm.handleSubmit(submitAddIndexForm)}
                                    onSuccess={() => {
                                        setDefaultValueCode("");
                                        setActiveItem(addIndexMutaion.data);
                                    }}
                                    onCancel={() => setDefaultValueCode("")}
                                    renderModalContent={() => (
                                        <FormIndex
                                            control={addIndexForm.control}
                                            errors={addIndexForm.formState.errors}
                                            defaultValueCode={defaultValueCode}
                                            setDefaultValueCode={setDefaultValueCode}
                                        />
                                    )}
                                    renderOpenButton={() => {
                                        return (
                                            <Menu.Item>
                                                <Icon name="add" color="blue" />
                                                {t("Add index")}
                                            </Menu.Item>
                                        );
                                    }}
                                />
                            </Menu>
                        </Grid.Column>

                        {activeItem && 
                            <IndexValueList
                                activeItem={activeItem}
                            />
                        }
                    </Grid>
                </Segment>
            </div>
        </Container>
    );
}

export default IndexPage;