import {Dispatch} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "semantic-ui-react";

type UnitTestResultExpandShrinkProps = {
    maxIndex: number;
    setActivesIndexes: Dispatch<React.SetStateAction<number[] | undefined>>;
};

const UnitTestResultExpandShrink = ({maxIndex, setActivesIndexes}: UnitTestResultExpandShrinkProps) => {
    const {t} = useTranslation();

    const expand = () => {
        const newState = Array.from(Array(maxIndex).keys());
        setActivesIndexes(newState);
    };

    const shrink = () => setActivesIndexes([]);

    return (
        <div className="expand-shrink-buttons">
            <Button data-cy="expand-button" icon labelPosition="left" basic color="violet" onClick={expand}>
                <i
                    aria-hidden="true"
                    className="icon arrow-expand-stroke-rounded"
                    style={{backgroundColor: "transparent"}}
                />
                {t("Expand all")}
            </Button>
            <Button data-cy="shrink-button" icon labelPosition="left" basic color="violet" onClick={shrink}>
                <i
                    aria-hidden="true"
                    className="icon arrow-shrink-stroke-rounded"
                    style={{backgroundColor: "transparent"}}
                />
                {t("Shrink all")}
            </Button>
        </div>
    );
};

export default UnitTestResultExpandShrink;
