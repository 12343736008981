import { Dispatch, SetStateAction, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Form, Grid, Header, Input, Segment } from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import { useDeleteIndexValueMutation, useUpdateIndexValueMutation } from "../../../../Services/Company/Queries";
import { IndexValue, IndexValueDTO } from "../../../../Services/Company/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";

type IndexValueDetailsType = {
    indexId: number;
    indexValue: IndexValue;
}

type IndexValueDetailsViewAndEditType = {
    indexId: number;
    indexValue: IndexValue;
    setEditMode: Dispatch<SetStateAction<boolean>>;
}

const IndexValueDetails = ({
    indexId,
    indexValue,
}: IndexValueDetailsType) => {

    const [editMode, setEditMode] = useState<boolean>(false);

    if (editMode)  {
        return (
            <IndexValueDetailsEdit indexId={indexId} indexValue={indexValue} setEditMode={setEditMode} />
        );
    } else {
        return (
            <IndexValueDetailsView indexId={indexId} indexValue={indexValue} setEditMode={setEditMode} />
        );
    }
}

const IndexValueDetailsView = ({
    indexId,
    indexValue,
    setEditMode,
}: IndexValueDetailsViewAndEditType) => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const deleteIndexValueMutation = useDeleteIndexValueMutation(indexId, indexValue.id);
    const handleDelete = () => {
        deleteIndexValueMutation.mutate();
    };
    const onIndexValueDeleteSuccess = () => {
        navigate(`/transversal_settings/indexes`, {
            state: {activeTabIndex: 1},
        });
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("Index value")}
            </Header>

            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Effective date")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{new Date(indexValue.effectiveDate).toLocaleDateString("fr")}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Value")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{indexValue.value}</Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="bottom-button-card">
                <Divider />
                <Button primary name="editIndexValue" onClick={() => setEditMode(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteIndexValue"
                    isSuccess={deleteIndexValueMutation.isSuccess}
                    isPending={deleteIndexValueMutation.isPending}
                    isError={deleteIndexValueMutation.isError}
                    resetMutation={deleteIndexValueMutation.reset}
                    onSuccess={onIndexValueDeleteSuccess}
                    onValidate={handleDelete}
                    objectToDelete="Index value"
                    objectType="female"
                />
            </div>
        </Segment>
    );
}

const IndexValueDetailsEdit = ({
    indexId,
    indexValue,
    setEditMode,
}: IndexValueDetailsViewAndEditType) => {
    const {t} = useTranslation();

    const {control, handleSubmit, formState} = useForm<any>({mode: "onBlur"});

    const onIndexValueUpdateSuccess = () => {
        setEditMode(false)
    };

    const updateIndexValueMutation = useUpdateIndexValueMutation(indexId, indexValue.id);

    const submitForm = async (formData: IndexValueDTO) => {
        updateIndexValueMutation.mutate(formData);
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("Index value")}
            </Header>
            <Form noValidate={true}>
                <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                    <Grid.Row style={{paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("Effective date")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Controller
                                control={control}
                                defaultValue={indexValue.effectiveDate}
                                name={"effectiveDate"}
                                rules={{required: true}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        type="date"
                                        fluid
                                        required
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(t, formState.errors, "name")}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("Value")} * : </strong>
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={indexValue.value}
                                name={"value"}
                                rules={{required: true, min: 0}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Input
                                        fluid
                                        name={name}
                                        placeholder={t("0")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="number"
                                        min="0"
                                        step={".01"}
                                        error={getErrorMessage(
                                            t,
                                            formState.errors,
                                            name,
                                            undefined,
                                            undefined,
                                            undefined,
                                            0
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className="bottom-button-card">
                    <Divider />
                    <Button name="cancel" secondary onClick={() => setEditMode(false)}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        onSuccess={onIndexValueUpdateSuccess}
                        resetMutation={updateIndexValueMutation.reset}
                        isSuccess={updateIndexValueMutation.isSuccess}
                        isPending={updateIndexValueMutation.isPending}
                        isError={updateIndexValueMutation.isError}
                        error={updateIndexValueMutation.error}
                        onValidate={handleSubmit(submitForm)}
                        objectToUpdate="Index value"
                        objectType="female"
                        openModalButtonName="updateIndexValue"
                        isModalDisabled={Object.keys(formState.errors).length !== 0}
                    />
                </div>
            </Form>
        </Segment>
    );
}

export default IndexValueDetails;