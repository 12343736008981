import {useTranslation} from "react-i18next";
import {TabPane} from "semantic-ui-react";
import {InsurancePackageResult} from "../../../../Services/Pricing/Types";
import {formatBigDecimalAsCurrency} from "../../../../Utils/NumberUtils";

type UnitTestResultAmountsPaneProps = {
    insurancePackage: InsurancePackageResult;
};

const UnitTestResultAmountsPane = ({insurancePackage}: UnitTestResultAmountsPaneProps) => {
    const {t} = useTranslation();

    return (
        <TabPane data-cy="amounts-pane">
            <div className="amounts-pane">
                <div data-cy="amounts">
                    <b>{t("Amounts")}</b>
                    <div className="row gap-1">
                        <div className="col">{t("Annual")}</div>
                        <div className="col right">{t("TTC")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.annualPremiumInclVat, t)}
                        </div>
                        <div className="col right">{t("HT")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.netAnnualPremium, t)}
                        </div>
                        <div className="col right">{t("Taxes")}</div>
                        <div className="col left bold">{formatBigDecimalAsCurrency(insurancePackage.taxAnnual, t)}</div>
                    </div>

                    <div className="row gap-1">
                        <div className="col">{t("Prorate")}</div>
                        <div className="col right">{t("TTC")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.splittingPremiumInclVat, t)}
                        </div>
                        <div className="col right">{t("HT")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.netSplittingPremium, t)}
                        </div>
                        <div className="col right">{t("Taxes")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.taxSplitting, t)}
                        </div>
                    </div>

                    <div className="row gap-1">
                        <div className="col">{t("Splitted")}</div>
                        <div className="col right">{t("TTC")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.proratePremiumInclVat, t)}
                        </div>
                        <div className="col right">{t("HT")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.netProratePremium, t)}
                        </div>
                        <div className="col right">{t("Taxes")}</div>
                        <div className="col left bold">
                            {formatBigDecimalAsCurrency(insurancePackage.taxProrate, t)}
                        </div>
                    </div>
                </div>

                <div data-cy="commission-amounts">
                    <b>{t("Commission amounts")}</b>
                    <div className="row gap-1">
                        <div className="col">{t("Contribution")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.contributionCommissionAmount, t)}
                        </div>
                    </div>
                    <div className="row gap-1">
                        <div className="col">{t("Management")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.managementCommissionAmount, t)}
                        </div>
                    </div>
                </div>

                <div data-cy="retrocessions-amounts">
                    <b>{t("Retrocessions amounts")}</b>
                    <div className="row gap-1">
                        <div className="col">{t("business_provider_x", {number: 1})}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.retrocessionAmount1, t)}
                        </div>
                        <div className="col">{t("Calculation basis")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.baseRetrocessionAmount1, t)}
                        </div>
                    </div>
                    <div className="row gap-1">
                        <div className="col">{t("business_provider_x", {number: 2})}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.retrocessionAmount2, t)}
                        </div>
                        <div className="col">{t("Calculation basis")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.baseRetrocessionAmount2, t)}
                        </div>
                    </div>
                    <div className="row gap-1">
                        <div className="col">{t("business_provider_x", {number: 3})}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.retrocessionAmount3, t)}
                        </div>
                        <div className="col">{t("Calculation basis")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.baseRetrocessionAmount3, t)}
                        </div>
                    </div>
                    <div className="row gap-1">
                        <div className="col">{t("business_provider_x", {number: 4})}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.retrocessionAmount4, t)}
                        </div>
                        <div className="col">{t("Calculation basis")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.baseRetrocessionAmount4, t)}
                        </div>
                    </div>
                    <div className="row gap-1">
                        <div className="col">{t("business_provider_x", {number: 5})}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.retrocessionAmount5, t)}
                        </div>
                        <div className="col">{t("Calculation basis")}</div>
                        <div className="col right bold">
                            {formatBigDecimalAsCurrency(insurancePackage.baseRetrocessionAmount5, t)}
                        </div>
                    </div>
                </div>
            </div>
        </TabPane>
    );
};

export default UnitTestResultAmountsPane;
