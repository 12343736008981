import axios from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {PremiumsMutationType} from "./Queries";
import {PricingOutflow} from "./Types";

export const postPricing = async (productCode: string, data: PremiumsMutationType): Promise<PricingOutflow> => {
    try {
        const {pricingInflow, policyEffectiveDate, policyInsurancePackageCode} = data;
        let url = `/products/${productCode}/premiums?calculationSheet=true`;

        if (policyEffectiveDate) url += `&policyEffectiveDate=${policyEffectiveDate}`;
        if (policyInsurancePackageCode) url += `&policyInsurancePackageCode=${policyInsurancePackageCode}`;

        const response = await axiosInstance.post<PricingOutflow>(url, pricingInflow);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
