import {Editor} from "@monaco-editor/react";
import {PricingOutflow} from "../../../../Services/Pricing/Types";

type UnitTestResultRawViewProps = {
    pricingOutflow: PricingOutflow;
};

const UnitTestResultRawView = ({pricingOutflow}: UnitTestResultRawViewProps) => {
    return (
        <Editor
            height="calc(100vh - 300px)"
            language="json"
            value={JSON.stringify(pricingOutflow, null, 4)}
            options={{
                domReadOnly: true,
                readOnly: true,
                tabSize: 4,
            }}
        />
    );
};

export default UnitTestResultRawView;
