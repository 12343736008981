import {UseMutationResult} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Dropdown, Form, Icon} from "semantic-ui-react";
import EntityPicker from "../../../../../Components/Modals/EntityPicker";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import SystemVariableCard from "../../../../../Components/SystemVariable/SystemVariableCard";
import {RulePart} from "../../../../../Services/ComputingRule/Types";
import {useGetPricingCriteriasList} from "../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType, PricingCriterias} from "../../../../../Services/PricingCriterias/Types";
import {CustomError} from "../../../../../Services/RequestUtils";
import {useGetSystemVariables, useGetVariablesList} from "../../../../../Services/Variables/Queries";
import {
    SystemVariableDTO,
    Variable,
    VariableCondition,
    VariableType,
    VariableValueType,
} from "../../../../../Services/Variables/Types";
import {GenericReducerActionType} from "../../../../../Utils/ReducerUtils";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsVariableName,
} from "../../../../../Utils/SortUtils";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import ComputingRuleAssignmentEdit from "../../../ComputingRule/ComputingRuleAssignment/ComputingRuleAssignmentEdit";
import {PricingCriteriaCard} from "../../../Version/Fragments/PricingCriterias/PricingCriteriaCard";
import {VariableCard} from "../../../Version/Fragments/Variables/VariableCard";
import {AssignmentType, VariableConditionActionType} from "./VariableConditionAssignementReducer";
import VariableConditionGridSearchEdit from "./VariableConditionGridSearchEdit";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type VariableConditionAssignementUpdatePropsType = {
    variable: Variable;
    assignmentType: AssignmentType;
    conditionState: VariableCondition;
    originalCondition: VariableCondition;
    dispatchCondition: React.Dispatch<VariableConditionActionType>;
    checkSyntax: UseMutationResult<boolean, CustomError, RulePart[], Error>;
    setIsErrorVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsComputingRuleChange: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    isComputingRuleChange: boolean | undefined;
    control: Control;
    errors: FieldErrors;
    setValue: (
        name: string,
        value: any,
        config?:
            | Partial<{
                  shouldValidate: boolean;
                  shouldDirty: boolean;
              }>
            | undefined
    ) => void;
    trigger: (name?: string | string[] | undefined) => Promise<boolean>;
};

const VariableConditionAssignementUpdate = ({
    variable,
    assignmentType,
    conditionState,
    originalCondition,
    dispatchCondition,
    checkSyntax,
    setIsErrorVisible,
    setIsComputingRuleChange,
    isComputingRuleChange,
    control,
    errors,
    setValue,
    trigger,
}: VariableConditionAssignementUpdatePropsType) => {
    const {t, i18n} = useTranslation();
    const params = useParams() as IParams;

    const [openPricingCriteriaModal, setOpenPricingCriteriaModal] = useState<boolean>(false);
    const [openVariableModal, setOpenVariableModal] = useState<boolean>(false);
    const [openVariableSystemModal, setOpenVariableSystemModal] = useState<boolean>(false);

    const getInputTypeBasedOnVariableValueType = () => {
        switch (variable.valueType) {
            case VariableValueType.NUMERIC:
                return "number";
            case VariableValueType.STRING:
                return "text";
            case VariableValueType.DATE:
                return "date";
        }
    };

    useEffect(() => {
        if (
            assignmentType === AssignmentType.PRICING_CRITERIA &&
            conditionState.variableCriterias[0].pricingCriteriaName === ""
        )
            setOpenPricingCriteriaModal(true);
        else if (assignmentType === AssignmentType.VARIABLE && conditionState.variableCriterias[0].variableName === "")
            setOpenVariableModal(true);
        else if (
            assignmentType === AssignmentType.SYSTEM_VARIABLE &&
            conditionState.variableCriterias[0].systemVariable === ""
        )
            setOpenVariableSystemModal(true);
    }, [assignmentType, conditionState]);

    return (
        <>
            {assignmentType === AssignmentType.FIXED_VALUE && (
                <Controller
                    control={control}
                    defaultValue={conditionState.variableCriterias[0].value || ""}
                    rules={{required: variable.valueType !== "STRING"}}
                    name={"fixedValue"}
                    render={({field: {name, value, onChange, onBlur, ref}}) => (
                        <Form.Input
                            inputref={ref}
                            fluid
                            name={name}
                            placeholder={t("Fixed value")}
                            required
                            type={getInputTypeBasedOnVariableValueType()}
                            onBlur={onBlur}
                            onChange={(e) => {
                                dispatchCondition({
                                    type: GenericReducerActionType.EDIT,
                                    assignmentType: AssignmentType.FIXED_VALUE,
                                    payload: {fixedValue: e.target.value},
                                });
                                onChange(e);
                            }}
                            value={value}
                            maxLength="100"
                            error={getErrorMessage(t, errors, "fixedValue")}
                            action>
                            <input />
                            <Button
                                name="remove_fixedValue"
                                icon="delete"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange("");
                                    dispatchCondition({
                                        type: GenericReducerActionType.REMOVE,
                                        assignmentType: AssignmentType.FIXED_VALUE,
                                        payload: {},
                                    });
                                }}
                            />
                        </Form.Input>
                    )}
                />
            )}

            {assignmentType === AssignmentType.SYSTEM_VARIABLE &&
                (openVariableSystemModal ? (
                    <EntityPicker
                        object={"System variable"}
                        objectContext={"female"}
                        entityListGetMethod={useGetSystemVariables}
                        entityListGetParameters={[params.versionId]}
                        renderCardContent={(variable: SystemVariableDTO) => (
                            <SystemVariableCard
                                key={variable.variableName}
                                systemVariable={variable}
                                onClick={(v: SystemVariableDTO) => {
                                    dispatchCondition({
                                        type: GenericReducerActionType.EDIT,
                                        assignmentType: AssignmentType.SYSTEM_VARIABLE,
                                        payload: {systemVariable: v.variableLabel},
                                    });
                                    setOpenVariableSystemModal(false);
                                }}
                            />
                        )}
                        filterEntity={(v: SystemVariableDTO) => {
                            return (
                                String(variable.valueType) === VariableValueType.STRING ||
                                String(v.variableCritereType) === VariableValueType.STRING ||
                                String(v.variableCritereType) === String(variable.valueType)
                            );
                        }}
                        filterBySearch={(v: SystemVariableDTO, search: string): boolean => {
                            const display = i18n.exists(`enums.SystemVariable.${v.variableName}`)
                                ? t(`enums.SystemVariable.${v.variableName}`)
                                : v.variableName;

                            return (
                                display.toLowerCase().includes(search.toLowerCase()) ||
                                v.variableName.toLowerCase().includes(search.toLowerCase()) ||
                                v.variableCritereType.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onCancel={() => {
                            setOpenVariableSystemModal(false);
                            if (conditionState.variableCriterias[0].systemVariable === "") {
                                dispatchCondition({
                                    type: GenericReducerActionType.REMOVE,
                                    assignmentType: AssignmentType.SYSTEM_VARIABLE,
                                    payload: {},
                                });
                            }
                        }}
                        sortOptions={[...sortOptionsVariableName]}
                        defaultSortMethod={(a: SystemVariableDTO, b: SystemVariableDTO) =>
                            a.variableName.toLowerCase() < b.variableName.toLowerCase() ? 1 : -1
                        }
                        placeholder={<CardListPlaceholder />}
                    />
                ) : (
                    <Button
                        icon
                        labelPosition="right"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenVariableSystemModal(true);
                        }}>
                        {i18n.exists(`enums.SystemVariable.${conditionState.variableCriterias[0].systemVariable}`)
                            ? t(`enums.SystemVariable.${conditionState.variableCriterias[0].systemVariable}`)
                            : conditionState.variableCriterias[0].systemVariable}
                        <Icon
                            className="remove_system_variable"
                            name="delete"
                            onClick={(e: MouseEvent) => {
                                e.stopPropagation();
                                dispatchCondition({
                                    type: GenericReducerActionType.REMOVE,
                                    assignmentType: AssignmentType.SYSTEM_VARIABLE,
                                    payload: {},
                                });
                            }}
                        />
                    </Button>
                ))}

            {assignmentType === AssignmentType.PRICING_CRITERIA &&
                (openPricingCriteriaModal ? (
                    <EntityPicker
                        object={"Pricing criteria"}
                        objectContext={"male"}
                        entityListGetMethod={useGetPricingCriteriasList}
                        entityListGetParameters={[params.versionId, true]}
                        renderCardContent={(pricingCriteria: PricingCriterias) => (
                            <PricingCriteriaCard
                                key={pricingCriteria.id}
                                versionId={params.versionId}
                                pricingCriteria={pricingCriteria}
                                onClick={(pricingCriteria: PricingCriterias) => {
                                    dispatchCondition({
                                        type: GenericReducerActionType.EDIT,
                                        assignmentType: AssignmentType.PRICING_CRITERIA,
                                        payload: {pricingCriteria: pricingCriteria.name},
                                    });
                                    setOpenPricingCriteriaModal(false);
                                }}
                            />
                        )}
                        filterEntity={(pricingCriteria: PricingCriterias) => {
                            return (
                                String(variable.valueType) === VariableValueType.STRING ||
                                String(pricingCriteria.type) === PricingCriteriaItemType.STRING ||
                                String(pricingCriteria.type) === String(variable.valueType)
                            );
                        }}
                        filterBySearch={(pricingCriteria: PricingCriterias, search: string): boolean => {
                            return (
                                pricingCriteria.name.toLowerCase().includes(search.toLowerCase()) ||
                                pricingCriteria.type.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onCancel={() => {
                            setOpenPricingCriteriaModal(false);
                            if (conditionState.variableCriterias[0].pricingCriteriaName === "") {
                                dispatchCondition({
                                    type: GenericReducerActionType.REMOVE,
                                    assignmentType: AssignmentType.VARIABLE,
                                    payload: {},
                                });
                            }
                        }}
                        sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                        defaultSortMethod={(a: PricingCriterias, b: PricingCriterias) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                        }
                        placeholder={<CardListPlaceholder />}
                    />
                ) : (
                    <Button
                        icon
                        labelPosition="right"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenPricingCriteriaModal(true);
                        }}>
                        {conditionState.variableCriterias[0].pricingCriteriaName || ""}
                        <Icon
                            className="remove_pricing_criteria"
                            name="delete"
                            onClick={(e: MouseEvent) => {
                                e.stopPropagation();
                                dispatchCondition({
                                    type: GenericReducerActionType.REMOVE,
                                    assignmentType: AssignmentType.PRICING_CRITERIA,
                                    payload: {},
                                });
                            }}
                        />
                    </Button>
                ))}

            {assignmentType === AssignmentType.VARIABLE &&
                (openVariableModal ? (
                    <EntityPicker
                        object={"Variable"}
                        objectContext={"female"}
                        entityListGetMethod={useGetVariablesList}
                        entityListGetParameters={[params.versionId, VariableType.V]}
                        renderCardContent={(variable: Variable) => (
                            <VariableCard
                                key={variable.id}
                                versionId={params.versionId}
                                variable={variable}
                                onClick={(variable: Variable) => {
                                    dispatchCondition({
                                        type: GenericReducerActionType.EDIT,
                                        assignmentType: AssignmentType.VARIABLE,
                                        payload: {variableName: variable.name},
                                    });
                                    setOpenVariableModal(false);
                                }}
                            />
                        )}
                        filterEntity={(variableToFilter: Variable) => {
                            return (
                                String(variable.valueType) === VariableValueType.STRING ||
                                String(variableToFilter.valueType) === VariableValueType.STRING ||
                                String(variableToFilter.valueType) === String(variable.valueType)
                            );
                        }}
                        filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                            return (
                                variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                                variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                                search === ""
                            );
                        }}
                        onCancel={() => {
                            setOpenVariableModal(false);
                            if (conditionState.variableCriterias[0].variableName === "") {
                                dispatchCondition({
                                    type: GenericReducerActionType.REMOVE,
                                    assignmentType: AssignmentType.VARIABLE,
                                    payload: {},
                                });
                            }
                        }}
                        sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                        defaultSortMethod={(a: Variable, b: Variable) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                        }
                        placeholder={<CardListPlaceholder />}
                    />
                ) : (
                    <Button
                        icon
                        labelPosition="right"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenVariableModal(true);
                        }}>
                        {conditionState.variableCriterias[0].variableName || ""}
                        <Icon
                            className="remove_variable"
                            name="delete"
                            onClick={(e: MouseEvent) => {
                                e.stopPropagation();
                                dispatchCondition({
                                    type: GenericReducerActionType.REMOVE,
                                    assignmentType: AssignmentType.VARIABLE,
                                    payload: {},
                                });
                            }}
                        />
                    </Button>
                ))}

            {assignmentType === AssignmentType.VARIABLE_COMPUTING_RULE && (
                <ComputingRuleAssignmentEdit
                    computingRuleRoundingMode={
                        conditionState.variableCriterias[0].computingRule?.computingRuleConditions[0].roundingMode
                    }
                    computingRuleDecimalCount={
                        conditionState.variableCriterias[0].computingRule?.computingRuleConditions[0].decimalCount
                    }
                    computingRuleRulePartList={
                        conditionState.variableCriterias[0].computingRule?.computingRuleConditions[0].rulePartList
                    }
                    dispatchCondition={dispatchCondition}
                    checkSyntax={checkSyntax}
                    setIsErrorVisible={setIsErrorVisible}
                    setIsComputingRuleChange={setIsComputingRuleChange}
                    isComputingRuleChange={isComputingRuleChange}
                />
            )}

            {assignmentType === AssignmentType.GRID_SEARCH && (
                <VariableConditionGridSearchEdit
                    versionId={params.versionId}
                    conditionState={conditionState}
                    originalCondition={originalCondition}
                    dispatchCondition={dispatchCondition}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    trigger={trigger}
                    variableValueType={getInputTypeBasedOnVariableValueType()}
                />
            )}

            {assignmentType === AssignmentType.NOTHING && (
                <Dropdown
                    className="assignement_type_dropdown"
                    defaultValue={assignmentType}
                    onChange={(_e, {value}) => {
                        if (value === AssignmentType.GRID_SEARCH) {
                            dispatchCondition({
                                type: GenericReducerActionType.EDIT,
                                assignmentType: AssignmentType.GRID_SEARCH,
                                payload: {refGridCode: "", variableCriterias: []},
                            });
                        } else {
                            dispatchCondition({
                                type: GenericReducerActionType.EDIT,
                                assignmentType: value as AssignmentType,
                                payload: {
                                    fixedValue: value === AssignmentType.FIXED_VALUE ? "" : null,
                                    systemVariable: value === AssignmentType.SYSTEM_VARIABLE ? "" : null,
                                    pricingCriteria: value === AssignmentType.PRICING_CRITERIA ? "" : null,
                                    variableName: value === AssignmentType.VARIABLE ? "" : null,
                                    rulePartList: value === AssignmentType.VARIABLE_COMPUTING_RULE ? [] : null,
                                },
                            });
                        }
                    }}
                    options={
                        variable.variableType === VariableType.V
                            ? [
                                  {
                                      key: 1,
                                      text: t("enums.AssignmentType.FIXED_VALUE"),
                                      value: AssignmentType.FIXED_VALUE,
                                  },
                                  {
                                      key: 2,
                                      text: t("enums.AssignmentType.SYSTEM_VARIABLE"),
                                      value: AssignmentType.SYSTEM_VARIABLE,
                                  },
                                  {
                                      key: 3,
                                      text: t("enums.AssignmentType.PRICING_CRITERIA"),
                                      value: AssignmentType.PRICING_CRITERIA,
                                  },
                                  {key: 4, text: t("enums.AssignmentType.VARIABLE"), value: AssignmentType.VARIABLE},
                                  {
                                      key: 5,
                                      text: t("enums.AssignmentType.GRID_SEARCH"),
                                      value: AssignmentType.GRID_SEARCH,
                                  },
                              ]
                            : [
                                  {
                                      key: 1,
                                      text: t("Computing rule"),
                                      value: AssignmentType.VARIABLE_COMPUTING_RULE,
                                  },
                              ]
                    }
                    selection
                />
            )}
        </>
    );
};

export default VariableConditionAssignementUpdate;
