import {useCallback, useEffect} from "react";
import {FieldValues, UseFormProps, UseFormReturn, useForm} from "react-hook-form";

const useCachedForm = <T extends FieldValues>(
    cacheKey: string,
    props?: UseFormProps<T>
): {
    form: UseFormReturn<T>;
    updateCache: () => void;
} => {
    const cacheValue = localStorage.getItem(cacheKey);
    const form = useForm<T>({
        ...props,
        defaultValues: cacheValue ? JSON.parse(cacheValue) : null,
    });

    const updateCache = useCallback(() => {
        localStorage.setItem(cacheKey, JSON.stringify(form.getValues()));
    }, [cacheKey, form]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (form.formState.isDirty) updateCache();
        }, 10000);

        return () => clearInterval(interval);
    }, [cacheKey, form, updateCache]);

    return {form, updateCache};
};

export default useCachedForm;
