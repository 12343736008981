import React, {Fragment, useEffect, useState} from "react";
import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Input,
    Label,
    Table,
} from "semantic-ui-react";
import EntityPicker from "../../../../../Components/Modals/EntityPicker";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import TableBodyPlaceholder from "../../../../../Components/Placeholder/TableBodyPlaceholder";
import SystemVariableCard from "../../../../../Components/SystemVariable/SystemVariableCard";
import {useGetPricingCriteriasList} from "../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType, PricingCriterias} from "../../../../../Services/PricingCriterias/Types";
import {useGetPricingGridDimensionsQuery, useGetPricingGridsQuery} from "../../../../../Services/PricingGrid/Queries";
import {
    BasicGridType,
    determinePricingGridSearchDisplayValueTypeFromVariableCriteria,
    determinePricingGridSearchValueTypeFromVariableCriteria,
    DimensionValueType,
    PricingGridSearchValueType,
} from "../../../../../Services/PricingGrid/Types";
import {useGetSystemVariables, useGetVariablesList} from "../../../../../Services/Variables/Queries";
import {
    SystemVariableDTO,
    Variable,
    VariableCondition,
    VariableType,
    VariableValueType,
} from "../../../../../Services/Variables/Types";
import {GenericReducerActionType} from "../../../../../Utils/ReducerUtils";
import {
    sortOptionsDataType,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsType,
    sortOptionsVariableName,
    sortOptionsVariableType,
} from "../../../../../Utils/SortUtils";
import {PricingCriteriaCard} from "../../../Version/Fragments/PricingCriterias/PricingCriteriaCard";
import {VariableCard} from "../../../Version/Fragments/Variables/VariableCard";
import {
    AssignmentDimensionType,
    AssignmentType,
    VariableConditionActionType,
} from "./VariableConditionAssignementReducer";

type VariableConditionGridSearchEditPropsType = {
    versionId: string;
    conditionState: VariableCondition;
    originalCondition: VariableCondition;

    dispatchCondition: React.Dispatch<VariableConditionActionType>;

    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    setValue: (
        name: string,
        value: any,
        config?:
            | Partial<{
                  shouldValidate: boolean;
                  shouldDirty: boolean;
              }>
            | undefined
    ) => void;
    trigger: (name?: string | string[] | undefined) => Promise<boolean>;
    variableValueType: string;
};

const VariableConditionGridSearchEdit = ({
    versionId,
    conditionState,
    originalCondition,

    dispatchCondition,

    control,
    errors,
    setValue,
    trigger,
    variableValueType,
}: VariableConditionGridSearchEditPropsType) => {
    const {t, i18n} = useTranslation();

    const gridsQueryResult = useGetPricingGridsQuery(versionId);
    const [selectedGrid, setSelectedGrid] = useState<BasicGridType | undefined>();
    const {data: variableVList} = useGetVariablesList(versionId, VariableType.V);
    const {data: variableRList} = useGetVariablesList(versionId, VariableType.R);
    const [mount, setMount] = useState<boolean>(false);
    const [defaultValueChecked, setDefaultValueChecked] = useState<boolean>(conditionState.defaultValue === null);

    const getDimensionsQuery = useGetPricingGridDimensionsQuery(
        String(selectedGrid?.id) ?? "",
        selectedGrid !== undefined
    );

    useEffect(() => {
        if (
            selectedGrid !== undefined &&
            getDimensionsQuery.isSuccess &&
            (conditionState.variableCriterias.length === 0 || !mount)
        ) {
            dispatchCondition({
                type: GenericReducerActionType.EDIT,
                assignmentType: AssignmentType.GRID_SEARCH,
                payload: {
                    refGridCode: selectedGrid.code,
                    variableCriterias: getDimensionsQuery.data.map((dimension) => {
                        const variable = conditionState.variableCriterias.find(
                            (conditionState) => conditionState.refDimensionCode === dimension.code
                        );
                        if (variable !== undefined) {
                            return {
                                refDimensionCode: dimension.code,
                                value: variable.value !== undefined ? variable.value : null,
                                systemVariable: variable.systemVariable !== undefined ? variable.systemVariable : null,
                                pricingCriteriaName:
                                    variable.pricingCriteriaName !== undefined ? variable.pricingCriteriaName : null,
                                variableName: variable.variableName !== undefined ? variable.variableName : null,
                            };
                        } else {
                            return {
                                refDimensionCode: dimension.code,
                                value: null,
                                systemVariable: null,
                                pricingCriteriaName: null,
                                variableName: null,
                            };
                        }
                    }),
                    defaultValue: conditionState.defaultValue,
                },
            });

            setMount(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDimensionsQuery.data]);

    useEffect(() => {
        if (
            selectedGrid === undefined &&
            gridsQueryResult.isSuccess &&
            gridsQueryResult.data.length > 0 &&
            conditionState &&
            conditionState.refGridCode
        ) {
            const newGrid = gridsQueryResult.data.find((grid) => grid.code === conditionState.refGridCode);
            setSelectedGrid(newGrid);
        }
    }, [gridsQueryResult.isSuccess, gridsQueryResult.data, selectedGrid, conditionState, setValue]);

    const checkDimensionHaveAnyValue = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            (dimension?.value === null &&
                dimension?.systemVariable === null &&
                dimension?.pricingCriteriaName === null &&
                dimension?.variableName === null) ||
            dimension === undefined
        );
    };

    const checkDimensionIsFixedValueType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value !== null &&
            dimension?.systemVariable === null &&
            dimension?.pricingCriteriaName === null &&
            dimension?.variableName === null
        );
    };

    const checkDimensionIsSystemVariableType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value === null &&
            dimension?.systemVariable !== null &&
            dimension?.pricingCriteriaName === null &&
            dimension?.variableName === null
        );
    };

    const checkDimensionIsPricingCriteriaNameType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value === null &&
            dimension?.systemVariable === null &&
            dimension?.pricingCriteriaName !== null &&
            dimension?.variableName === null
        );
    };

    const checkDimensionIsVariableVType = (dimensionCode: string): boolean => {
        const dimension = conditionState.variableCriterias.find((v) => v.refDimensionCode === dimensionCode);
        return (
            (dimension &&
                dimension.variableName !== null &&
                ((dimension.isVariableV !== undefined && dimension.isVariableV) ||
                    variableVList?.find((v) => v.name === dimension.variableName) !== undefined)) ||
            false
        );
    };

    const checkDimensionIsVariableRType = (dimensionCode: string): boolean => {
        const dimension = conditionState.variableCriterias.find((v) => v.refDimensionCode === dimensionCode);
        return (
            (dimension &&
                dimension.variableName !== null &&
                ((dimension.isVariableV !== undefined && !dimension.isVariableV) ||
                    variableRList?.find((v) => v.name === dimension.variableName) !== undefined)) ||
            false
        );
    };

    const getInputTypeBasedOnDimensionType = (dimensionValueType: DimensionValueType) => {
        switch (dimensionValueType) {
            case DimensionValueType.INT:
            case DimensionValueType.DOUBLE:
                return "number";
            case DimensionValueType.STRING:
                return "text";
            case DimensionValueType.DATE:
                return "date";
        }
    };

    return (
        <>
            {gridsQueryResult.isSuccess && gridsQueryResult.data !== undefined && (
                <Grid>
                    <GridRow>
                        <GridColumn width={10}>
                            <Container className="input">
                                <Form.Select
                                    fluid
                                    style={{minWidth: "15em"}}
                                    name="gridCodeDropdown"
                                    placeholder={t("Select a grid")}
                                    value={selectedGrid?.code}
                                    onChange={(_, {value}) => {
                                        const selectedGrid = gridsQueryResult.data?.find((grid) => grid.code === value);

                                        if (selectedGrid !== undefined) {
                                            dispatchCondition({
                                                type: GenericReducerActionType.EDIT,
                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                payload: {
                                                    refGridCode: selectedGrid.code,
                                                    variableCriterias: [],
                                                    defaultValue: conditionState.defaultValue,
                                                },
                                            });

                                            setSelectedGrid(selectedGrid);
                                        }
                                    }}
                                    options={gridsQueryResult.data.map((grid) => {
                                        return {
                                            key: grid.code,
                                            text: grid.code + " (" + grid.label + ")",
                                            value: grid.code,
                                        };
                                    })}
                                />
                                <Button
                                    attached="right"
                                    name="delete"
                                    icon="delete"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatchCondition({
                                            type: GenericReducerActionType.REMOVE,
                                            assignmentType: AssignmentType.GRID_SEARCH,
                                            payload: {},
                                        });
                                    }}
                                />
                            </Container>
                        </GridColumn>
                    </GridRow>
                </Grid>
            )}

            <Table fixed celled style={{width: 800, maxWidth: "100%"}} data-cy="pricing-grid-search-edit-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {selectedGrid && getDimensionsQuery.isPending && <TableBodyPlaceholder numbersOfColumns={2} />}

                    {getDimensionsQuery.data !== undefined &&
                        selectedGrid &&
                        conditionState !== null &&
                        conditionState.variableCriterias !== null &&
                        conditionState.variableCriterias.length > 0 &&
                        getDimensionsQuery.data.map((dimension) => {
                            const vCriteria = conditionState.variableCriterias.find(
                                (v) => v.refDimensionCode === dimension.code
                            );

                            return (
                                <Table.Row key={dimension.code}>
                                    <Table.Cell>{dimension.code}</Table.Cell>
                                    <Table.Cell style={{overflow: "visible"}}>
                                        {/* Sélectionner le type de valeur associé sur une dimension dans la recherche de grille : valeur fixe ou critère tarifaire */}
                                        {checkDimensionHaveAnyValue(dimension.code) && (
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    validate: (value) => value !== AssignmentDimensionType.NOTHING,
                                                }}
                                                defaultValue={AssignmentDimensionType.NOTHING}
                                                name={dimension.code + "_assignmentDimensionType"}
                                                render={({field: {name, value, onBlur}}) => (
                                                    <Dropdown
                                                        fluid
                                                        name={name}
                                                        value={value}
                                                        floating={true}
                                                        placeholder={t("Select a type of associated value")}
                                                        onBlur={onBlur}
                                                        onChange={(_, {value}) => {
                                                            const criteriaAlreadyExists =
                                                                conditionState.variableCriterias.find(
                                                                    (criteria) =>
                                                                        criteria.refDimensionCode === dimension.code
                                                                );
                                                            if (!criteriaAlreadyExists)
                                                                conditionState.variableCriterias.push({
                                                                    id: null,
                                                                    value: null,
                                                                    refDimensionCode: dimension.code,
                                                                    variableName: null,
                                                                    pricingCriteriaName: null,
                                                                    computingRule: null,
                                                                    systemVariable: null,
                                                                });

                                                            dispatchCondition({
                                                                type: GenericReducerActionType.EDIT,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    value as AssignmentDimensionType,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                    value:
                                                                        value === AssignmentDimensionType.FIXED_VALUE
                                                                            ? ""
                                                                            : null,
                                                                    systemVariable:
                                                                        value ===
                                                                        AssignmentDimensionType.SYSTEM_VARIABLE
                                                                            ? ""
                                                                            : null,
                                                                    pricingCriteria:
                                                                        value ===
                                                                        AssignmentDimensionType.PRICING_CRITERIA
                                                                            ? ""
                                                                            : null,
                                                                    variable:
                                                                        value === AssignmentDimensionType.VARIABLE ||
                                                                        value === AssignmentDimensionType.VARIABLE_R
                                                                            ? ""
                                                                            : null,
                                                                },
                                                            });
                                                        }}
                                                        error={errors[name] ? true : false}
                                                        options={Object.keys(AssignmentDimensionType)
                                                            .filter(
                                                                (assignmentDimensionType) =>
                                                                    assignmentDimensionType !==
                                                                    AssignmentDimensionType.NOTHING
                                                            )
                                                            .map((assignmentDimensionType) => {
                                                                return {
                                                                    value: assignmentDimensionType,
                                                                    key: assignmentDimensionType,
                                                                    text: t(
                                                                        "enums.AssignmentType." +
                                                                            assignmentDimensionType
                                                                    ),
                                                                };
                                                            })}
                                                        selection
                                                    />
                                                )}
                                            />
                                        )}

                                        {/* Input en mode valeur fixe */}
                                        {checkDimensionIsFixedValueType(dimension.code) && (
                                            <Controller
                                                control={control}
                                                rules={{required: true}}
                                                defaultValue={
                                                    conditionState.variableCriterias.find(
                                                        (variable) => variable.refDimensionCode === dimension.code
                                                    )?.value || ""
                                                }
                                                name={dimension.code + "_fixedValue"}
                                                render={({field: {name, value, onChange, onBlur}}) => (
                                                    <Input
                                                        fluid
                                                        required
                                                        value={value}
                                                        name={name}
                                                        maxLength="100"
                                                        placeholder={t("Fixed value")}
                                                        type={getInputTypeBasedOnDimensionType(
                                                            dimension.dimensionValueType
                                                        )}
                                                        step={
                                                            dimension.dimensionValueType === DimensionValueType.DOUBLE
                                                                ? "0.01"
                                                                : "1"
                                                        }
                                                        onBlur={onBlur}
                                                        onChange={(e, {value}) => {
                                                            onChange(e);
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.EDIT,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.FIXED_VALUE,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                    value: value,
                                                                },
                                                            });
                                                            setValue(name, value);
                                                            trigger(name);
                                                        }}
                                                        error={errors[name] ? true : false}
                                                        action>
                                                        <input />
                                                        <Button
                                                            name={dimension.code + "_delete"}
                                                            icon="delete"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                dispatchCondition({
                                                                    type: GenericReducerActionType.REMOVE,
                                                                    assignmentType: AssignmentType.GRID_SEARCH,
                                                                    assignmentDimensionType:
                                                                        AssignmentDimensionType.FIXED_VALUE,
                                                                    payload: {
                                                                        conditionState: conditionState,
                                                                        dimensionCode: dimension.code,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </Input>
                                                )}
                                            />
                                        )}

                                        {/* Dropdown sélection d'une variable système à affecter */}
                                        {checkDimensionIsSystemVariableType(dimension.code) && (
                                            <Fragment>
                                                <Button
                                                    as={"div"}
                                                    labelPosition="left"
                                                    fluid
                                                    style={{marginRight: 0}}
                                                    onClick={() => {
                                                        dispatchCondition({
                                                            type: GenericReducerActionType.EDIT,
                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                            assignmentDimensionType:
                                                                AssignmentDimensionType.SYSTEM_VARIABLE,
                                                            payload: {
                                                                conditionState: conditionState,
                                                                dimensionCode: dimension.code,
                                                                systemVariable: "",
                                                            },
                                                        });
                                                    }}>
                                                    <Label
                                                        basic
                                                        style={{flex: 1, overflow: "hidden", fontWeight: "normal"}}>
                                                        {i18n.exists(
                                                            `enums.SystemVariable.${vCriteria?.systemVariable}`
                                                        )
                                                            ? t(`enums.SystemVariable.${vCriteria?.systemVariable}`)
                                                            : vCriteria?.systemVariable}
                                                    </Label>

                                                    <Button
                                                        icon
                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                            e.stopPropagation();
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.SYSTEM_VARIABLE,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}>
                                                        <Icon name="delete" />
                                                    </Button>
                                                </Button>

                                                {vCriteria?.systemVariable === "" && (
                                                    <EntityPicker
                                                        object={"System variable"}
                                                        objectContext={"female"}
                                                        entityListGetMethod={useGetSystemVariables}
                                                        entityListGetParameters={[versionId]}
                                                        renderCardContent={(variable: SystemVariableDTO) => (
                                                            <SystemVariableCard
                                                                systemVariable={variable}
                                                                onClick={(v: SystemVariableDTO) => {
                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                                        assignmentDimensionType:
                                                                            AssignmentDimensionType.SYSTEM_VARIABLE,
                                                                        payload: {
                                                                            conditionState: conditionState,
                                                                            dimensionCode: dimension.code,
                                                                            systemVariable: v.variableLabel,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                        filterEntity={(v: SystemVariableDTO) =>
                                                            String(v.variableCritereType) ===
                                                                VariableValueType.STRING ||
                                                            String(v.variableCritereType) ===
                                                                String(dimension.dimensionValueType) ||
                                                            (v.variableCritereType === VariableValueType.NUMERIC &&
                                                                (dimension.dimensionValueType ===
                                                                    DimensionValueType.INT ||
                                                                    dimension.dimensionValueType ===
                                                                        DimensionValueType.DOUBLE))
                                                        }
                                                        filterBySearch={(
                                                            v: SystemVariableDTO,
                                                            search: string
                                                        ): boolean => {
                                                            const display = i18n.exists(
                                                                `enums.SystemVariable.${v.variableName}`
                                                            )
                                                                ? t(`enums.SystemVariable.${v.variableName}`)
                                                                : v.variableName;

                                                            return (
                                                                display.toLowerCase().includes(search.toLowerCase()) ||
                                                                v.variableName
                                                                    .toLowerCase()
                                                                    .includes(search.toLowerCase()) ||
                                                                v.variableCritereType
                                                                    .toLowerCase()
                                                                    .includes(search.toLowerCase()) ||
                                                                search === ""
                                                            );
                                                        }}
                                                        onCancel={() => {
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.SYSTEM_VARIABLE,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}
                                                        defaultSortMethod={(
                                                            a: SystemVariableDTO,
                                                            b: SystemVariableDTO
                                                        ) =>
                                                            a.variableName.toLowerCase() < b.variableName.toLowerCase()
                                                                ? 1
                                                                : -1
                                                        }
                                                        sortOptions={[
                                                            ...sortOptionsVariableName,
                                                            ...sortOptionsDataType,
                                                        ]}
                                                        placeholder={<CardListPlaceholder />}
                                                    />
                                                )}
                                            </Fragment>
                                        )}

                                        {checkDimensionIsPricingCriteriaNameType(dimension.code) && (
                                            <Fragment>
                                                <Button
                                                    as={"div"}
                                                    labelPosition="left"
                                                    fluid
                                                    style={{marginRight: 0}}
                                                    onClick={() => {
                                                        dispatchCondition({
                                                            type: GenericReducerActionType.EDIT,
                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                            assignmentDimensionType:
                                                                AssignmentDimensionType.PRICING_CRITERIA,
                                                            payload: {
                                                                conditionState: conditionState,
                                                                dimensionCode: dimension.code,
                                                                pricingCriteria: "",
                                                            },
                                                        });
                                                    }}>
                                                    <Label
                                                        basic
                                                        style={{flex: 1, overflow: "hidden", fontWeight: "normal"}}>
                                                        {vCriteria?.pricingCriteriaName}
                                                    </Label>

                                                    <Button
                                                        icon
                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                            e.stopPropagation();
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.PRICING_CRITERIA,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}>
                                                        <Icon name="delete" />
                                                    </Button>
                                                </Button>

                                                {vCriteria?.pricingCriteriaName === "" && (
                                                    <EntityPicker
                                                        object={"Pricing criteria"}
                                                        objectContext={"male"}
                                                        entityListGetMethod={useGetPricingCriteriasList}
                                                        entityListGetParameters={[versionId, true]}
                                                        renderCardContent={(pricingCriteria: PricingCriterias) => (
                                                            <PricingCriteriaCard
                                                                versionId={versionId}
                                                                pricingCriteria={pricingCriteria}
                                                                onClick={(v: PricingCriterias) => {
                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                                        assignmentDimensionType:
                                                                            AssignmentDimensionType.PRICING_CRITERIA,
                                                                        payload: {
                                                                            conditionState: conditionState,
                                                                            dimensionCode: dimension.code,
                                                                            pricingCriteria: v.name,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                        filterEntity={(p: PricingCriterias) =>
                                                            String(p.type) === PricingCriteriaItemType.STRING ||
                                                            String(p.type) === String(dimension.dimensionValueType) ||
                                                            (p.type === PricingCriteriaItemType.NUMERIC &&
                                                                (dimension.dimensionValueType ===
                                                                    DimensionValueType.INT ||
                                                                    dimension.dimensionValueType ===
                                                                        DimensionValueType.DOUBLE))
                                                        }
                                                        filterBySearch={(
                                                            p: PricingCriterias,
                                                            search: string
                                                        ): boolean => {
                                                            return (
                                                                p.name.toLowerCase().includes(search.toLowerCase()) ||
                                                                p.type.toLowerCase().includes(search.toLowerCase()) ||
                                                                search === ""
                                                            );
                                                        }}
                                                        onCancel={() => {
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.PRICING_CRITERIA,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}
                                                        defaultSortMethod={(
                                                            a: PricingCriterias,
                                                            b: PricingCriterias
                                                        ) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)}
                                                        sortOptions={[
                                                            ...sortOptionsName,
                                                            ...sortOptionsType,
                                                            ...sortOptionsModificationDate,
                                                        ]}
                                                        placeholder={<CardListPlaceholder />}
                                                    />
                                                )}
                                            </Fragment>
                                        )}

                                        {/* Dropdown sélection d'une variable à affecter */}
                                        {checkDimensionIsVariableVType(dimension.code) && variableVList && (
                                            <Fragment>
                                                <Button
                                                    as={"div"}
                                                    labelPosition="left"
                                                    fluid
                                                    style={{marginRight: 0}}
                                                    onClick={() => {
                                                        dispatchCondition({
                                                            type: GenericReducerActionType.EDIT,
                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                            assignmentDimensionType: AssignmentDimensionType.VARIABLE,
                                                            payload: {
                                                                conditionState: conditionState,
                                                                dimensionCode: dimension.code,
                                                                variable: "",
                                                            },
                                                        });
                                                    }}>
                                                    <Label
                                                        basic
                                                        style={{flex: 1, overflow: "hidden", fontWeight: "normal"}}>
                                                        {vCriteria?.variableName}
                                                    </Label>

                                                    <Button
                                                        icon
                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                            e.stopPropagation();
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.VARIABLE,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}>
                                                        <Icon name="delete" />
                                                    </Button>
                                                </Button>

                                                {vCriteria?.variableName === "" && (
                                                    <EntityPicker
                                                        object={"Variable"}
                                                        objectContext={"female"}
                                                        entityListGetMethod={useGetVariablesList}
                                                        entityListGetParameters={[versionId, VariableType.V]}
                                                        renderCardContent={(variable: Variable) => (
                                                            <VariableCard
                                                                versionId={versionId}
                                                                variable={variable}
                                                                onClick={(v: Variable) => {
                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                                        assignmentDimensionType:
                                                                            AssignmentDimensionType.VARIABLE,
                                                                        payload: {
                                                                            conditionState: conditionState,
                                                                            dimensionCode: dimension.code,
                                                                            variable: v.name,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                        filterEntity={(v: Variable) =>
                                                            String(v.valueType) === VariableValueType.STRING ||
                                                            String(v.valueType) ===
                                                                String(dimension.dimensionValueType) ||
                                                            (v.valueType === VariableValueType.NUMERIC &&
                                                                (dimension.dimensionValueType ===
                                                                    DimensionValueType.INT ||
                                                                    dimension.dimensionValueType ===
                                                                        DimensionValueType.DOUBLE))
                                                        }
                                                        filterBySearch={(v: Variable, search: string): boolean => {
                                                            return (
                                                                v.name.toLowerCase().includes(search.toLowerCase()) ||
                                                                v.valueType
                                                                    .toLowerCase()
                                                                    .includes(search.toLowerCase()) ||
                                                                search === ""
                                                            );
                                                        }}
                                                        onCancel={() => {
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.VARIABLE,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}
                                                        defaultSortMethod={(a: Variable, b: Variable) =>
                                                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                                                        }
                                                        sortOptions={[
                                                            ...sortOptionsName,
                                                            ...sortOptionsVariableType,
                                                            ...sortOptionsModificationDate,
                                                        ]}
                                                        placeholder={<CardListPlaceholder />}
                                                    />
                                                )}
                                            </Fragment>
                                        )}

                                        {/* Dropdown sélection d'une variable à affecter */}
                                        {checkDimensionIsVariableRType(dimension.code) && variableRList && (
                                            <Fragment>
                                                <Button
                                                    as={"div"}
                                                    labelPosition="left"
                                                    fluid
                                                    style={{marginRight: 0}}
                                                    onClick={() => {
                                                        dispatchCondition({
                                                            type: GenericReducerActionType.EDIT,
                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                            assignmentDimensionType: AssignmentDimensionType.VARIABLE_R,
                                                            payload: {
                                                                conditionState: conditionState,
                                                                dimensionCode: dimension.code,
                                                                variable: "",
                                                            },
                                                        });
                                                    }}>
                                                    <Label
                                                        basic
                                                        style={{flex: 1, overflow: "hidden", fontWeight: "normal"}}>
                                                        {vCriteria?.variableName}
                                                    </Label>

                                                    <Button
                                                        icon
                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                            e.stopPropagation();
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.VARIABLE_R,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}>
                                                        <Icon name="delete" />
                                                    </Button>
                                                </Button>

                                                {vCriteria?.variableName === "" && (
                                                    <EntityPicker
                                                        object={"VARIABLE_COMPUTING_RULE"}
                                                        objectContext={"female"}
                                                        entityListGetMethod={useGetVariablesList}
                                                        entityListGetParameters={[versionId, VariableType.R]}
                                                        renderCardContent={(variable: Variable) => (
                                                            <VariableCard
                                                                versionId={versionId}
                                                                variable={variable}
                                                                onClick={(v: Variable) => {
                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                                        assignmentDimensionType:
                                                                            AssignmentDimensionType.VARIABLE_R,
                                                                        payload: {
                                                                            conditionState: conditionState,
                                                                            dimensionCode: dimension.code,
                                                                            variable: v.name,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                        filterEntity={() =>
                                                            dimension.dimensionValueType !== DimensionValueType.DATE
                                                        }
                                                        filterBySearch={(v: Variable, search: string): boolean => {
                                                            return (
                                                                v.name.toLowerCase().includes(search.toLowerCase()) ||
                                                                v.valueType
                                                                    .toLowerCase()
                                                                    .includes(search.toLowerCase()) ||
                                                                search === ""
                                                            );
                                                        }}
                                                        onCancel={() => {
                                                            dispatchCondition({
                                                                type: GenericReducerActionType.REMOVE,
                                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                                assignmentDimensionType:
                                                                    AssignmentDimensionType.VARIABLE_R,
                                                                payload: {
                                                                    conditionState: conditionState,
                                                                    dimensionCode: dimension.code,
                                                                },
                                                            });
                                                        }}
                                                        defaultSortMethod={(a: Variable, b: Variable) =>
                                                            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                                                        }
                                                        sortOptions={[
                                                            ...sortOptionsName,
                                                            ...sortOptionsModificationDate,
                                                        ]}
                                                        placeholder={<CardListPlaceholder />}
                                                    />
                                                )}
                                            </Fragment>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                </Table.Body>
            </Table>

            <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                <Grid.Row>
                    <GridColumn width={7}>
                        <strong>{t("Reject pricing if search is unsuccessful")} * : </strong>
                    </GridColumn>
                    <GridColumn width={9}>
                        <Controller
                            control={control}
                            name="reject"
                            defaultValue={defaultValueChecked}
                            render={({field: {onChange, value, name, onBlur}}) => (
                                <Form.Checkbox
                                    toggle
                                    name={name}
                                    checked={value}
                                    onBlur={onBlur}
                                    onChange={(_, {checked}) => {
                                        setDefaultValueChecked(checked !== undefined ? checked : false);
                                        onChange(checked);
                                        if (checked) {
                                            dispatchCondition({
                                                type: GenericReducerActionType.EDIT,
                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                payload: {
                                                    refGridCode: conditionState.refGridCode,
                                                    variableCriterias: conditionState.variableCriterias,
                                                    defaultValue: null,
                                                },
                                            });
                                        }
                                    }}
                                />
                            )}
                        />
                    </GridColumn>

                    {!defaultValueChecked && (
                        <>
                            <GridColumn width={7}>
                                <strong>{t("Default value")} * : </strong>
                            </GridColumn>
                            <GridColumn width={3}>
                                <Controller
                                    control={control}
                                    defaultValue={conditionState.defaultValue}
                                    name={"defaultValue"}
                                    rules={{required: true}}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required
                                            type={variableValueType}
                                            name={name}
                                            placeholder={t("Default value")}
                                            onChange={(e, {value}) => {
                                                onChange(e);
                                                dispatchCondition({
                                                    type: GenericReducerActionType.EDIT,
                                                    assignmentType: AssignmentType.GRID_SEARCH,
                                                    payload: {
                                                        refGridCode: conditionState.refGridCode,
                                                        variableCriterias: conditionState.variableCriterias,
                                                        defaultValue: value,
                                                    },
                                                });
                                                setValue(name, value);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            maxLength="100"
                                            error={errors[name] ? true : false}
                                        />
                                    )}
                                />
                            </GridColumn>
                        </>
                    )}
                </Grid.Row>
            </Grid>

            <VariableConditionGridSearchOriginalViewMemo
                versionId={versionId}
                variableCondition={originalCondition}
                selectedGrid={selectedGrid}
                gridsQueryResult={gridsQueryResult.data}
            />
        </>
    );
};

type VariableConditionGridSearchOriginalViewPropsType = {
    versionId: string;
    variableCondition: VariableCondition;
    selectedGrid: BasicGridType | undefined;
    gridsQueryResult: BasicGridType[] | undefined;
};

const VariableConditionGridSearchOriginalView = ({
    versionId,
    variableCondition,
    selectedGrid,
    gridsQueryResult,
}: VariableConditionGridSearchOriginalViewPropsType) => {
    const {t} = useTranslation();
    const originalGridDimensionsQuery = useGetPricingGridDimensionsQuery(
        String(gridsQueryResult?.find((grid) => grid.code === variableCondition.refGridCode)?.id ?? 0),
        !!gridsQueryResult
    );

    // Si une recherche sur une dimension utilise une variable, on ne sait pas déterminer facilement si c'est une V ou une R
    // Il faut donc les récupérer et chercher pour adapter l'affichage
    const {data: variableVList} = useGetVariablesList(versionId, VariableType.V);
    const {data: variableRList} = useGetVariablesList(versionId, VariableType.R);

    const getTranslationKey = (valueType: PricingGridSearchValueType, value: string): string => {
        if (valueType === PricingGridSearchValueType.VARIABLE) {
            const isVariableV = variableVList?.find((variable) => variable.name === value) !== undefined;
            const isVariableR = variableRList?.find((variable) => variable.name === value) !== undefined;

            if (isVariableV) {
                return "enums.PricingGridSearchValueType.VARIABLE_V";
            } else if (isVariableR) {
                return "enums.PricingGridSearchValueType.VARIABLE_R";
            } else {
                return "enums.PricingGridSearchValueType.VARIABLE";
            }
        } else {
            return "enums.PricingGridSearchValueType." + valueType;
        }
    };

    const renderBodyContent = () => {
        if (
            originalGridDimensionsQuery !== undefined &&
            originalGridDimensionsQuery.data !== undefined &&
            originalGridDimensionsQuery.data.length >= variableCondition.variableCriterias.length
        ) {
            // Invalidation suite à l'ajout d'une ou plusieurs dimension(s)
            return originalGridDimensionsQuery.data.map((dimension) => {
                const variableCriteria = variableCondition.variableCriterias?.find(
                    (v) => v.refDimensionCode === dimension.code
                );

                if (variableCriteria) {
                    const searchType = determinePricingGridSearchValueTypeFromVariableCriteria(variableCriteria);
                    const displayValue = determinePricingGridSearchDisplayValueTypeFromVariableCriteria(
                        variableCriteria,
                        t
                    );

                    return (
                        <Table.Row key={dimension.code}>
                            <Table.Cell>{dimension.code}</Table.Cell>
                            <Table.Cell>{t(getTranslationKey(searchType, displayValue))}</Table.Cell>
                            <Table.Cell>{displayValue}</Table.Cell>
                        </Table.Row>
                    );
                } else {
                    return (
                        <Table.Row key={dimension.code} error>
                            <Table.Cell>
                                <Icon name="attention" />
                                {dimension.code}
                            </Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                        </Table.Row>
                    );
                }
            });
        } else if (
            originalGridDimensionsQuery.data !== undefined &&
            originalGridDimensionsQuery.data.length < variableCondition.variableCriterias.length
        ) {
            // Invalidation suite à la suppression d'une ou plusieurs dimension(s)
            return variableCondition.variableCriterias.map((variableCriteria) => {
                const dimension = originalGridDimensionsQuery.data.find(
                    (dimension) => dimension.code === variableCriteria.refDimensionCode
                );

                if (dimension) {
                    const searchType = determinePricingGridSearchValueTypeFromVariableCriteria(variableCriteria);
                    const displayValue = determinePricingGridSearchDisplayValueTypeFromVariableCriteria(
                        variableCriteria,
                        t
                    );

                    return (
                        <Table.Row key={variableCriteria.refDimensionCode}>
                            <Table.Cell>{variableCriteria.refDimensionCode}</Table.Cell>
                            <Table.Cell>{t(getTranslationKey(searchType, displayValue))}</Table.Cell>
                            <Table.Cell>{displayValue}</Table.Cell>
                        </Table.Row>
                    );
                } else {
                    return (
                        <Table.Row key={variableCriteria.refDimensionCode} error>
                            <Table.Cell>
                                <Icon name="attention" />
                                {variableCriteria.refDimensionCode}
                            </Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                        </Table.Row>
                    );
                }
            });
        }
    };

    return (
        <Fragment>
            {((!variableCondition.valid && variableCondition.refGridCode === selectedGrid?.code) ||
                variableCondition.refGridCode !== selectedGrid?.code) &&
                originalGridDimensionsQuery.data !== undefined && (
                    <Fragment>
                        <Divider />
                        <p>
                            {t("Actual grid assignement") + " : "}
                            <strong>{variableCondition.refGridCode}</strong>
                        </p>

                        <Table fixed name="old_assignement">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Associated_value_type")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{renderBodyContent()}</Table.Body>
                        </Table>
                    </Fragment>
                )}
        </Fragment>
    );
};

const VariableConditionGridSearchOriginalViewMemo = React.memo<VariableConditionGridSearchOriginalViewPropsType>(
    VariableConditionGridSearchOriginalView
);

export default VariableConditionGridSearchEdit;
