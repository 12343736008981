import {UseQueryResult} from "@tanstack/react-query";
import {Fragment} from "react";
import {Control, UseFormWatch} from "react-hook-form";
import {Placeholder, Table, TableHeaderCell} from "semantic-ui-react";
import {PricingInflowForm} from "../../../../Services/Pricing/Types";
import {InsuranceCoverageDTO} from "../../../../Services/Product/Types";
import {groupBy} from "../../../../Utils/ObjectUtils";
import UnitTestInsurancePackageOption from "./UnitTestInsurancePackageOption";

type UnitTestInsurancePackageOptionsProps = {
    control: Control<PricingInflowForm>;
    watch: UseFormWatch<PricingInflowForm>;
    query: UseQueryResult<Array<InsuranceCoverageDTO>, Error>;
};

const UnitTestInsurancePackageOptions = ({control, watch, query}: UnitTestInsurancePackageOptionsProps) => {
    const {isSuccess, isPending, data: insurancePackages} = query;

    const selectedInsurancePackageCode = watch("insurancePackageCode");

    const filter = (insurancePackageCode: string) => {
        return selectedInsurancePackageCode === "Toutes" || insurancePackageCode === selectedInsurancePackageCode;
    };

    const getTableBody = () => {
        if (isPending) {
            return Array.from(Array(2).keys()).map((key) => (
                <Table.Row key={key}>
                    <Table.Cell colSpan={3}>
                        <Placeholder fluid>
                            <Placeholder.Header>
                                <Placeholder.Line length="full" />
                            </Placeholder.Header>
                        </Placeholder>
                    </Table.Cell>
                </Table.Row>
            ));
        }

        if (isSuccess && insurancePackages !== undefined) {
            return insurancePackages
                .filter((i) => filter(i.insurancePackageCode))
                .map((i) => {
                    const optionsGroups = groupBy(i.options, (o) => o.registryCode) ?? {};
                    const groups = Object.entries(optionsGroups);

                    const firstGroup = groups[0];

                    if (firstGroup === undefined) {
                        return (
                            <Table.Row key={i.insurancePackageCode}>
                                <Table.Cell>{i.insurancePackageCode}</Table.Cell>
                                <Table.Cell>-</Table.Cell>
                                <Table.Cell>-</Table.Cell>
                            </Table.Row>
                        );
                    }

                    const [firstRegistryCode, firstOptions] = firstGroup;

                    return (
                        <Fragment key={`${i.insurancePackageCode}-${firstRegistryCode}`}>
                            <Table.Row>
                                <Table.Cell rowSpan={groups.length}>{i.insurancePackageCode}</Table.Cell>
                                <Table.Cell>{firstRegistryCode}</Table.Cell>
                                <Table.Cell>
                                    {firstOptions?.map((o) => (
                                        <UnitTestInsurancePackageOption
                                            key={o.code}
                                            control={control}
                                            insurancePackageCode={i.insurancePackageCode}
                                            option={o}
                                        />
                                    ))}
                                </Table.Cell>
                            </Table.Row>

                            {groups.slice(1).map(([registryCode, options]) => (
                                <Table.Row key={registryCode}>
                                    <Table.Cell>{registryCode}</Table.Cell>
                                    <Table.Cell>
                                        {options?.map((o) => (
                                            <UnitTestInsurancePackageOption
                                                key={o.code}
                                                control={control}
                                                insurancePackageCode={i.insurancePackageCode}
                                                option={o}
                                            />
                                        ))}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Fragment>
                    );
                });
        }

        return null;
    };

    return (
        <Table celled structured data-cy="insurance-packages-options">
            <Table.Header>
                <Table.Row>
                    <TableHeaderCell>Formule</TableHeaderCell>
                    <TableHeaderCell>Référentiel</TableHeaderCell>
                    <TableHeaderCell>Choix des options</TableHeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{getTableBody()}</Table.Body>
        </Table>
    );
};

export default UnitTestInsurancePackageOptions;
