import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon, Label, Table} from "semantic-ui-react";
import {PricingGridSearchType, PricingGridSearchValueType} from "../../Services/PricingGrid/Types";
import {useGetVariablesList} from "../../Services/Variables/Queries";
import {VariableType} from "../../Services/Variables/Types";

type PricingGridSearchViewProps = {
    versionId: string;
    pricingGridSearch: PricingGridSearchType;
};

const PricingGridSearchView = ({
    versionId,
    pricingGridSearch: {code, dimensions, defaultValue},
}: PricingGridSearchViewProps) => {
    const {t} = useTranslation();
    const [detailedViewOpen, setDetailedViewOpen] = useState<boolean>(false);
    const toogleDetailedView = () => setDetailedViewOpen((current) => !current);

    // Si une recherche sur une dimension utilise une variable, on ne sait pas déterminer facilement si c'est une V ou une R
    // Il faut donc les récupérer et chercher pour adapter l'affichage
    const {data: variableVList} = useGetVariablesList(versionId, VariableType.V);
    const {data: variableRList} = useGetVariablesList(versionId, VariableType.R);

    const getTranslationKey = (valueType: PricingGridSearchValueType, value: string): string => {
        if (valueType === PricingGridSearchValueType.VARIABLE) {
            const isVariableV = variableVList?.find((variable) => variable.name === value) !== undefined;
            const isVariableR = variableRList?.find((variable) => variable.name === value) !== undefined;

            if (isVariableV) {
                return "enums.PricingGridSearchValueType.VARIABLE_V";
            } else if (isVariableR) {
                return "enums.PricingGridSearchValueType.VARIABLE_R";
            } else {
                return "enums.PricingGridSearchValueType.VARIABLE";
            }
        } else {
            return "enums.PricingGridSearchValueType." + valueType;
        }
    };

    return (
        <Fragment>
            <Label size="large" basic style={{border: "none"}} horizontal>
                {t("Grid search") + " : "}
            </Label>

            <Button icon labelPosition="right" onClick={toogleDetailedView}>
                {code}
                <Icon name={detailedViewOpen ? "eye slash" : "eye"} />
            </Button>

            {detailedViewOpen && (
                <Fragment>
                    <Table fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Associated_value_type")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {dimensions.map((dim) => (
                                <Table.Row key={dim.code}>
                                    <Table.Cell>{dim.code}</Table.Cell>
                                    <Table.Cell>{t(getTranslationKey(dim.valueType, dim.value))}</Table.Cell>
                                    <Table.Cell>{dim.value}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    {defaultValue !== null ? (
                        <Fragment>
                            <Label size="large" basic style={{border: "none"}} horizontal>
                                {t("In case of unsuccessful search_default value")}
                            </Label>
                            <Label size="large" className="fixed_value_assignment" horizontal>
                                {defaultValue}
                            </Label>
                        </Fragment>
                    ) : (
                        <Label size="large" basic style={{border: "none"}} horizontal>
                            {t("In case of an unsuccessful search, the application is rejected")}
                        </Label>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default PricingGridSearchView;
