import {useTranslation} from "react-i18next";
import {Button, Message, Modal} from "semantic-ui-react";
import {CustomError} from "../../Services/RequestUtils";

type ModalErrorProps = {
    error: CustomError | null;
    onClose?: () => void;
};

const ModalError = ({error, onClose}: ModalErrorProps) => {
    const {t} = useTranslation();

    const isOpen = !!error;

    return (
        <Modal data-cy="modal-error" open={isOpen} onClose={onClose}>
            <Modal.Header data-cy="modal-error-header">{t("An error occurred") + "..."}</Modal.Header>
            <Modal.Content data-cy="modal-error-content">
                <Message negative>
                    <strong> {t("Action did not succeed")} : </strong>{" "}
                    {isOpen && t(getResponseErrorTranslationKey(error))}
                    {isOpen && error.details && error.details.length > 0 && (
                        <ul>
                            {error?.details.map((detail) => {
                                const linePatternMatch = detail.match(/^Line\s(\d+)\s:\s(.*)/);
                                if (linePatternMatch !== null) {
                                    return (
                                        <li key={detail}>{`${t("Line")} ${linePatternMatch[1]} : ${t(
                                            linePatternMatch[2]
                                        )}`}</li>
                                    );
                                }

                                const keyValuePatternMatch = detail.match(/(\w+)\s:\s(.*)/);
                                if (keyValuePatternMatch !== null) {
                                    return (
                                        <li key={detail}>{`${t(keyValuePatternMatch[1])} : ${
                                            keyValuePatternMatch[2]
                                        }`}</li>
                                    );
                                }

                                return <li key={detail}>{t(detail)}</li>;
                            })}
                        </ul>
                    )}
                </Message>
            </Modal.Content>
            <Modal.Actions data-cy="modal-error-actions">
                <Button name="close" secondary onClick={onClose}>
                    {t("Close")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const getResponseErrorTranslationKey = (error: CustomError): string => {
    if (error.message) {
        if (error.message.length > 100 || error.message.trim() === "" || !error.message.match(/_/)) {
            switch (error.status) {
                case 403:
                    return error.message;
                case 404:
                    return "Client side error occurred please contact support";
                case 500:
                    return "Server side error occurred please try later";
            }
        } else {
            return `request_errors.${error.message}`;
        }
    }

    return "An error occurred please contact support";
};

export default ModalError;
