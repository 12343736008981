import {useTranslation} from "react-i18next";
import {Card, Label, SemanticCOLORS} from "semantic-ui-react";
import {SystemVariableDTO, VariableValueType} from "../../Services/Variables/Types";
import TextOverflow from "../Text/TextOverflow";

type SystemVariableCardProps = {
    systemVariable: SystemVariableDTO;
    onClick: (() => void) | ((systemVariable: SystemVariableDTO) => void);
    isCurrent?: boolean;
};

const SystemVariableCard = ({systemVariable, onClick, isCurrent}: SystemVariableCardProps) => {
    const {t, i18n} = useTranslation();

    const getColorOfValueTypeLabel = (variableValueType: VariableValueType): SemanticCOLORS | undefined => {
        switch (variableValueType) {
            case VariableValueType.STRING:
                return "blue";
            case VariableValueType.DATE:
                return "green";
            case VariableValueType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    const color = isCurrent ? "blue" : undefined;

    return (
        <Card onClick={() => onClick(systemVariable)} color={color}>
            <Card.Content>
                <Card.Header>
                    {systemVariable.isDynamic || !i18n.exists(`enums.SystemVariable.${systemVariable.variableName}`)
                        ? systemVariable.variableName
                        : t(`enums.SystemVariable.${systemVariable.variableName}`)}
                </Card.Header>
                <Card.Meta>
                    <TextOverflow value={systemVariable.variableName} />
                </Card.Meta>
                <Card.Description
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                    <Label
                        color={getColorOfValueTypeLabel(systemVariable.variableCritereType)}
                        data-cy="system-variable-card-valueType">
                        {t("enums.VariableValueType." + systemVariable.variableCritereType)}
                    </Label>
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default SystemVariableCard;
