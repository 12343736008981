import { ComputingRule } from "../ComputingRule/Types";
import { ConditionPart } from "../Condition/Types";

export type Variable = {
    id: number;
    name: string;
    variableType: VariableType;
    valueType: VariableValueType;
    rank: number;
    saveQuote: boolean;
    variableConditions: VariableCondition[];
    valid: boolean;
    description: string;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type VariableDTO = {
    name: string;
    variableType: VariableType;
    valueType: VariableValueType;
    saveQuote: boolean;
};

export type VariableCondition = {
    id?: number | null;
    ifCondition: string | null;
    conditionPartList?: ConditionPart[];
    orderCondition?: number;
    refGridCode?: string | null;
    variableCriterias: VariableCriteria[];
    defaultValue?: string | null;
    valid?: boolean;
};

export type VariableCriteria = {
    id?: number | null;
    value?: string | null;
    refDimensionCode?: string | null;
    variableName?: string | null;
    pricingCriteriaName?: string | null;
    computingRule?: ComputingRule | null;
    systemVariable?: string | null;

    // Rajouté pour différencier les variables V et R lorsque le champ 'variableName' est !== null / undefined, en mode édition
    isVariableV?: boolean
};

export enum VariableType {
    V = "V",
    C = "C",
    R = "R",
}

export enum VariableValueType {
    STRING = "STRING",
    DATE = "DATE",
    NUMERIC = "NUMERIC",
}

export type SystemVariableList = {
    staticSystemVariable: SystemVariableDTO[];
    dynamicSystemVariable: SystemVariableDTO[];
};

export type SystemVariableDTO = {
    variableName: string;
    variableLabel: string;
    variableCritereType: VariableValueType;
    isDynamic: boolean;
};
