import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Container, Divider, Icon, Label, Radio, Segment, Tab} from "semantic-ui-react";
import {PricingOutflow} from "../../../../Services/Pricing/Types";
import {handleNullablePropertyAsDate} from "../../../../Utils/TextUtils";
import UnitTestResultHeaderInformation from "./UnitTestResultHeaderInformation";
import UnitTestResultInsurancePackagePane from "./UnitTestResultInsurancePackagePane";
import UnitTestResultRawView from "./UnitTestResultRawView";
import UnitTestResultRiskInformationSidebar from "./UnitTestResultRiskInformationSidebar";
import "./unit-test-result.css";

const UnitTestResultPage = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const pricingOutflow: PricingOutflow = location.state as PricingOutflow;

    const openCalculationSheet = () => {
        const newTab = window.open();

        if (newTab) {
            newTab.document.open();
            newTab.document.write(pricingOutflow.calculationSheet);
            newTab.document.close();
        }
    };

    const exportAsJson = (event: React.MouseEvent) => {
        const element = event.target as HTMLAnchorElement;
        const data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(pricingOutflow));
        element.setAttribute("href", "data:" + data);
        element.setAttribute("download", "export_pricing_result.json");
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    const [isRawViewOpen, setIsRawViewOpen] = useState<boolean>(false);
    const toogleRawView = () => setIsRawViewOpen((current) => !current);

    const panes = pricingOutflow.insurancePackages.map((insurancePackage) => {
        return {
            key: insurancePackage.code,
            menuItem: insurancePackage.code + " - " + insurancePackage.label,
            render: () => <UnitTestResultInsurancePackagePane insurancePackage={insurancePackage} />,
        };
    });

    const backForm = () => {
        const parts = location.pathname.split("/");
        navigate(parts.slice(0, parts.length - 1).join("/"));
    };

    return (
        <Container fluid className="unit-test-result">
            <Segment className="page-container">
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <span data-cy="pricing_date">{t("format_date", {value: Date.now()})}</span>
                    <div
                        data-cy="action-buttons"
                        style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "12px"}}
                    >
                        <Button
                            name="back-form"
                            type="button"
                            icon
                            labelPosition="left"
                            basic
                            color="violet"
                            onClick={backForm}
                        >
                            <Icon name="arrow left" style={{backgroundColor: "transparent"}} />
                            {t("Return to form")}
                        </Button>

                        <Button
                            data-cy="calculation-sheet"
                            icon
                            labelPosition="left"
                            color="violet"
                            onClick={openCalculationSheet}
                        >
                            <i aria-hidden="true" className="icon calculator-stroke-rounded" style={{color: "white"}} />
                            {t("Calculation sheet")}
                        </Button>

                        <Button data-cy="export" icon labelPosition="left" basic color="violet" onClick={exportAsJson}>
                            <Icon name="download" style={{backgroundColor: "transparent"}} />
                            {t("Export")}
                        </Button>

                        <Radio
                            data-cy="toggle-raw-view"
                            toggle
                            className="violet"
                            checked={isRawViewOpen}
                            onChange={toogleRawView}
                        />
                    </div>
                </div>

                <h2 data-cy="version-name" style={{margin: "0", marginBottom: "0.5rem"}}>
                    {pricingOutflow.versionCode}
                </h2>

                {isRawViewOpen ? (
                    <UnitTestResultRawView pricingOutflow={pricingOutflow} />
                ) : (
                    <Fragment>
                        <div style={{display: "flex", flexDirection: "row", gap: "3rem"}}>
                            <UnitTestResultHeaderInformation
                                data-cy="header-information-effective-date"
                                label="Date d'effet"
                                renderSubContent={() => (
                                    <span style={{fontWeight: "bold"}}>
                                        {handleNullablePropertyAsDate(pricingOutflow.effectiveDate, t)}
                                    </span>
                                )}
                            />
                            <UnitTestResultHeaderInformation
                                data-cy="header-information-period-end-date"
                                label="Date de fin de période"
                                renderSubContent={() => (
                                    <span style={{fontWeight: "bold"}}>
                                        {handleNullablePropertyAsDate(pricingOutflow.periodEndDate, t)}
                                    </span>
                                )}
                            />
                            <UnitTestResultHeaderInformation
                                data-cy="header-information-splitting-code"
                                label="Fractionnement"
                                renderSubContent={() => (
                                    <Label
                                        circular
                                        basic
                                        color="blue"
                                        content={t(`enums.SplittingType.${pricingOutflow.splittingCode}`)}
                                        icon="clock outline"
                                    />
                                )}
                            />
                            <UnitTestResultHeaderInformation
                                data-cy="header-information-deadline"
                                label="Echéance"
                                renderSubContent={() => (
                                    <span style={{fontWeight: "bold"}}>{pricingOutflow.deadLine}</span>
                                )}
                            />
                            <UnitTestResultHeaderInformation
                                data-cy="header-information-trade-discount"
                                label="Réduction commerciale"
                                renderSubContent={() => (
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "2rem",
                                            color: "#1aae9f",
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {pricingOutflow.tradeDiscount ?? 0} %
                                    </p>
                                )}
                            />
                            <UnitTestResultHeaderInformation
                                data-cy="header-information-additional-elements"
                                label={t("Additional elements")}
                                renderSubContent={() => (
                                    <a
                                        style={{cursor: "pointer", textDecoration: "underline", color: "#6435c9"}}
                                        onClick={() => setIsSidebarOpen(true)}
                                        data-cy="open-risk-informations"
                                    >
                                        Voir les éléments
                                    </a>
                                )}
                            />
                        </div>

                        <Divider />

                        <Tab panes={panes} />

                        <UnitTestResultRiskInformationSidebar
                            isOpen={isSidebarOpen}
                            setIsOpen={setIsSidebarOpen}
                            riskInformations={pricingOutflow.riskInformation}
                        />
                    </Fragment>
                )}
            </Segment>
        </Container>
    );
};

export default UnitTestResultPage;
