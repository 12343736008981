export type RegistryNotAssociatedDTO = {
    registryId: number;
    code: string;
    label: string;
    type: string;
    contributionCommissionRate: number | null;
    managementCommissionRate: number | null;
};

export type RiskTypeItemType = {
    id: number;
    label: string;
    ranges: Array<RangeType>;
};

export type RangeType = {
    id: number;
    label: string;
};

export type Company = {
    id: number;
    label: string;
    prorateMode: ProrateMode;
    prorateDaysCountingMode: ProrateDaysCountingMode;
    commissionMode: CommissionMode;
    internalNovanetUrl: string | null;
    externalNovanetUrl: string | null;
    webserviceLogin: string | null;
    webservicePassword: string | null;
    consoleURL: string;
    environmentName: string;
    retrocessionSettingsActive: boolean;
};

export enum CommissionMode {
    TAUX_UNIQUE = "TAUX_UNIQUE",
    PAR_DIFFERENCE = "PAR_DIFFERENCE",
}

export enum ProrateDaysCountingMode {
    EXACT = "EXACT",
    MOYEN = "MOYEN",
}

export enum ProrateMode {
    ANNUEL = "ANNUEL",
    FRACTIONNE = "FRACTIONNE",
}

export type UserDTO = {
    id: number;
    username: string;
    role: Role;
    email: string;
    enabled: boolean;
    blocked: boolean;
    credentialsExpired: boolean;
    lastLoginDate: string;
};

export type UserGroupDTO = {
    id: number | null;
    name: string;
    userGroupAuthorizations: UserGroupAuthorizationsDTO;
};

export type UserGroupAuthorizationsDTO = {
    id: number | null;
    nbDaysAuthorizedBeforePricingDate: number;
};

export enum Role {
    SUPER_ADMIN = "SUPER_ADMIN",
    ADMINISTRATEUR = "ADMINISTRATEUR",
    ADMIN_PRODUIT = "ADMIN_PRODUIT",
    GESTIONNAIRE = "GESTIONNAIRE",
    APPORTEUR = "APPORTEUR",
}

export type UserAddDTO = {
    username: string;
    email: string;
    password: string | null;
    role: Role;
};

export type UserUpdateDTO = {
    username: string;
    email: string;
    password: string | null;
    role: Role;
};

export type ApplicationAccessDTO = {
    id: number;
    identifier: string;
    username: string;
    token: string;
    role: Role;
    groupName: string;
};

export type ApplicationAccessAddUpdateDTO = {
    username: string;
    identifier: string | null;
    token: string | null;
    role: Role;
    groupId: number;
};

export type CompanyProductInfosType = {
    id: number;
    label: string;
    saveQuote: boolean;
};

export type UserUpdateSelfDTO = {
    username: string | null;
    password: string | null;
};

export type EmailDTO = {
    email: string;
};

export type PasswordDTO = {
    password: string;
};

export type AuthTokenDTO = {
    token: string;
};

export type VerifyTokenDTO = {
    token: string;
    requestedScopes: Array<string>;
};

export type Index = {
    id: number;
    code: string;
    indexValues: IndexValue[];
}

export type IndexValue = {
    id: number;
    effectiveDate: string;
    value: number;
}

export type IndexValueDTO = {
    effectiveDate: string;
    value: number;
}
