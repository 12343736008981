import {Fragment, useMemo} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Dimmer, DropdownItemProps, Form, Grid, Icon, Loader} from "semantic-ui-react";
import ModalError from "../../../../Components/Modals/ModalError";
import useCachedForm from "../../../../Hooks/useCachedForm";
import {usePremiumsMutation} from "../../../../Services/Pricing/Queries";
import {MovementType, PricingInflow, PricingInflowForm} from "../../../../Services/Pricing/Types";
import {useGetInsuranceCoveragesQuery} from "../../../../Services/Product/Queries";
import {ProductDetailsType} from "../../../../Services/Product/Types";
import {useGetProductBehavioursQuery} from "../../../../Services/ProductBehaviour/Queries";
import {ProductRetrocession} from "../../../../Services/ProductRetrocession/Types";
import {useGetEligiblePromotionalCampaignsQuery} from "../../../../Services/PromotionalCampagins/Queries";
import {Splitting} from "../../../../Services/Splitting/Types";
import {VersionDTO} from "../../../../Services/Version/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import UnitTestInsurancePackageOptions from "./UnitTestInsurancePackageOptions";
import UnitTestQuestions from "./UnitTestQuestions";

const convertToPricingInflow = (formData: PricingInflowForm): PricingInflow => {
    const businessProviders = formData.businessProviders ?? {};

    const pricingInflow: PricingInflow = {
        ...formData,
        insurancePackageCode: formData.insurancePackageCode === "Toutes" ? null : formData.insurancePackageCode,
        riskInformation: Object.entries(formData?.riskInformation ?? {}).map(([code, value]) => ({
            code,
            value: value || null,
        })),
        includeOptions:
            formData.includeOptions &&
            Object.entries(formData.includeOptions).map(([insurancePackageCode, options]) => ({
                insurancePackageCode,
                optionsCode: Object.entries(options)
                    .filter((entry) => entry[1])
                    .map((entry) => entry[0]),
            })),
        businessProvider1: businessProviders["1"]?.toString() ?? null,
        businessProvider2: businessProviders["2"]?.toString() ?? null,
        businessProvider3: businessProviders["3"]?.toString() ?? null,
        businessProvider4: businessProviders["4"]?.toString() ?? null,
        businessProvider5: businessProviders["5"]?.toString() ?? null,
    };

    // @ts-expect-error Viens du spread operator plus haut, mais on en veut pas dans l'export
    delete pricingInflow.businessProviders;

    return pricingInflow;
};

type UnitTestFormProps = {
    product: ProductDetailsType;
    splittings: Array<Splitting>;
    retrocessions: Array<ProductRetrocession>;
    versions: Array<VersionDTO>;
};

const UnitTestForm = ({product, splittings, retrocessions, versions}: UnitTestFormProps) => {
    const {t} = useTranslation();

    const {form, updateCache} = useCachedForm<PricingInflowForm>(`${product.code}-unit-test`, {
        shouldFocusError: false,
    });

    const premiumsMutation = usePremiumsMutation(product.id, product.code);

    const handleSubmit = (formData: PricingInflowForm) => {
        // Mise à jour du cache avant changement de page
        updateCache();

        const pricingInflow = convertToPricingInflow(formData);

        const policyEffectiveDate = formData.policyEffectiveDate ?? null;
        const policyInsurancePackageCode = formData.policyInsurancePackageCode ?? null;

        premiumsMutation.mutate({pricingInflow, policyEffectiveDate, policyInsurancePackageCode});
    };

    const effectiveDateTime = form.watch("effectiveDate");
    const effectiveDate = effectiveDateTime?.substring(0, 10);

    const version = useMemo((): VersionDTO | undefined => {
        if (effectiveDate) {
            return versions
                .filter((v) => v.pipDate != null && v.effectiveDate <= effectiveDate)
                .toSorted((v1, v2) => new Date(v2.effectiveDate).getTime() - new Date(v1.effectiveDate).getTime())[0];
        }

        return undefined;
    }, [effectiveDate, versions]);

    const splittingsOptions = splittings.map((splitting) => ({
        text: splitting.label,
        value: splitting.code,
    }));

    const movementTypeOptions = Object.values(MovementType).map((movement) => ({
        text: t(`enums.MovementType.${movement}`) + " (" + movement + ")",
        value: movement,
    }));

    const movementType = form.watch("movementType");

    const displayPolicyFields = movementType !== MovementType.AN;

    const getEligibleCampaigns = useGetEligiblePromotionalCampaignsQuery(product.code, effectiveDate, !!effectiveDate);
    const displayPromotionalCampaign = getEligibleCampaigns.isSuccess && getEligibleCampaigns.data.length > 0;

    const getInsuranceCoverages = useGetInsuranceCoveragesQuery(String(product.id), effectiveDate, !!effectiveDate);

    // Pour le select des formules possibles
    const insurancePackageOptions = useMemo((): Array<DropdownItemProps> => {
        const options: Array<DropdownItemProps> = [];

        if (getInsuranceCoverages.isSuccess) {
            getInsuranceCoverages.data.forEach((i) => {
                options.push({
                    text: i.insurancePackageLabel,
                    value: i.insurancePackageCode,
                });
            });
        }

        return options;
    }, [getInsuranceCoverages.isSuccess, getInsuranceCoverages.data]);

    const getProductBehaviours = useGetProductBehavioursQuery(String(product.id));
    const displayContributionExemption =
        getProductBehaviours.isSuccess && getProductBehaviours.data.find((pb) => pb.exonerationActive) !== undefined;

    const mainDeadlineDerogation = form.watch("mainDeadlineDerogation");

    const exportAsJson = (event: React.MouseEvent) => {
        const element = event.target as HTMLAnchorElement;
        const pricingInflow = convertToPricingInflow(form.getValues());
        const data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(pricingInflow));
        element.setAttribute("href", "data:" + data);
        element.setAttribute("download", "export_pricing_form.json");
    };

    return (
        <Form>
            <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center", gap: "12px"}}>
                <Button
                    name="export"
                    type="button"
                    icon
                    labelPosition="left"
                    basic
                    color="violet"
                    onClick={exportAsJson}
                >
                    <Icon name="download" style={{backgroundColor: "transparent"}} />
                    {t("Export")}
                </Button>

                <Button
                    name="validate"
                    type="button"
                    icon
                    labelPosition="left"
                    color="violet"
                    onClick={form.handleSubmit(handleSubmit)}
                >
                    <Icon name="check" />
                    {t("Validate")}
                </Button>
            </div>

            <Grid columns={4} className="fields-container">
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={form.control}
                            name="effectiveDate"
                            defaultValue={new Date().toISOString().substring(0, 16)}
                            rules={{required: true}}
                            render={({field, fieldState: {error}}) => (
                                <Form.Input
                                    {...field}
                                    required
                                    type="datetime-local"
                                    label={t(field.name)}
                                    error={!!error}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                {version && (
                    <Fragment>
                        <Grid.Row>
                            <Grid.Column>
                                <h2 style={{padding: "0"}}>{version.label}</h2>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{gap: "0.5rem 0"}}>
                            <Grid.Column>
                                <Controller
                                    control={form.control}
                                    name="movementCode"
                                    defaultValue="AN"
                                    rules={{required: true}}
                                    render={({field, fieldState: {error}}) => (
                                        <Form.Input
                                            {...field}
                                            type="text"
                                            required
                                            label={t(field.name)}
                                            error={!!error}
                                        />
                                    )}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Controller
                                    control={form.control}
                                    name="movementType"
                                    defaultValue="AN"
                                    rules={{required: true}}
                                    render={({field, fieldState: {error}}) => {
                                        return (
                                            <Form.Dropdown
                                                {...field}
                                                label={t(field.name)}
                                                clearable
                                                selection
                                                required
                                                options={movementTypeOptions}
                                                onChange={(_, {value}) => field.onChange(value)}
                                                error={!!error}
                                            />
                                        );
                                    }}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Controller
                                    control={form.control}
                                    name="splittingCode"
                                    defaultValue={splittings.find((s) => s.defaultSplitting)!.code}
                                    rules={{required: true}}
                                    render={({field, fieldState: {error}}) => {
                                        return (
                                            <Form.Dropdown
                                                {...field}
                                                clearable
                                                selection
                                                required
                                                label={t(field.name)}
                                                options={splittingsOptions}
                                                onChange={(_, {value}) => field.onChange(value)}
                                                error={!!error}
                                            />
                                        );
                                    }}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Controller
                                    control={form.control}
                                    name="insurancePackageCode"
                                    defaultValue="Toutes"
                                    rules={{required: true}}
                                    render={({field, fieldState: {error}}) => {
                                        return (
                                            <Form.Dropdown
                                                {...field}
                                                value={field.value ?? "Toutes"}
                                                clearable
                                                selection
                                                required
                                                label={t("insurancePackages")}
                                                options={[
                                                    {text: "Toutes", value: "Toutes"},
                                                    ...insurancePackageOptions,
                                                ]}
                                                onChange={(_, {value}) => field.onChange(value)}
                                                error={!!error}
                                            />
                                        );
                                    }}
                                />
                            </Grid.Column>

                            {movementType === MovementType.AN && (
                                <Grid.Column>
                                    <Controller
                                        control={form.control}
                                        name="debitDay"
                                        defaultValue={null}
                                        rules={{min: 1, max: 31}}
                                        shouldUnregister
                                        render={({field, fieldState: {error}}) => (
                                            <Form.Input
                                                {...field}
                                                label={t(field.name)}
                                                type="number"
                                                error={!!error}
                                                className="labeled"
                                            >
                                                <input min={1} max={31} value={field.value || ""} />
                                            </Form.Input>
                                        )}
                                    />
                                </Grid.Column>
                            )}

                            {displayPolicyFields && (
                                <Fragment>
                                    <Grid.Column>
                                        <Controller
                                            control={form.control}
                                            name="policyEffectiveDate"
                                            defaultValue=""
                                            rules={{required: true}}
                                            shouldUnregister
                                            render={({field, fieldState: {error}}) => (
                                                <Form.Input
                                                    {...field}
                                                    type="date"
                                                    required
                                                    label={t(field.name)}
                                                    error={!!error}
                                                />
                                            )}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Controller
                                            control={form.control}
                                            name="policyInsurancePackageCode"
                                            defaultValue=""
                                            rules={{required: true}}
                                            shouldUnregister
                                            render={({field, fieldState: {error}}) => {
                                                return (
                                                    <Form.Dropdown
                                                        {...field}
                                                        value={field.value ?? ""}
                                                        clearable
                                                        selection
                                                        required
                                                        label={t(field.name)}
                                                        options={insurancePackageOptions}
                                                        onChange={(_, {value}) => field.onChange(value)}
                                                        error={!!error}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid.Column>
                                </Fragment>
                            )}
                        </Grid.Row>

                        {product.mainDeadlineDerogation && (
                            <Grid.Row>
                                <Grid.Column style={{minHeight: "62px", display: "flex", alignItems: "center"}}>
                                    <Controller
                                        control={form.control}
                                        name={"mainDeadlineDerogation"}
                                        defaultValue={false}
                                        shouldUnregister
                                        render={({field}) => (
                                            <Form.Checkbox
                                                {...field}
                                                toggle
                                                label={t(field.name)}
                                                value={undefined}
                                                onChange={(_, data) => field.onChange(data.checked)}
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                </Grid.Column>

                                {mainDeadlineDerogation && (
                                    <Fragment>
                                        <Grid.Column>
                                            <Controller
                                                control={form.control}
                                                name="deadLineDay"
                                                defaultValue={null}
                                                rules={{required: true, min: 1, max: 31}}
                                                shouldUnregister
                                                render={({field, fieldState: {error}}) => (
                                                    <Form.Input
                                                        {...field}
                                                        type="number"
                                                        required
                                                        label={t("deadlineByDay")}
                                                        error={!!error}
                                                    >
                                                        <input min={1} max={31} value={field.value || ""} />
                                                    </Form.Input>
                                                )}
                                            />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Controller
                                                control={form.control}
                                                name="deadLineMonth"
                                                defaultValue={null}
                                                rules={{required: true, min: 1, max: 12}}
                                                shouldUnregister
                                                render={({field, fieldState: {error}}) => (
                                                    <Form.Input
                                                        {...field}
                                                        type="number"
                                                        required
                                                        label={t("deadlineByMonth")}
                                                        error={!!error}
                                                    >
                                                        <input min={1} max={12} value={field.value || ""} />
                                                    </Form.Input>
                                                )}
                                            />
                                        </Grid.Column>
                                    </Fragment>
                                )}
                            </Grid.Row>
                        )}

                        <Grid.Row>
                            {displayContributionExemption && (
                                <Grid.Column>
                                    <Controller
                                        control={form.control}
                                        name="contributionExemption"
                                        defaultValue={0}
                                        rules={{min: 0, max: 100}}
                                        render={({field}) => (
                                            <Form.Input
                                                {...field}
                                                label={t(field.name)}
                                                type="number"
                                                className="labeled"
                                                error={getErrorMessage(
                                                    t,
                                                    form.formState.errors,
                                                    field.name,
                                                    null,
                                                    null,
                                                    null,
                                                    0,
                                                    100
                                                )}
                                            >
                                                <input min={0} max={100} value={field.value || ""} />
                                            </Form.Input>
                                        )}
                                    />
                                </Grid.Column>
                            )}

                            {displayPromotionalCampaign && (
                                <Grid.Column>
                                    <Controller
                                        control={form.control}
                                        name="promotionalCampaignCode"
                                        defaultValue=""
                                        render={({field, fieldState: {error}}) => {
                                            return (
                                                <Form.Dropdown
                                                    {...field}
                                                    value={field.value ?? ""}
                                                    clearable
                                                    selection
                                                    label={t("Promotional campaign")}
                                                    options={getEligibleCampaigns.data.map((pc) => ({
                                                        text: `${pc.label} (${pc.code})`,
                                                        value: pc.code,
                                                    }))}
                                                    onChange={(_, {value}) => field.onChange(value)}
                                                    error={!!error}
                                                />
                                            );
                                        }}
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>

                        {retrocessions.length > 0 && (
                            <Grid.Row>
                                {retrocessions.map((pr) => (
                                    <Grid.Column key={pr.id}>
                                        <Controller
                                            control={form.control}
                                            name={`businessProviders.${pr.businessProviderRank}`}
                                            defaultValue={null}
                                            render={({field, fieldState: {error}}) => (
                                                <Form.Input
                                                    {...field}
                                                    type="text"
                                                    label={t("business_provider_x", {
                                                        number: pr.businessProviderRank,
                                                    })}
                                                    error={!!error}
                                                />
                                            )}
                                        />
                                    </Grid.Column>
                                ))}
                            </Grid.Row>
                        )}

                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <UnitTestInsurancePackageOptions
                                    control={form.control}
                                    watch={form.watch}
                                    query={getInsuranceCoverages}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <UnitTestQuestions version={version} form={form} />
                    </Fragment>
                )}
            </Grid>

            <Dimmer active={premiumsMutation.isPending} page>
                <Loader content={t("Loading") + "..."} />
            </Dimmer>

            <ModalError error={premiumsMutation.error} onClose={premiumsMutation.reset} />
        </Form>
    );
};

export default UnitTestForm;
