import {Breadcrumb} from "../Components/Routes/BreadcrumbsInjectorWrapper";
import CommercialReductionsPage from "../Coverlife/Pages/CommercialReductionsPage";
import NewProductCoverlife from "../Coverlife/Pages/NewProductCoverlife";
import PremiumsPricingRightsPage from "../Coverlife/Pages/PremiumsPricingRightsPage";
import CommissionPackagesPage from "../Generation/Pages/CommissionPackages/CommissionPackagesPage";
import ESelectionPage from "../Generation/Pages/ESelection/ESelectionPage";
import ApplicationAccessPage from "../Pages/Company/ApplicationAccess/ApplicationAccessPage";
import CompanyPage from "../Pages/Company/CompanyPage";
import CompanyProductInfoPage from "../Pages/Company/CompanyProductInfo/CompanyProductInfoPage";
import IndexPage from "../Pages/Company/Indexes/IndexPage";
import IndexValuePage from "../Pages/Company/Indexes/IndexValuePage";
import RegistryListPage from "../Pages/Company/Registries/RegistryListPage";
import EntryPage from "../Pages/Company/Registry/EntryPage";
import RestApiAccessPage from "../Pages/Company/RestApiAccess/RestApiAccessPage";
import RiskTypesPage from "../Pages/Company/RiskTypes/RiskTypesPage";
import UserPage from "../Pages/Company/User/UserPage";
import UserGroupPage from "../Pages/Company/UserGroup/UserGroupPage";
import UsersPage from "../Pages/Company/Users/UsersPage";
import DashboardPage from "../Pages/Global/Dashboard/DashboardPage";
import UserAccountPage from "../Pages/Global/UserAccount/UserAccountPage";
import CoveragePage from "../Pages/Products/InsurancePackage/Fragments/Coverages/CoveragePage";
import DeductibleCapitalPage from "../Pages/Products/InsurancePackage/Fragments/DeductibleCapital/DeductibleCapitalPage";
import FeeChargePage from "../Pages/Products/InsurancePackage/Fragments/FeeCharges/FeeChargePage";
import OptionPage from "../Pages/Products/InsurancePackage/Fragments/Options/OptionPage";
import InsurancePackagePage from "../Pages/Products/InsurancePackage/InsurancePackagePage";
import MassTestPage from "../Pages/Products/MassTest/MassTestPage";
import PricingCriteriaPage from "../Pages/Products/PricingCriterias/PricingCriteriaPage";
import PricingGridPage from "../Pages/Products/PricingGrid/PricingGridPage";
import NewProductPage from "../Pages/Products/Product/NewProductPage";
import ProductPage from "../Pages/Products/Product/ProductPage";
import ProductBehaviourPage from "../Pages/Products/ProductBehaviour/ProductBehaviourPage";
import ProductRetrocessionPage from "../Pages/Products/ProductRetrocession/ProductRetrocessionPage";
import PromotionalCampaignPage from "../Pages/Products/PromotionalCampaign/PromotionalCampaignPage";
import QuestionPage from "../Pages/Products/Question/QuestionPage";
import SplittingPage from "../Pages/Products/Splitting/SplittingPage";
import UnitTestPage from "../Pages/Products/UnitTest/Form/UnitTestPage";
import UnitTestResultPage from "../Pages/Products/UnitTest/Result/UnitTestResultPage";
import VariablePage from "../Pages/Products/Variables/VariablePage";
import VersionPage from "../Pages/Products/Version/VersionPage";

export type RouteProps = {
    path: string;
    name: string;
    element: (({sections}: {sections: Breadcrumb[]}) => JSX.Element) | React.FC;
    societeUser?: string;
    requiredRole?: string[];
    customBreadcrumb?: boolean;
};

const routes: Array<RouteProps> = [
    {path: "/", name: "home", element: DashboardPage},
    {
        path: "/transversal_settings/users",
        name: "users",
        element: UsersPage,
        requiredRole: ["SUPER_ADMIN", "ADMINISTRATEUR"],
    },
    {
        path: "/transversal_settings/users/:userId",
        name: "user",
        element: UserPage,
        requiredRole: ["SUPER_ADMIN", "ADMINISTRATEUR"],
    },
    {
        path: "/transversal_settings/rest_api_access",
        name: "rest_api_access",
        element: RestApiAccessPage,
        requiredRole: ["SUPER_ADMIN", "ADMINISTRATEUR"],
    },
    {
        path: "/transversal_settings/rest_api_access/:applicationAccessId",
        name: "applicationAccess",
        element: ApplicationAccessPage,
        requiredRole: ["SUPER_ADMIN", "ADMINISTRATEUR"],
    },
    {
        path: "/account",
        name: "account",
        element: UserAccountPage,
    },
    {
        path: "/products/:id",
        name: "product",
        customBreadcrumb: true,
        element: ProductPage,
    },
    {
        path: "/products/:id/behaviours/:behaviourId",
        name: "behaviours",
        customBreadcrumb: true,
        element: ProductBehaviourPage,
    },
    {
        path: "/products/:id/versions/:versionId",
        name: "version",
        customBreadcrumb: true,
        element: VersionPage,
    },
    {
        path: "/products/:id/versions/:versionId/mass_tests",
        name: "mass_test",
        customBreadcrumb: true,
        element: MassTestPage,
    },
    {
        path: "/products/:id/versions/:versionId/pricing-grid/:gridId",
        name: "pricing-grid",
        customBreadcrumb: true,
        element: PricingGridPage,
    },
    {
        path: "/products/:id/versions/:versionId/insurance-packages/:insurancePackageId",
        name: "insurance-package",
        customBreadcrumb: true,
        element: InsurancePackagePage,
    },
    {
        path: "/products/:id/versions/:versionId/pricing-criterias/:pricingCriteriaId",
        name: "pricing-criteria",
        customBreadcrumb: true,
        element: PricingCriteriaPage,
    },
    {
        path: "/new_product",
        name: "new_product",
        element: NewProductPage,
    },
    {
        path: "/products/:id/splittings/:splittingId",
        name: "splitting",
        customBreadcrumb: true,
        element: SplittingPage,
    },
    {
        path: "/products/:id/versions/:versionId/questions/:questionId",
        name: "question",
        customBreadcrumb: true,
        element: QuestionPage,
    },
    {
        path: "/transversal_settings/registry",
        name: "registry",
        element: RegistryListPage,
    },
    {
        path: "/transversal_settings/registry/:registryEntryId",
        name: "registryEntry",
        customBreadcrumb: true,
        element: EntryPage,
    },
    {
        path: "/transversal_settings/risk_types",
        name: "risk_types",
        element: RiskTypesPage,
    },
    {
        path: "/products/:id/versions/:versionId/variables/:variableId",
        name: "variables",
        customBreadcrumb: true,
        element: VariablePage,
    },
    {
        path: "/transversal_settings/rest_api_access/user_groups/:userGroupId",
        name: "user_group",
        element: UserGroupPage,
        requiredRole: ["SUPER_ADMIN", "ADMINISTRATEUR"],
    },
    {
        path: "/products/:id/versions/:versionId/insurance-packages/:insurancePackageId/coverages-groups/:coverageGroupId/coverages/:coverageId",
        name: "coverage",
        customBreadcrumb: true,
        element: CoveragePage,
    },
    {
        path: "/products/:id/versions/:versionId/insurance-packages/:insurancePackageId/coverages-groups/:coverageGroupId/options/:optionId",
        name: "option",
        customBreadcrumb: true,
        element: OptionPage,
    },
    {
        path: "/products/:id/versions/:versionId/insurance-packages/:insurancePackageId/fee-charges/:feeChargeId",
        name: "fee_charge",
        customBreadcrumb: true,
        element: FeeChargePage,
    },
    {
        path: "/products/:id/versions/:versionId/insurance-packages/:insurancePackageId/deductible-capitals/:deductibleCapitalId",
        name: "deductible_capital",
        customBreadcrumb: true,
        element: DeductibleCapitalPage,
    },
    {
        path: "/products/:id/retrocessions/:retrocessionId",
        name: "product_retrocession",
        customBreadcrumb: true,
        element: ProductRetrocessionPage,
    },
    {
        path: "/transversal_settings/company_product_infos",
        name: "company product infos",
        element: CompanyProductInfoPage,
    },
    {
        path: "/products/:id/promotional_campaigns/:promotionalCampaignId",
        name: "promotional_campaign",
        customBreadcrumb: true,
        element: PromotionalCampaignPage,
    },
    {
        path: "/companies/:companyId",
        name: "company",
        customBreadcrumb: true,
        element: CompanyPage,
    },
    {
        path: "/transversal_settings/indexes",
        name: "indexes",
        element: IndexPage,
    },
    {
        path: "/transversal_settings/indexes/:indexId/values/:valueId",
        name: "index_value",
        element: IndexValuePage,
    },
    {
        path: "/products/:id/unit_test",
        name: "unit_test",
        element: UnitTestPage,
    },
    {
        path: "/products/:id/unit_test/result",
        name: "unit_test_result",
        element: UnitTestResultPage,
    },

    // COVERLIFE
    {
        path: "/new_product",
        name: "new_product",
        societeUser: "coverlife",
        element: NewProductCoverlife,
    },
    {
        path: "/new_product_generic",
        name: "new_product_generic",
        societeUser: "coverlife",
        element: NewProductPage,
    },
    {
        path: "/specific_settings/pricing_rights",
        name: "premium_pricing_rights",
        societeUser: "coverlife",
        customBreadcrumb: true,
        element: PremiumsPricingRightsPage,
    },
    {
        path: "/specific_settings/commercial_reductions",
        name: "commercial_reductions",
        societeUser: "coverlife",
        customBreadcrumb: true,
        element: CommercialReductionsPage,
    },

    // GENERATION
    {
        path: "/specific_settings/commission_packages",
        name: "commission_packages",
        societeUser: "generation",
        customBreadcrumb: true,
        element: CommissionPackagesPage,
    },
    {
        path: "/specific_settings/e_selection",
        name: "eSelection",
        societeUser: "generation",
        customBreadcrumb: true,
        element: ESelectionPage,
    },
];

export default routes;
